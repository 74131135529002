import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { CardActionProps } from './CardAction.props';

import { cardActionStyles } from './styles';

const useStyles = makeStyles(cardActionStyles);

const CardActions: FC<CardActionProps> = ({
  amount,
  children,
  preChildren,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.actionIcon}>
      {preChildren}
      <div className={classes.countBlock} aria-live="polite">
        <Typography className={classes.count} variant="button" component="span">
          {amount}
        </Typography>
      </div>
      {children}
    </div>
  );
};

export default CardActions;
