import React, {
  FC,
  ReactEventHandler,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { ThemeProvider } from '@material-ui/core/styles';
import en from 'javascript-time-ago/locale/en';
import TimeAgo, { LocaleData } from 'javascript-time-ago';
import debounce from 'lodash/debounce';
import {
  getPosts,
  getPostsByLink,
  AllPosts,
  getPostsBatch,
} from '../../services/SocialAPI';
import getTheme from '../../utils/theme';
import { NewPostInternal } from '../NewPost';
import {
  getTopLevelLink,
  isFirstLevelLink,
  isSameLink,
  linkIncludesLink,
  flattenTableOfLinks,
} from '../../utils/linkHelpers';
import {
  PostUpdateData,
  PostWithMarkup,
  PostMarkup,
  IPost,
  Link,
} from '../../types';
import Filters from '../Filters';
import { FiltersValue } from '../Filters/Filters.props';
import pkg from '../../../package.json';
import '../../scss/postCard.scss';
import { PostsInnerProps, PostsProps } from './Posts.props';
import PostList, { ScrollablePostList } from './PostList';
import PostListByLinkOrder from './PostListByLinkOrder';
import LoadPostError from '../LoadPostError';
import InfiniteLoader from '../InfiniteLoader';

import { ReactComponent as ArrowLeft } from '../../assets/images/arrow-left.svg';
import NoPosts from '../NoPosts';

import styles from './Posts.module.scss';
import useTheme from '../../hooks/useTheme';
import {
  sendEventInitiateNewItem,
  setAdditionalTrackingEngines,
  setEventGlobalData,
} from '../../utils/events';
import eventSystem from '../../utils/eventSystem';
import Loader from '../Loader';

import {
  LayoutModeProvider,
  useLayoutModeContext,
} from '../../providers/layoutModeContext';
import {
  CommonDataProvider,
  useCommonDataContext,
} from '../../providers/commonDataContext';
import { ModalProvider, useModal } from '../../providers/modalContext';
import { PostProvider } from '../../providers/postProvider';
import { A11yLiveProvider } from '../../providers/a11yLiveContext';

const getPostIdsFromQueryParams = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const postIdInQuery = parseInt(params.get('postId') || '', 10);
  const adminToken = params.get('adminToken') || '';
  return { postIdInQuery, adminToken };
};

const PostsInner: FC<PostsInnerProps> = ({
  ids,
  themeName,
  changeInputCallback,
  onPostsMarkupChangeCallback,
  onUnmountCallback,
  linkClickCallback,
  hideAddNewButton,
  disableContentFilter,
  additionalTrackingEngines,
  trackingGlobalData,
  resourceTitle,
  assetLink,
}) => {
  const { tenantId, link, env, tableOfLinks, token, userId } =
    useCommonDataContext();
  const { isCommentsMode } = useLayoutModeContext();
  const { openModal } = useModal();
  const inputLink = useRef<Link>(link);
  const scrollableContainer = useRef(null);
  const [pageData, setPageData] = useState<AllPosts>({
    nextPageCursor: null,
    posts: [],
    totalCount: 0,
  });
  const [postCount, setPostCount] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [contentFilterDisable, setContentFilterDisable] = useState<
    boolean | undefined
  >(disableContentFilter);
  const [addNewButtonHide, setAddNewButtonHide] = useState<boolean | undefined>(
    hideAddNewButton,
  );
  const [trackingGlobalDataValues, setTrackingGlobalDataValues] = useState<
    Record<string, unknown> | undefined
  >(trackingGlobalData);
  const [filters, setFilters] = useState<FiltersValue>({
    filter: 'all',
    sort: 'recent',
    link: disableContentFilter ? link : getTopLevelLink(link),
  });
  const [divHeight, setDivHeight] = useState<number>();
  const postsListRef = useRef<ScrollablePostList>(null);
  const refreshMarkups = useRef(false);
  const divRef = useRef<HTMLDivElement>(null);
  const chapterCursors = useRef<Record<string, number | null>>({});

  const [hasPostsError, setPostsError] = useState(false);

  const isSortByLinkOrder = filters.sort === 'linkOrder';
  const { postIdInQuery, adminToken } = getPostIdsFromQueryParams();

  const showOnlyIds = useMemo(
    () => (postIdInQuery ? [postIdInQuery] : ids),
    [ids, window.location.search],
  );

  const fetchDataInner = (calledFilters?: FiltersValue) => {
    setIsLoaded(false);

    if (!isCommentsMode) {
      postsListRef.current?.scrollToTop();
    }

    getPostsByLink({
      token,
      tenantId,
      env,
      ...(calledFilters || filters),
      pageSize: 10,
    })
      .then((data) => {
        unstable_batchedUpdates(() => {
          setPageData(data);
          setPostsError(false);
          setIsLoaded(true);
          if (contentFilterDisable && !isCommentsMode)
            setPostCount(data.totalCount);
        });
      })
      .catch(() => {
        unstable_batchedUpdates(() => {
          setPostsError(true);
          setIsLoaded(true);
        });
      });
  };
  const fetchData = debounce(fetchDataInner, 300);

  const fetchMorePostsForChapter = async (linkId: string) => {
    const isRootLink = filters.link.id === linkId;

    const filterLink: Link = {
      id: filters.link.id,
      subLink: isRootLink ? undefined : { id: linkId },
    };

    try {
      const response = await getPostsByLink({
        token,
        tenantId,
        env,
        link: filterLink,
        exactLink: isRootLink,
        filter: filters.filter,
        sort: filters.sort,
        cursor: chapterCursors.current[linkId],
        pageSize: 10,
      });

      chapterCursors.current[linkId] = response.nextPageCursor;

      setPageData((prevData) => ({
        ...prevData,
        posts: prevData.posts.concat(response.posts),
      }));
    } catch (err) {
      setPostsError(true);
    }
  };

  const fetchPostsForChapters = async () => {
    setIsLoaded(false);
    setPageData({ nextPageCursor: null, posts: [], totalCount: 0 });

    const flatTableOfLinks = flattenTableOfLinks(tableOfLinks!, 2);

    const collections = await getPostsBatch({
      token,
      tenantId,
      env,
      filters: flatTableOfLinks.map(({ id }) => {
        const isRootLink = filters.link.id === id;

        const filterLink: Link = {
          id: filters.link.id,
          subLink: isRootLink ? undefined : { id },
        };

        return {
          link: filterLink,
          exactLink: isRootLink,
          filter: filters.filter,
          sort: filters.sort,
          pageSize: 10,
        };
      }),
    });

    collections.forEach((collection, index) => {
      setPageData((prevData) => ({
        ...prevData,
        posts: prevData.posts.concat(collection.posts),
      }));

      chapterCursors.current[flatTableOfLinks[index].id] =
        collection.nextPageCursor;
    });

    setIsLoaded(true);

    setTimeout(() => {
      postsListRef.current?.scrollToGroup?.(link.subLink?.id || link.id);
    }, 100);
  };

  const addNewPostEvent = (post: PostWithMarkup) => {
    if (isSameLink(post.link, inputLink.current)) {
      fetchData({ ...filters, link: post.link });
    } else {
      changeInputCallback?.({
        link: post.link,
        hideAddNewButton: true,
        disableContentFilter: true,
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`social v${pkg.version}, env ${env}`);
    TimeAgo.addLocale(en as LocaleData);
    eventSystem.subscribe<PostWithMarkup>('newpost', addNewPostEvent);
    setDivHeight(divRef.current?.clientHeight);
    return () => {
      onUnmountCallback?.({ refreshMarkups: refreshMarkups.current });
      eventSystem.unsubscribe<PostWithMarkup>('newpost', addNewPostEvent);
    };
  }, []);

  useEffect(() => {
    setAdditionalTrackingEngines(additionalTrackingEngines);
  }, [additionalTrackingEngines]);

  useEffect(() => {
    setTrackingGlobalDataValues(trackingGlobalData);
    if (trackingGlobalData) {
      setEventGlobalData(trackingGlobalData);
    } else if (Array.isArray(window.dataLayer)) {
      const bookId = window.dataLayer.find(
        (event) =>
          typeof event.book_id !== 'undefined' && event.book_id != null,
      )?.book_id;
      const businessModelCode = window.dataLayer.find(
        (event) =>
          typeof event.business_model_code !== 'undefined' &&
          event.business_model_code != null,
      )?.business_model_code;
      const pearsonId = window.dataLayer.find(
        (event) =>
          typeof event.person_id !== 'undefined' && event.person_id != null,
      )?.person_id;
      const productId = window.dataLayer.find(
        (event) =>
          typeof event.product_id !== 'undefined' && event.product_id != null,
      )?.product_id;
      const globalData = {
        book_id: bookId || null,
        business_model_code: businessModelCode || null,
        person_id: pearsonId || null,
        product_id: productId || null,
      };
      setEventGlobalData(globalData);
    }
  }, [trackingGlobalData]);

  useTheme(tenantId, themeName);

  useEffect(() => {
    if (showOnlyIds) {
      getPosts({
        ids: showOnlyIds,
        env,
        token,
        tenantId,
        adminToken,
      }).then((data) => {
        setPageData(data);
      });
    } else if (
      isSortByLinkOrder &&
      !filters.link.subLink &&
      !contentFilterDisable
    ) {
      fetchPostsForChapters();
    } else {
      fetchData();
    }
  }, [showOnlyIds, tenantId, filters, userId, isSortByLinkOrder]);

  useEffect(() => {
    if (isSameLink(inputLink.current, link)) {
      return;
    }
    inputLink.current = link;
    setFilters({ ...filters, link });
  }, [link]);

  useEffect(() => {
    setContentFilterDisable(disableContentFilter);
  }, [disableContentFilter]);

  useEffect(() => {
    setAddNewButtonHide(hideAddNewButton);
  }, [hideAddNewButton]);

  const onGoToAllPosts: ReactEventHandler = () => {
    changeInputCallback?.({
      link: getTopLevelLink(inputLink.current),
      hideAddNewButton: false,
      disableContentFilter: false,
      isAllPostLink: true,
    });
  };

  const onLoadMorePosts = () => {
    if (!(filters.link && pageData)) return;

    setIsLoaded(false);
    getPostsByLink({
      token,
      tenantId,
      env,
      link: filters.link,
      filter: filters.filter,
      sort: filters.sort,
      cursor: pageData.nextPageCursor,
      pageSize: 10,
    })
      .then((response) => {
        pageData.nextPageCursor = response.nextPageCursor
          ? response.nextPageCursor
          : null;
        pageData.posts = pageData.posts.concat(response.posts);
        setPageData({ ...pageData });
      })
      .catch(() => {
        setPostsError(true);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  };

  const onPostUpdate = (data: PostUpdateData) => {
    const postIndex = pageData.posts.findIndex((post) => post.id === data.id);
    if (postIndex !== -1) {
      pageData.posts[postIndex] = {
        ...pageData.posts[postIndex],
        ...data,
      };
      setPageData({ ...pageData });
      if (data.needToRefresh) {
        refreshMarkups.current = true;
      }
    }
  };

  const onPostDelete = (postId: number, postsMarkup: PostMarkup) => {
    setPageData({
      ...pageData,
      posts: pageData.posts.filter((post) => post.id !== postId),
    });
    refreshMarkups.current = false;
    setPostCount((prev: number) => prev - 1);
    onPostsMarkupChangeCallback?.(postsMarkup);
  };

  const onPostAdd = (data: IPost | PostWithMarkup) => {
    if (linkIncludesLink(filters.link, data.link)) {
      pageData.posts.unshift(data);
      setPageData({ ...pageData });

      setTimeout(() => {
        // a bit hacky. let react re-render `pageData.posts`
        postsListRef.current?.scrollToPost(data.id);
      }, 100);
    }
    refreshMarkups.current = true;
    onPostsMarkupChangeCallback?.((data as PostWithMarkup).postsMarkup);
  };

  const onFilterUpdate = (filtersValue: FiltersValue) => {
    setFilters(filtersValue);
  };

  const handleLoadMoreForChapterClick = (subLinkId: string) => {
    fetchMorePostsForChapter(subLinkId);
  };

  const showAddPostModal = () => {
    const closeModal = openModal(NewPostInternal, {
      link: filters.link,
      linkContext:
        resourceTitle || assetLink
          ? {
              ranges: [],
              resourceTitle,
              assetLink,
            }
          : undefined,
      onCancelCallback: () => {
        closeModal();
      },
      onSubmitCallback: (data: IPost | PostWithMarkup) => {
        onPostAdd(data);
        closeModal();
      },
      themeName,
      disableContentFilter: !!contentFilterDisable,
      dialog: true,
      external: false,
      trackingGlobalData: trackingGlobalDataValues,
      additionalTrackingEngines,
    });
  };

  const NoPostsFound = () => {
    const isFiltered = filters.filter !== 'all';
    const action = () => {
      if (isFiltered) {
        setFilters({ ...filters, filter: 'all' });
      } else {
        showAddPostModal();
      }
    };

    return <NoPosts isFiltered={isFiltered} action={action} />;
  };

  const renderPosts = () =>
    isSortByLinkOrder && !contentFilterDisable ? (
      <PostListByLinkOrder
        posts={pageData.posts}
        ref={postsListRef}
        onlyShowLink={
          isFirstLevelLink(filters.link) ? undefined : filters.link.subLink
        }
        hasMorePosts={chapterCursors.current}
        hideLoadMore={!!filters.link.subLink || !isLoaded}
        onPostUpdate={onPostUpdate}
        onPostDelete={onPostDelete}
        onLinkClick={linkClickCallback}
        onLoadMoreClick={handleLoadMoreForChapterClick}
        scrollableContainer={scrollableContainer}
      />
    ) : (
      <PostList
        posts={pageData.posts}
        ref={postsListRef}
        onPostUpdate={onPostUpdate}
        onPostDelete={onPostDelete}
        onLinkClick={linkClickCallback}
        scrollableContainer={scrollableContainer}
      />
    );

  const onAddPost = () => {
    showAddPostModal();
    sendEventInitiateNewItem('New');
  };

  const renderMainPannel = () => {
    if (hasPostsError) {
      return (
        <Loader isLoading={!isLoaded}>
          {!isCommentsMode && (
            <LoadPostError
              repeatAction={
                isSortByLinkOrder ? fetchPostsForChapters : fetchData
              }
            />
          )}
        </Loader>
      );
    }

    if (pageData.posts.length === 0 && isLoaded) {
      return (
        <Loader isLoading={!isLoaded}>
          {!isCommentsMode && <NoPostsFound />}
        </Loader>
      );
    }

    return (
      <InfiniteLoader
        hasMore={pageData.nextPageCursor !== null}
        isLoading={!isSortByLinkOrder && !isLoaded}
        onNeedMore={onLoadMorePosts}
      >
        {renderPosts()}
      </InfiniteLoader>
    );
  };

  const postListProps = {
    style: {
      height: !isCommentsMode
        ? `calc(100vh - ${(divHeight ?? 0) + 121}px)`
        : 'auto',
    },
  };

  const postsAttrs = {
    ...(isCommentsMode
      ? {}
      : {
          role: 'complementary',
          'aria-label': 'social conversation',
        }),
  };

  return (
    <div {...postsAttrs} className={styles.postView}>
      <section aria-label="conversation controls" ref={divRef}>
        <div
          className={cn(styles.postMenu, {
            [styles.postMenuWithNewPost]: isCommentsMode,
          })}
        >
          {contentFilterDisable && !isCommentsMode && (
            <Button
              variant="text"
              className={styles.showPreviewLevelLink}
              onClick={onGoToAllPosts}
            >
              <ArrowLeft className={styles.allPostIcon} />
              <span className={styles.allPosts}>All posts</span>
            </Button>
          )}

          {isCommentsMode && (
            <>
              {pageData.posts.length === 0 && isLoaded && (
                <p className={styles.noPosts}>
                  Be the first to ask a question about this topic.
                </p>
              )}
              <NewPostInternal
                linkContext={
                  resourceTitle || assetLink
                    ? {
                        ranges: [],
                        resourceTitle,
                        assetLink,
                      }
                    : undefined
                }
                link={filters.link}
                onSubmitCallback={onPostAdd}
                themeName={themeName}
                disableContentFilter={!!contentFilterDisable}
                dialog={false}
                external={false}
                trackingGlobalData={trackingGlobalDataValues}
                additionalTrackingEngines={additionalTrackingEngines}
              />
            </>
          )}

          {!isCommentsMode && (
            <Filters
              userId={userId}
              tableOfLinks={tableOfLinks}
              onFiltersUpdateCallback={onFilterUpdate}
              onAddPost={onAddPost}
              hideAddBtn={addNewButtonHide || hasPostsError}
              filtersValue={filters}
              contentFilterDisable={contentFilterDisable}
            />
          )}
        </div>

        {!isCommentsMode && (
          <Divider
            role="presentation"
            className={styles.divider}
            variant="fullWidth"
          />
        )}

        {contentFilterDisable && !isCommentsMode && postCount > 0 && (
          <div className={styles.linkTitle}>
            {postCount} Paragraph post
            {postCount > 1 ? 's' : ''}
          </div>
        )}
      </section>

      <div
        className={styles.posts}
        ref={scrollableContainer}
        {...postListProps}
      >
        {renderMainPannel()}
      </div>
    </div>
  );
};

const Posts: FC<PostsProps> = ({
  ids,
  tenantId,
  link,
  env = 'stage',
  themeName,
  getUserCallback,
  changeInputCallback,
  onPostsMarkupChangeCallback,
  onUnmountCallback,
  onloginRequireCallback,
  resourceTitle,
  assetLink,
  tableOfLinks,
  linkClickCallback,
  hideAddNewButton,
  disableContentFilter,
  layoutMode,
  trackingGlobalData,
  additionalTrackingEngines,
}) => (
  <A11yLiveProvider>
    <ThemeProvider theme={getTheme(themeName, tenantId)}>
      <CommonDataProvider
        env={env}
        tenantId={tenantId}
        link={link}
        getUserCallback={getUserCallback}
        onloginRequireCallback={onloginRequireCallback}
        tableOfLinks={tableOfLinks}
        themeName={themeName}
      >
        <LayoutModeProvider layoutMode={layoutMode || 'default'}>
          <ModalProvider>
            <PostProvider>
              <PostsInner
                ids={ids}
                themeName={themeName}
                changeInputCallback={changeInputCallback}
                onPostsMarkupChangeCallback={onPostsMarkupChangeCallback}
                onUnmountCallback={onUnmountCallback}
                linkClickCallback={linkClickCallback}
                hideAddNewButton={hideAddNewButton}
                disableContentFilter={disableContentFilter}
                trackingGlobalData={trackingGlobalData}
                additionalTrackingEngines={additionalTrackingEngines}
                resourceTitle={resourceTitle}
                assetLink={assetLink}
              />
            </PostProvider>
          </ModalProvider>
        </LayoutModeProvider>
      </CommonDataProvider>
    </ThemeProvider>
  </A11yLiveProvider>
);

export default Posts;
