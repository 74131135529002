import React, { FC } from 'react';
import { Resizable } from 're-resizable';
import styles from './DialogModal.module.scss';

interface Props {
  width?: number | string;
  height?: number | string;
  minWidth?: number;
  minHeight?: number;
  enable?: boolean;
}

const ResizableBox: FC<Props> = ({
  width = 'auto',
  height = '100%',
  enable = true,
  minWidth = 360,
  minHeight = 270,
  children,
}) => (
  <Resizable
    defaultSize={{ width, height }}
    minWidth={minWidth}
    minHeight={minHeight}
    enable={{
      top: false,
      right: false,
      bottom: false,
      left: false,
      topRight: false,
      bottomRight: enable,
      bottomLeft: false,
      topLeft: false,
    }}
    handleComponent={{
      bottomRight: <span className={enable ? styles.resize : ''} />,
    }}
    style={{
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {children}
  </Resizable>
);

export default ResizableBox;
