import { Quill } from 'react-quill';
import { a11yClick } from '../../../../utils/a11y';

import BaseTooltip from './base-tooltip';
import { USER, SELECTION_CHANGE, Range } from './types';

const LinkBlot = Quill.import('formats/link');

const TOOLTIP_INPUT_IDREF = 'quil-tooltip-input-label';

export default class SnowTooltip extends BaseTooltip {
  static TEMPLATE = [
    '<a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a>',
    `<label for="${TOOLTIP_INPUT_IDREF}">
      <span class="ql-tooltip-label ql-tooltip-label-link">Enter link:</span>
      <span class="ql-tooltip-label ql-tooltip-label-formula">Enter formula:</span>
      <span class="ql-tooltip-label ql-tooltip-label-video">Enter video:</span>
    </label>`,
    `<input id="${TOOLTIP_INPUT_IDREF}" type="text" data-link="https://pearson.com" data-video="YouTube URL">`,
    '<a tabindex="0" role="button" aria-label="save" class="ql-action"></a>',
    '<a tabindex="0" role="button" aria-label="cancel" class="ql-remove"></a>',
  ].join('');

  constructor(quill: any, bounds: any) {
    super(quill, bounds);
    this.preview = this.root.querySelector('a.ql-preview');
  }

  listen() {
    super.listen();

    const [saveBtn, cancelBtn] = this.root.querySelectorAll(
      'a.ql-action, a.ql-remove',
    );

    const saveHandler = (event: Event) => {
      if (a11yClick(event as any)) {
        if (this.root.classList.contains('ql-editing')) {
          this.save();
        } else {
          this.edit('link', this.preview.textContent);
        }

        event.preventDefault();
      }
    };
    saveBtn.addEventListener('click', saveHandler);
    saveBtn.addEventListener('keypress', saveHandler);

    const cancelHandler = (event: MouseEvent) => {
      if (a11yClick(event as any)) {
        if (this.linkRange != null) {
          const range = this.linkRange;
          this.restoreFocus();
          this.quill.formatText(range, 'link', false, USER);
          delete this.linkRange;
        }

        event.preventDefault();
        this.hide();
      }
    };
    cancelBtn.addEventListener('click', cancelHandler);
    cancelBtn.addEventListener('keypress', cancelHandler);

    this.quill.on(
      SELECTION_CHANGE,
      (range: any, oldRange: any, source: any) => {
        if (range == null) return;
        if (range.length === 0 && source === USER) {
          const [link, offset] = this.quill.scroll.descendant(
            LinkBlot,
            range.index,
          );
          if (link != null) {
            this.linkRange = new Range(range.index - offset, link.length());
            const preview = LinkBlot.formats(link.domNode);
            this.preview.textContent = preview;
            this.preview.setAttribute('href', preview);
            this.show();
            this.position(this.quill.getBounds(this.linkRange));
            return;
          }
        } else {
          delete this.linkRange;
        }
        this.hide();
      },
    );
  }

  show() {
    super.show();
    this.root.removeAttribute('data-mode');
  }
}
