import React from 'react';
import { useCommonDataContext } from '../../providers/commonDataContext';
import { getImageUrl } from '../../utils/cloudfront';
import PrimaryButton from '../PrimaryButton';
import styles from './LoadPostError.module.scss';

const LoadPostError = ({ repeatAction }: { repeatAction: () => void }) => {
  const { env } = useCommonDataContext();
  const failedImageUrl = getImageUrl(env, 'posts-load-fail.png');

  return (
    <div className={styles.postsError}>
      <p className={styles.labelBlock}>
        <span className={styles.label}>Failed to load posts</span>
      </p>
      <PrimaryButton onClick={repeatAction}>Try again</PrimaryButton>
      <img
        src={failedImageUrl}
        className={styles.image}
        alt="Failed to load posts"
      />
    </div>
  );
};

export default LoadPostError;
