import React, { useMemo, useRef, FC, MutableRefObject } from 'react';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import cn from 'classnames';

import TableLinkItem from './TableLinkItem';
import TableLinkItemChildren from './TableLinkItemChildren';

import {
  findLinkNameByLink,
  getLinkFromPath,
  normalizeLinkName,
} from '../../utils/linkHelpers';
import { TableLinksListProps, RenderTreeProps } from './TableLinksList.props';
import styles from './TableLinksList.module.scss';

const renderTree: RenderTreeProps = (
  linkFilterObjs,
  parentId = '',
  parentName = '',
  value,
  onSelectItem,
) =>
  linkFilterObjs.map((linkFilterObj) => {
    const valueId = parentId + linkFilterObj.id;
    const level = parentId.split('/').length;
    const isFlatRoot = parentId === '' && linkFilterObjs.length === 1;
    const isOpened =
      (value && value.startsWith(valueId) && value !== valueId) || isFlatRoot;

    return (
      <div role="listitem" key={valueId}>
        {Array.isArray(linkFilterObj.subLinks) ? (
          <TableLinkItemChildren
            open={isOpened}
            isSelected={value === valueId}
            name={normalizeLinkName(linkFilterObj.name, parentName)}
            level={level}
            onSelectItem={() => onSelectItem(valueId)}
          >
            {renderTree(
              linkFilterObj.subLinks,
              `${valueId}/`,
              linkFilterObj.name,
              value,
              onSelectItem,
            )}
          </TableLinkItemChildren>
        ) : (
          <TableLinkItem
            className={styles.menuItem}
            isSelected={value === valueId}
            name={normalizeLinkName(linkFilterObj.name, parentName)}
            level={level}
            onSelectItem={() => onSelectItem(valueId)}
          />
        )}
      </div>
    );
  });

const TableLinksList: FC<TableLinksListProps> = ({
  className,
  tableOfLinks,
  value,
  onChange,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  const selectedLink = !disabled && value && getLinkFromPath(value);
  const defaultLink = value && getLinkFromPath(value);
  const selectedValue = selectedLink
    ? findLinkNameByLink(selectedLink, tableOfLinks)
    : defaultLink && findLinkNameByLink(defaultLink, tableOfLinks);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (newValue: string) => {
    onChange(newValue);
    handleClose();
  };

  const linkFilterTree = useMemo(
    () => renderTree(tableOfLinks || [], '', '', value, onSelect),
    [tableOfLinks, onChange],
  );

  return (
    <>
      <button
        type="button"
        className={cn(styles.expandButton, className)}
        onClick={handleClick}
        disabled={disabled}
        aria-expanded={!!anchorEl}
        ref={buttonRef}
        data-testid="test-table-links-btn"
      >
        <span className={styles.valueText}>{selectedValue}</span>
        {anchorEl ? (
          <ArrowDropUp className={styles.icon} />
        ) : (
          <ArrowDropDown className={styles.icon} />
        )}
      </button>
      <Popover
        classes={{
          paper: styles.list,
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: buttonRef?.current?.offsetWidth },
          role: 'region',
          'aria-label': 'Table of contents.',
        }}
      >
        <List
          component="div"
          role="list"
          style={{
            maxHeight: `calc(90vh - ${
              buttonRef?.current?.getBoundingClientRect().bottom
            }px)`,
            overflowX: 'scroll',
          }}
        >
          {linkFilterTree}
        </List>
      </Popover>
    </>
  );
};

export default TableLinksList;
