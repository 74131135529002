import { Enviroment } from '../types';

const getBaseUrl = (env: Enviroment): string => {
  switch (env) {
    case 'dev':
    case 'stage':
    case 'stg':
    case 'perf':
    case 'qa':
      return 'https://static.social-dev.pearsondev.tech';
    case 'prod':
    default:
      return 'https://dfyn1fx6xeb39.cloudfront.net';
  }
};

// eslint-disable-next-line import/prefer-default-export
export const getImageUrl = (env: Enviroment, image: string): string => {
  const baseUrl = getBaseUrl(env);
  return `${baseUrl}/social-application/images/${image}`;
};
