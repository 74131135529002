import React, { createContext, FC, useContext, useMemo, useState } from 'react';

interface PostContextValue {
  areRepliesVisible: (postId: number) => boolean;
  toggleRepliesVisibility: (postId: number) => void;
  setRepliesVisibility: (postId: number, visible: boolean) => void;
}

const PostContext = createContext<PostContextValue>({} as any);

export const PostProvider: FC = ({ children }) => {
  const [openedReplies, setOpenedReplies] = useState<number[]>([]);

  const areRepliesVisible = (postId: number) =>
    openedReplies.indexOf(postId) !== -1;

  const setRepliesVisibility = (postId: number, visible: boolean) => {
    setOpenedReplies((ids) =>
      visible ? [...ids, postId] : ids.filter((id) => id !== postId),
    );
  };

  const toggleRepliesVisibility = (postId: number) => {
    setRepliesVisibility(postId, !areRepliesVisible(postId));
  };

  const value = useMemo(
    () => ({
      areRepliesVisible,
      toggleRepliesVisibility,
      setRepliesVisibility,
    }),
    [openedReplies],
  );

  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};

export const usePostContext = () => useContext(PostContext);
