import { Quill } from 'react-quill';
import katex from 'katex';

import { QLFORMULA } from '../../../utils/contentHelpers';

const Embed = Quill.import('blots/embed');

class CustomFormula extends Embed {
  static create(value: any) {
    const node = super.create(value);
    if (typeof value === 'string') {
      katex.render(value, node, {
        throwOnError: false,
        errorColor: '#f00',
      });
      node.setAttribute('data-value', value);
    }
    return node;
  }

  static value(domNode: any) {
    return domNode.tagName === QLFORMULA.toUpperCase()
      ? domNode.textContent
      : domNode.getAttribute('data-value');
  }

  html() {
    const { formula } = this.value();
    return `<span>${formula}</span>`;
  }
}

CustomFormula.blotName = 'formula';
CustomFormula.className = QLFORMULA;
CustomFormula.tagName = 'SPAN';

export default CustomFormula;
