import React, { FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Form, { Field, useForm } from 'rc-field-form';
import cn from 'classnames';

import { useId } from 'react-id-generator';
import { useLegacyId } from '@yoda/a11y-tools/dist/react/hooks';
import TextEditor from '../TextEditor';
import { getUserData } from '../../services/SocialAPI';
import FormFieldLabel from '../FormFieldLabel';
import TableLinksList from '../TableLinksList';
import Avatar from '../Avatar';

import {
  clampStringLength,
  extractLinksFromRoot,
  handleExtractedRootLink,
  initValue,
} from '../../utils/linkHelpers';
import {
  convertDataToCommonFormat,
  convertCommonToMentionsFormat,
} from '../../utils/contentHelpers';
import {
  createTitleValidationRule,
  createContentValidationRule,
  useIsFormDirty,
} from './helpers';

import { FormPostData, PostContent, User } from '../../types';
import { FormPostProps, FormValues } from './FormPost.props';
import styles from './FormPost.module.scss';
import FormFieldHint from '../FormFieldHint';
import PrimaryButton from '../PrimaryButton';
import useFileUpload from '../../hooks/useFileUpload';
import { useCommonDataContext } from '../../providers/commonDataContext';
import { useA11yLiveContext } from '../../providers/a11yLiveContext';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { A11Y_ID_PREFIX } from '../../utils/a11y';
import SrOnlyText from '../A11yRelatedLib/SrOnlyText';

const TITLE_MAX_LENGTH = 100;

const allowedAttachmentTypes = ['application/pdf', 'image/jpeg', 'image/png'];

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderColor: 'var(--input-border-color)',
    },
  },
})(TextField);

const FormPost: FC<FormPostProps> = ({
  content,
  title = '',
  anonymous,
  link,
  onSubmitCallback,
  onCancelCallback,
  submitActionText,
  hideTitle,
  hideContentSelect,
  questionPlaceholder,
  mentionedUser,
  footerClassName = '',
  children,
  isRequestInProgress,
  error,
  inlineMode = false,
  isReply = false,
  showRTEPanel = false,
  setIsEmpty,
  disableAutoFocus,
}) => {
  const [form] = useForm();
  const [user, setUser] = useState<User>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [postAnonymous, setPostAnonymous] = useState(false);

  const { env, tableOfLinks, token, userId } = useCommonDataContext();

  const { isCommentsMode } = useLayoutModeContext();
  const { polite: livePolite } = useA11yLiveContext();

  const [postTextareaHtmlId, titleDescrHtmlId] = useId(2, A11Y_ID_PREFIX);

  useEffect(() => {
    if (userId && token) {
      getUserData(userId, env).then(setUser);
    }
  }, [userId, token, env]);

  useEffect(() => {
    setPostAnonymous(anonymous || false);
  }, [anonymous]);

  const initialFormValues: FormValues = useMemo(
    () => ({
      title: title || '',
      content: convertCommonToMentionsFormat(content || { html: '' }),
      link: link && tableOfLinks ? initValue(tableOfLinks, link) : '',
    }),
    [title, content, link],
  );

  // FIXME: setPostContent shouldn't exist. this is to emulate useState behavior to befriend rc-field-form and QuestionEditor
  const setPostContent = (
    state: SetStateAction<PostContent>,
    onChange: (content: PostContent) => void,
  ) => {
    if (typeof state === 'function') {
      const currentContent = form.getFieldValue('content');
      const newContent = state(currentContent);
      onChange(newContent);
    } else {
      onChange(state);
    }
  };

  const checkChanges = () => {
    const currentFormData = form.getFieldsValue(['title', 'link', 'content']);
    const isConfirmationEnabled = useIsFormDirty(
      initialFormValues,
      currentFormData,
    );
    setIsEmpty(isConfirmationEnabled);
  };

  const { pickFile, uploadingFiles, attachmentError, isFileUploading } =
    useFileUpload({
      allowedTypes: allowedAttachmentTypes,
      maxFileSize: '4MB',
      userToken: token,
      env,
      onFileUploaded(file) {
        const prevContent = form.getFieldValue('content') as PostContent;
        const oldAttachments = prevContent.attachments || [];
        const attachments = [...oldAttachments, file];
        form.setFieldsValue({ content: { ...prevContent, attachments } });
        checkChanges();
      },
      checkFileNameIsValid(file) {
        const { attachments = [] } = form.getFieldValue(
          'content',
        ) as PostContent;

        return !attachments.find((attachment) => file.name === attachment.name);
      },
    });

  const handleFormValuesChange = (changedValues: Partial<FormPostData>) => {
    Object.keys(changedValues).map((field) =>
      form.setFields([{ name: field, errors: [], warnings: [] }]),
    );
    checkChanges();
  };

  const onSubmit = async (values: any) => {
    setPostAnonymous(false);

    const htmlContent = convertDataToCommonFormat(values.content);
    const linkValue = values.link;

    return onSubmitCallback({
      content: htmlContent,
      title: hideTitle ? undefined : values.title,
      anonymous: postAnonymous,
      link:
        linkValue && tableOfLinks
          ? handleExtractedRootLink(tableOfLinks, linkValue)
          : undefined,
    }).then(() => form.resetFields());
  };

  const handleKeyDown = (e: any) => {
    e.stopPropagation();
  };

  const submitForm = () => {
    form.submit();
  };

  const fieldLengthHint = (
    value: string | null | undefined,
    max: number,
  ): string => {
    const len = value ? value.trim().length : 0;
    if (len === max) {
      livePolite.read('You have reached the maximum field length.');
    }

    if (len === Math.round(max / 2)) {
      livePolite.read(`${len} characters of ${max} maximum length reached`);
    }

    return `${len}/${max}`;
  };

  const onCancelValues = () => {
    form.resetFields();
    if (onCancelCallback) {
      onCancelCallback();
    }
  };

  const textEditorHtmlId = useLegacyId();

  return (
    <Form
      className={cn(styles.form, { [styles.formComments]: isCommentsMode })}
      form={form}
      validateTrigger="onSubmit"
      initialValues={initialFormValues}
      onValuesChange={handleFormValuesChange}
      onFinish={onSubmit}
      onFinishFailed={() => document.getElementById(textEditorHtmlId)?.focus()}
      aria-label="New thread post"
    >
      {!inlineMode ? (
        <div className={styles.formPost}>
          <div className={styles.scrollable}>
            {children && <div className={styles.item}>{children}</div>}

            {tableOfLinks && link && !hideContentSelect && (
              <Field name="link">
                {(control, meta) => (
                  <div className={styles.item}>
                    <FormFieldLabel
                      htmlFor="contentSelect"
                      label="Chapter"
                      error={!!meta.errors.length}
                    />
                    <TableLinksList
                      id="contentSelect"
                      className={styles.contentSelect}
                      tableOfLinks={
                        tableOfLinks?.length === 1
                          ? extractLinksFromRoot(tableOfLinks)
                          : tableOfLinks
                      }
                      disabled={isRequestInProgress}
                      value={control.value}
                      onChange={control.onChange}
                    />
                  </div>
                )}
              </Field>
            )}

            {!hideTitle && (
              <Field name="title" rules={[createTitleValidationRule()]}>
                {(control, meta) => (
                  <div className={cn(styles.item, styles.withCounter)}>
                    <SrOnlyText aria-hidden="true" id={titleDescrHtmlId}>
                      Maximum {TITLE_MAX_LENGTH} symbols.
                    </SrOnlyText>
                    <FormFieldLabel
                      htmlFor="title"
                      label="Title"
                      error={!!meta.errors.length}
                    />
                    <StyledTextField
                      variant="outlined"
                      id="title"
                      className={cn(styles.postTitleInput, {
                        [styles.hasError]: !!meta.errors.length,
                      })}
                      inputProps={{
                        'aria-describedby': titleDescrHtmlId,
                      }}
                      placeholder="Give your post a title"
                      size="small"
                      disabled={isRequestInProgress}
                      error={!!meta.errors.length}
                      {...control}
                      onChange={({ target }) =>
                        control.onChange(
                          clampStringLength(target.value, TITLE_MAX_LENGTH),
                        )
                      }
                      onKeyDown={handleKeyDown}
                    />
                    <FormFieldHint
                      hasError={!!meta.errors?.length}
                      hint={meta.errors?.[0]}
                      secondaryHint={fieldLengthHint(
                        control.value,
                        TITLE_MAX_LENGTH,
                      )}
                    />
                  </div>
                )}
              </Field>
            )}

            <Field
              name="content"
              rules={[
                createContentValidationRule({
                  min: hideTitle || inlineMode ? 1 : 0,
                  required: true,
                }),
              ]}
            >
              {(control, meta) => (
                <div
                  className={cn(
                    styles.item,
                    styles.withCounter,
                    styles.fullHeight,
                  )}
                >
                  <FormFieldLabel
                    htmlFor={postTextareaHtmlId}
                    required
                    label={isReply ? 'Reply' : 'Post'}
                    error={!!meta.errors.length}
                  />

                  <TextEditor
                    htmlId={postTextareaHtmlId}
                    postContent={control.value}
                    setPostContent={(state) =>
                      setPostContent(state, control.onChange)
                    }
                    placeholder={questionPlaceholder}
                    errors={meta.errors}
                    onCancel={onCancelCallback}
                    submitActionText={submitActionText}
                    hideSubmit
                    mentionedUser={mentionedUser}
                    pickFile={pickFile}
                    uploadingFiles={uploadingFiles}
                    attachmentError={attachmentError}
                    inlineMode={false}
                    onImageLoading={setIsImageLoading}
                  />
                </div>
              )}
            </Field>
          </div>

          {error && (
            <FormFieldHint
              className={styles.errorMessage}
              hasError
              hint={error}
            />
          )}

          <Divider role="presentation" className={styles.bottomDivider} />

          <div className={cn(styles.newPostFooter, footerClassName)}>
            <div className={styles.userAvatar}>
              <Avatar user={user} />
              <span className={styles.avatarUserName}>{user?.userName}</span>
            </div>
            <div className={cn(styles.item, styles.saveButtonContainer)}>
              <PrimaryButton
                type="submit"
                className={styles.saveButton}
                disabled={
                  isRequestInProgress || isFileUploading || isImageLoading
                }
              >
                {submitActionText}
              </PrimaryButton>
              {onCancelCallback && (
                <Button
                  className={styles.saveButton}
                  disabled={isRequestInProgress}
                  onClick={onCancelCallback}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={cn(styles.inlineEditorRow, styles.formPost)}>
          <div>
            <Avatar user={user} className={styles.avatar} />
          </div>
          <Field
            name="content"
            rules={[
              createContentValidationRule({
                min: hideTitle || inlineMode ? 1 : 0,
              }),
            ]}
          >
            {(control, meta) => (
              <div
                className={cn(
                  styles.item,
                  styles.withCounter,
                  styles.inlineEditorContainer,
                )}
              >
                <TextEditor
                  postContent={control.value}
                  setPostContent={(state) =>
                    setPostContent(state, control.onChange)
                  }
                  placeholder={questionPlaceholder}
                  errors={meta.errors}
                  onCancel={isReply ? onCancelValues : undefined}
                  onSubmit={submitForm}
                  submitActionText={submitActionText}
                  hideSubmit={false}
                  mentionedUser={mentionedUser}
                  pickFile={pickFile}
                  uploadingFiles={uploadingFiles}
                  attachmentError={attachmentError}
                  showPanel={showRTEPanel}
                  inlineMode
                  disableAutoFocus={disableAutoFocus}
                  htmlId={textEditorHtmlId}
                />
                {error && (
                  <FormFieldHint
                    className={styles.errorMessage}
                    hasError
                    hint={error}
                  />
                )}
              </div>
            )}
          </Field>
        </div>
      )}
    </Form>
  );
};

export default FormPost;
