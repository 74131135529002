import React, { FC, useState } from 'react';
import FormFieldLabel from '../FormFieldLabel';
import QuotationBlock from '../QuotationBlock';
import { FormPostData } from '../../types';
import { findLinkNameByLink } from '../../utils/linkHelpers';
import FormPost from '../FormPost/FormPost';
import { NewPostModalProps } from './NewPostModal.props';
import styles from './NewPostModal.module.scss';

import DialogModal, { DRAGGABLE_ELEMENT } from '../DialogModal';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { useCommonDataContext } from '../../providers/commonDataContext';

const NewPostModal: FC<NewPostModalProps> = ({
  open,
  post,
  link,
  disableContentFilter,
  highlight,
  isFullScreen,
  inlineMode = false,
  onCancelCallback,
  onCancelRTE,
  onUpdatePost,
  onCreatePost,
  setIsEmpty,
  isRequestInProgress,
}) => {
  const { tableOfLinks, userId } = useCommonDataContext();
  const [error, setError] = useState<string>();
  const { isCommentsMode } = useLayoutModeContext();

  const { id, content, title, anonymous } = post || {};

  const handleSubmit = async (newPost: FormPostData) => {
    try {
      setError(undefined);

      if (id) {
        await onUpdatePost(newPost);
      } else {
        await onCreatePost(newPost);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setError('Something went wrong - please try again.');
    }
  };

  const formPostProps = {
    isRequestInProgress,
    error,
    setIsEmpty,
    newPost: true,
    onSubmitCallback: handleSubmit,
    hideContentSelect: !!id || !!highlight || disableContentFilter,
    hideTitle: isCommentsMode,
    ...(id
      ? {
          content,
          title,
          anonymous,
        }
      : {
          tableOfLinks,
          link,
        }),
  };

  return inlineMode ? (
    <FormPost
      footerClassName={!isFullScreen ? DRAGGABLE_ELEMENT : ''}
      questionPlaceholder={
        isCommentsMode
          ? 'Write a comment or question in this field'
          : 'Write a comment...'
      }
      submitActionText="Post comment"
      inlineMode
      showRTEPanel={!!userId}
      {...formPostProps}
      disableAutoFocus
      onCancelCallback={onCancelRTE}
    />
  ) : (
    <DialogModal
      open={open}
      isFullScreen={isFullScreen}
      isResizable={!isFullScreen}
      title={id ? 'Edit post' : 'New post'}
      onCancelCallback={onCancelCallback}
    >
      <FormPost
        footerClassName={!isFullScreen ? DRAGGABLE_ELEMENT : ''}
        questionPlaceholder="Ask a question, share a tip, or start a discussion with all users reading this book"
        submitActionText={id ? 'Update' : 'Post'}
        {...formPostProps}
      >
        {tableOfLinks && link && highlight && (
          <>
            <FormFieldLabel label="Your selection" />
            <QuotationBlock
              highlight={highlight}
              linkName={findLinkNameByLink(link, tableOfLinks)}
              className={styles.quotationBlock}
            />
          </>
        )}
      </FormPost>
    </DialogModal>
  );
};

export default NewPostModal;
