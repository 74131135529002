import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { NeedsReviewModalProps } from './NeedsReviewModal.props';
import styles from './NeedsReviewModal.module.scss';
import { useCommonDataContext } from '../../providers/commonDataContext';
import { getImageUrl } from '../../utils/cloudfront';

const NeedsReviewModal: FC<NeedsReviewModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { env } = useCommonDataContext();
  const noraImgUrl = getImageUrl(env, 'nora.png');

  const onClose = (event: any, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    onCancel();
  };

  return (
    <Dialog open className={styles.dialog} onClose={onClose} hideBackdrop>
      <div className={styles.container}>
        <img alt="" src={noraImgUrl} />

        <div className={styles.messageBlock}>
          <h2 className={styles.title}>Thanks for posting!</h2>
          <div className={styles.subtitle}>
            Once we make sure your post and its attachments fit our&nbsp;
            <strong>
              <a
                className={styles.communityGuidelinesLink}
                href="https://www.pearson.com/en-us/pearsonplus/community-guidelines.html"
                target="_black"
              >
                Community Guidelines
              </a>
            </strong>
            , it will go public.
          </div>
          <div className={styles.actionBar}>
            {/* <Button className={styles.cancelButton} onClick={onCancel}>Edit post</Button> */}
            <Button className={styles.confirmButton} onClick={onConfirm}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NeedsReviewModal;
