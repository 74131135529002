import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DialogModal from '../DialogModal';

import { ReplyDeleteModalProps } from './ReplyDeleteModal.props';
import styles from './ReplyDeleteModal.module.scss';

const ReplyDeleteModal: FC<ReplyDeleteModalProps> = ({
  open = true,
  replyId,
  onCancel,
  onDelete,
}) => (
  <DialogModal
    className={styles.deleteReplyModal}
    isFullScreen={false}
    open={open}
    onCancelCallback={onCancel}
    actions={
      <Button onClick={() => replyId && onDelete(replyId)}>Delete</Button>
    }
  >
    <Typography className={styles.question}>
      Delete reply permanently?
    </Typography>

    <Typography className={styles.clarification}>
      If you&apos;re sure, we&apos;re sure.
    </Typography>
  </DialogModal>
);

export default ReplyDeleteModal;
