import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import cn from 'classnames';

import { ReactComponent as AttachmentIcon } from '../../assets/images/editor/attachment.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import { AttachmentButtonProps } from './Attachments.props';
import styles from './Attachments.module.scss';

const ellipseFilename = (filename: string, limit: number) => {
  const dotIndex = filename.lastIndexOf('.');
  const name = dotIndex === -1 ? filename : filename.substr(0, dotIndex);
  const ext = dotIndex === -1 ? undefined : filename.substr(dotIndex);

  if (name.length <= limit * 2 + 3) {
    return filename;
  }

  return [
    [name.substr(0, limit), name.substr(name.length - limit)].join('...'),
    ext,
  ].join('');
};

const AttachmentButton: FC<AttachmentButtonProps> = ({
  className,
  name,
  url,
  progress,
  onDeleteClick,
}) => {
  const handleDeleteClick: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onDeleteClick?.();
  };

  const content = (
    <>
      <AttachmentIcon className={styles.attachmentIcon} />
      <span className={styles.name}>{ellipseFilename(name, 5)}</span>
      {!!progress && (
        <CircularProgress
          className={styles.progress}
          size="18px"
          variant="determinate"
          value={progress}
        />
      )}
      {!!(url && onDeleteClick) && (
        <DeleteIcon
          className={styles.deleteButton}
          onClick={handleDeleteClick}
        />
      )}
    </>
  );

  return url ? (
    <a
      className={cn(styles.attachmentButton, className)}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {content}
    </a>
  ) : (
    <div className={cn(styles.attachmentButton, className)}>{content}</div>
  );
};

export default AttachmentButton;
