import React, { FC } from 'react';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@greenville/mui-theme';

import ActionsMenuPopup, { ActionMenuItem } from '../ActionsMenuPopup';

import { PostActionsProps } from './PostCard.props';
import { getPageLink, isFirstLevelLink } from '../../utils/linkHelpers';

import styles from './styles';
import SrOnlyText from '../A11yRelatedLib/SrOnlyText';
import { useLayoutModeContext } from '../../providers/layoutModeContext';

const useStyles = makeStyles(styles);

const PostActionsMenuPopup: FC<PostActionsProps> = ({
  post,
  onFavorite,
  onViewInContent,
  onReport,
  onEdit,
  onDelete,
  isOwner = false,
  tableOfLinks,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { link, favoriteBy, content } = post;
  const showView = link && !isFirstLevelLink(link);

  const { isCommentsMode } = useLayoutModeContext();
  const classes = useStyles({ isCommentsMode });

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: any, ...params: unknown[]) => {
    if (action) {
      action(...params);
    }
    onCloseMenu();
  };

  return (
    <div className={classes.actions}>
      {favoriteBy && favoriteBy[0] && (
        <Icon
          name="BookmarkFilled"
          onClick={() => handleAction(onFavorite, post)}
        />
      )}
      {favoriteBy && favoriteBy[0] ? <SrOnlyText>Saved post</SrOnlyText> : null}
      <ActionsMenuPopup
        anchorEl={anchorEl}
        onOpen={onOpenMenu}
        onClose={onCloseMenu}
      >
        {showView && tableOfLinks && (
          <ActionMenuItem
            onClick={() =>
              handleAction(
                onViewInContent,
                getPageLink(link, tableOfLinks),
                content.linkContext?.ranges || [],
              )
            }
          >
            <Icon name="BookOutlined" />
            View
          </ActionMenuItem>
        )}

        {!isCommentsMode && (
          <ActionMenuItem onClick={() => handleAction(onFavorite, post)}>
            <Icon
              name={favoriteBy?.length ? 'BookmarkFilled' : 'BookmarkOutlined'}
            />
            {favoriteBy?.length ? 'Unsave' : 'Save'}
          </ActionMenuItem>
        )}

        {isOwner ? (
          [
            <ActionMenuItem
              key="edit"
              onClick={() => handleAction(onEdit, post)}
            >
              <Icon name="Edit" />
              Edit
            </ActionMenuItem>,
            <ActionMenuItem
              key="delete"
              onClick={() => handleAction(onDelete, post)}
            >
              <Icon name="Delete" />
              Delete
            </ActionMenuItem>,
          ]
        ) : (
          <ActionMenuItem onClick={() => handleAction(onReport, post)}>
            <ReportProblemOutlined />
            Report
          </ActionMenuItem>
        )}
      </ActionsMenuPopup>
    </div>
  );
};

export default PostActionsMenuPopup;
