import { Quill } from 'react-quill';

const Link = Quill.import('formats/link');
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = [
  'http',
  'https',
  'mailto',
  'tel',
  'radar',
  'rdar',
  'smb',
  'sms',
];

export default class CustomLinkSanitizer extends Link {
  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(
      (protocol: string) => sanitizedUrl.startsWith(protocol),
    );

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `https://${sanitizedUrl}`;
  }

  static tagName = 'a';

  static create(link: string): HTMLElement {
    const node = super.create(link);
    node.setAttribute('class', 'ql-inline-link');
    return node;
  }
}
