import React, {
  createRef,
  forwardRef,
  KeyboardEventHandler,
  useState,
} from 'react';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import cn from 'classnames';
import { useId } from 'react-id-generator';
import { AccordionProps } from './Accordion.props';
import styles from './Accordion.module.scss';
import { A11Y_ID_PREFIX } from '../../utils/a11y';
import SrOnlyText from '../A11yRelatedLib/SrOnlyText';

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  ({ label, children, disabled }, ref) => {
    const [expanded, setExpanded] = useState(true);
    const handleHeaderClick = () => setExpanded(!expanded);
    const [htmlId, btnDescrId] = useId(2, A11Y_ID_PREFIX);
    const btnRef = createRef<HTMLButtonElement>();

    const onThreadKeyDown: KeyboardEventHandler = (event) => {
      if (btnRef.current && event.shiftKey && event.code === 'Home') {
        btnRef.current.focus();
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div
        ref={ref}
        onKeyDown={onThreadKeyDown}
        className={styles.accordion}
        role="listitem"
      >
        <button
          ref={btnRef}
          className={cn(styles.header, disabled && styles.headerDisabled)}
          type="button"
          onClick={disabled ? undefined : handleHeaderClick}
          aria-controls={htmlId}
          aria-expanded={!!expanded}
          aria-describedby={btnDescrId}
        >
          {expanded ? (
            <KeyboardArrowDown className={styles.arrowIcon} />
          ) : (
            <ChevronRight className={styles.arrowIcon} />
          )}
          <span className={styles.label}>{label}</span>
        </button>
        <SrOnlyText id={btnDescrId}>
          Press Shift+Home for focus on this button.
        </SrOnlyText>
        {expanded && <div id={htmlId}>{children}</div>}
      </div>
    );
  },
);

export default Accordion;
