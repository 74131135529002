import React, { FC, useState, useRef, useEffect } from 'react';
import MUICardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import cn from 'classnames';

import {
  findLinkNameByLink,
  getPageLink,
  isFirstLevelLink,
} from '../../utils/linkHelpers';
import QuotationBlock from '../QuotationBlock';
import Attachments from '../Attachments';

import { CardContentProps } from './CardContent.props';
import { PostContent } from '../../types';
import styles from './CardContent.module.scss';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { convertCommonFormatToMarkup } from '../../utils/contentHelpers';

const TRUNCATED_TEXT_LIMIT = 500;

const CardContent: FC<CardContentProps> = ({
  className,
  classes,
  context,
  tableOfLinks,
  linkClickCallback,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  questionContentClickHandler,
  isViewMode = false,
  postRef,
  headerHtmlId,
  postContentId,
  quoteId,
}) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [isOverflowActive, setOverflowActive] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { link, title, content } = context;

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const { src } = event.target as HTMLImageElement;
      if (src && !src.includes('.giphy.com')) {
        window.open(src, '_blank');
      }
    };

    contentRef?.current?.addEventListener('click', onClick);
    return function cleanup() {
      contentRef?.current?.removeEventListener('click', onClick);
    };
  }, []);

  const isShowMoreButtonVisible =
    (!isViewMode && isOverflowActive) || !isTruncated;
  const { isCommentsMode } = useLayoutModeContext();

  function getContent(isTrunc: boolean, cont: PostContent): PostContent {
    const result = { ...cont };

    if (isOverflowActive && !isTrunc) {
      return result;
    }

    const buff = document.createElement('div');
    const el = document.createElement('div');
    el.innerHTML = result.html;

    let siz = 0;
    let node;
    for (let i = 0, max = el.childNodes.length; i <= max; i += 1) {
      node = el.childNodes[i];
      siz += node && node.textContent ? node.textContent.length : 0;
      buff.append(node);

      if (siz >= TRUNCATED_TEXT_LIMIT) {
        // if overflow on first step (one child with huge content)
        if (i === 0 && node.textContent) {
          node.textContent = node.textContent?.substr(0, TRUNCATED_TEXT_LIMIT);
        }

        const dots = document.createElement('span');
        dots.innerHTML = '&hellip;';
        node.appendChild(dots);

        result.html = buff.innerHTML;
        if (!isOverflowActive) {
          setOverflowActive(true);
        }

        break;
      }
    }

    return result;
  }

  return (
    <MUICardContent
      classes={{
        root: styles.postContentWrapper,
        ...classes,
      }}
      className={cn(styles.postContent, className, {
        [styles.postContentComment]: isCommentsMode,
      })}
    >
      {tableOfLinks && link && !isFirstLevelLink(link) && (
        <QuotationBlock
          className={styles.quotationBlock}
          highlight={content.linkContext?.highlight}
          linkName={findLinkNameByLink(link, tableOfLinks)}
          clickable={!isViewMode}
          htmlId={quoteId}
          onClick={() => {
            if (link && tableOfLinks && linkClickCallback) {
              const pageLink = getPageLink(link, tableOfLinks);
              if (pageLink) {
                linkClickCallback(pageLink, content.linkContext?.ranges);
              }
            }
          }}
        />
      )}

      {title && !isCommentsMode && (
        <Typography id={headerHtmlId} variant="h3" className={styles.title}>
          {title}
        </Typography>
      )}

      {content && (
        <div>
          <div
            id={postContentId}
            className={cn(styles.postText, {
              [styles.truncated]: isTruncated,
              [styles.viewMode]: isViewMode,
              [styles.postTextComment]: isCommentsMode,
            })}
            ref={contentRef}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: convertCommonFormatToMarkup(
                getContent(isTruncated, content),
              ).html!,
            }}
          />
        </div>
      )}

      {isShowMoreButtonVisible && (
        <Button
          classes={{
            label: styles.showButtonLabel,
          }}
          className={styles.showButton}
          onClick={() => {
            if (!isTruncated && postRef) {
              postRef?.current?.scrollIntoView();
            }
            setIsTruncated(!isTruncated);
          }}
        >
          {isTruncated ? 'Show more' : 'Show less'}
        </Button>
      )}

      {!isViewMode && content.attachments && (
        <Attachments
          className={styles.attachmentsBlock}
          attachments={content.attachments}
        />
      )}
    </MUICardContent>
  );
};

export default CardContent;
