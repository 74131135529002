import React, { FC, useState, useEffect, useRef } from 'react';

import useWindowSize from '../../hooks/useWindowSize';
import { addPost, updatePost } from '../../services/SocialAPI';
import { doesPostNeedReview } from '../../utils/postUtils';
import '../../scss/postDialog.scss';

import NeedsReviewModal from '../NeedsReviewModal';
import NewReplyModal from '../NewReplyModal';

import { FormPostData, IPost } from '../../types';
import { NewReplyProps } from './NewReply.props';
import { sendEventCreateItem } from '../../utils/events';
import GenericErrorModal from '../GenericErrorModal';
import { useCommonDataContext } from '../../providers/commonDataContext';
import { useLayoutModeContext } from '../../providers/layoutModeContext';

const NewReply: FC<NewReplyProps> = ({
  postId,
  onSubmitCallback,
  onCancelCallback,
  onCancelRTE,
  reply,
  mentionedUser,
  context,
  dialog = false,
  isReplyToReply = false,
}) => {
  const size = useWindowSize();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const createdPost = useRef<IPost | undefined>(undefined);
  const [discard, setDiscard] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const replyId = reply?.id;

  const { env, tenantId, tableOfLinks, token, userId, onLoginRequire } =
    useCommonDataContext();

  const { isCommentsMode } = useLayoutModeContext();

  useEffect(() => {
    setIsFullScreen(!!(size.width && size.width < 1050));
  }, [size]);

  const createOrUpdateReply = async (data: FormPostData) => {
    if (replyId) {
      return updatePost({
        token,
        id: replyId,
        content: data.content,
        anonymous: data.anonymous,
        tenantId,
        env,
      });
    }
    const createdReply = await addPost({
      token,
      parentId: postId,
      content: data.content,
      anonymous: data.anonymous,
      tenantId,
      env,
    });

    const eventItemType = isReplyToReply ? 'Reply to Reply' : 'Reply';
    sendEventCreateItem(eventItemType, false, data.content);
    return createdReply;
  };

  const handleConfirmReviewModal = async () => {
    if (createdPost.current) {
      const cPost = { ...createdPost.current };
      createdPost.current = undefined;
      onSubmitCallback?.(cPost);
    }
  };

  const handleCreateOrUpdatePost = async (data: FormPostData) => {
    if (!userId && onLoginRequire) {
      onLoginRequire();

      return;
    }

    setIsRequestInProgress(true);
    createdPost.current = await createOrUpdateReply(data);

    setIsRequestInProgress(false);
    if (createdPost.current) {
      if (
        createdPost.current.reviewStatus === 'needs_review' &&
        doesPostNeedReview(data) &&
        !isCommentsMode
      ) {
        setShowReviewModal(true);
      } else {
        onSubmitCallback?.(createdPost.current);
      }
    }
  };

  return (
    <>
      <NewReplyModal
        open={!!dialog && !showReviewModal}
        inlineMode={!dialog}
        contextReply={context}
        tableOfLinks={tableOfLinks}
        reply={reply}
        mentionedUser={mentionedUser}
        isFullScreen={isFullScreen}
        onCancelCallback={() =>
          isEmpty ? setDiscard(true) : onCancelCallback?.()
        }
        onCancelRTE={onCancelRTE}
        onUpdateReply={handleCreateOrUpdatePost}
        onCreateReply={handleCreateOrUpdatePost}
        setIsEmpty={setIsEmpty}
        isRequestInProgress={isRequestInProgress}
      />

      {showReviewModal && (
        <NeedsReviewModal
          onConfirm={handleConfirmReviewModal}
          onCancel={handleConfirmReviewModal}
        />
      )}

      <GenericErrorModal
        open={discard}
        title="Discard"
        message="Are you sure you want to discard this reply?"
        onConfirm={onCancelCallback}
        onClose={() => setDiscard(false)}
      />
    </>
  );
};

export default NewReply;
