import React, { FC } from 'react';

import styles from './SrOnlyText.module.scss';
import { SrOnlyTextProps } from './SrOnlyText.props';

const SrOnlyText: FC<SrOnlyTextProps> = ({ children, ...argv }) => (
  <div {...argv} className={styles.notVisibleSrOnlyText}>
    {children}
  </div>
);

export default SrOnlyText;
