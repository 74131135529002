import React, {
  createRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Post from '../Post';
import VirtualBlock from '../VirtualBlock';
import { PostListProps } from './Posts.props';
import styles from './PostList.module.scss';
import { IPost } from '../../types';
import { useLayoutModeContext } from '../../providers/layoutModeContext';

const INITIAL_POST_HEIGHT: number = 260;

export interface ScrollablePostList {
  scrollToTop(): void;
  scrollToPost(id: number): void;
  scrollToGroup?(linkId: string): void;
}

const PostList = forwardRef<ScrollablePostList, PostListProps>(
  (
    { posts, onPostUpdate, onPostDelete, onLinkClick, scrollableContainer },
    ref,
  ) => {
    const refs = useRef<Record<number, React.RefObject<HTMLDivElement>>>({});
    const { isCommentsMode } = useLayoutModeContext();

    const getBlockRef = (postId: number) => {
      let blockRef = refs.current[postId];

      if (!blockRef) {
        blockRef = createRef<HTMLDivElement>();
        refs.current[postId] = blockRef;
      }

      return blockRef;
    };

    const scrollToPost = (id: number) => {
      const blockRef = getBlockRef(id).current;

      if (!blockRef) {
        return;
      }

      const scroll = () => {
        // TODO: Dont like this solution because of the straight dependency on the DOM structure.
        // Didn`t find the better solution for now
        const el = blockRef?.parentElement?.parentElement
          ?.firstChild as HTMLElement;
        const deltaOffset = el?.offsetHeight || 0;
        scrollableContainer?.current?.scrollTo({
          top: blockRef.offsetTop - deltaOffset,
        });
      };
      scroll();

      setTimeout(() => {
        scroll();
      }, 100);
    };

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        const post = posts[0];

        if (post) {
          scrollToPost(post.id);
        }
      },
      scrollToPost,
    }));

    const getPostNode = (post: IPost) => {
      const PostComponent = (
        <Post
          key={post.id}
          id={post.id}
          onUpdateCallback={onPostUpdate}
          onDeleteCallback={onPostDelete}
          linkClickCallback={onLinkClick}
          postInput={post}
        />
      );

      return isCommentsMode ? (
        PostComponent
      ) : (
        <VirtualBlock
          key={post.id}
          className={styles.virtualBlock}
          initialHeight={INITIAL_POST_HEIGHT}
          ref={getBlockRef(post.id)}
        >
          {PostComponent}
        </VirtualBlock>
      );
    };

    return <section aria-label="Thread.">{posts.map(getPostNode)}</section>;
  },
);

export default PostList;
