import React, { FC, createContext, useContext, memo, Context } from 'react';
import { normalizeThemeName } from '../hooks/useTheme';
import {
  Enviroment,
  GetUserCallback,
  Link,
  TableOfLinks,
  ThemeName,
} from '../types';

interface CommonDataProviderProps {
  env: Enviroment;
  tenantId: number;
  link: Link;
  getUserCallback?: GetUserCallback;
  onloginRequireCallback?: () => void;
  tableOfLinks?: TableOfLinks[];
  themeName?: ThemeName;
}

export interface CommonDataContextProps {
  env: Enviroment;
  tenantId: number;
  link: Link;
  token: string;
  userId: string;
  tableOfLinks?: TableOfLinks[];
  onLoginRequire?: () => void;
  themeName?: ThemeName;
}

const CommonDataContext = createContext<CommonDataContextProps | null>(null);

export const CommonDataProvider: FC<CommonDataProviderProps> = memo(
  ({
    env = 'stage',
    tenantId,
    link,
    getUserCallback,
    tableOfLinks,
    children,
    onloginRequireCallback,
    themeName,
  }) => {
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
      env,
      tenantId,
      link,
      tableOfLinks,
      onLoginRequire: onloginRequireCallback,
      themeName: normalizeThemeName(themeName),
    };

    Object.defineProperty(value, 'token', {
      get() {
        return getUserCallback?.().token || '';
      },
    });

    Object.defineProperty(value, 'userId', {
      get() {
        return getUserCallback?.().userId || '';
      },
    });

    return (
      <CommonDataContext.Provider value={value as CommonDataContextProps}>
        {children}
      </CommonDataContext.Provider>
    );
  },
);

export const useCommonDataContext = (): CommonDataContextProps =>
  useContext<CommonDataContextProps>(
    CommonDataContext as Context<CommonDataContextProps>,
  );
