import { IGif } from '@giphy/js-types';
import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from '@giphy/react-components';
import { Icon } from '@greenville/mui-theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import React, { useContext } from 'react';
import ReactQuill from 'react-quill';
import useWindowSize from '../../../hooks/useWindowSize';
import { useCommonDataContext } from '../../../providers/commonDataContext';
import { getImageUrl } from '../../../utils/cloudfront';
import { USER } from '../quill-modules/custom-theme/types';
import styles from './GifFieldDialog.module.scss';

interface GifFieldProps {
  showGif: boolean;
  closeGifField: () => void;
  reactQuillRef: ReactQuill | null | undefined;
  cursorPosition: number;
}

interface ComponentsProps {
  closeGifField: () => void;
  reactQuillRef: ReactQuill | null | undefined;
  cursorPosition: number;
}

const API_KEY = 'I8gpbAeb5JGSUmm6Wevnb5E2DK0J63l8';

const Gifs = ({
  reactQuillRef,
  cursorPosition,
  closeGifField,
}: ComponentsProps) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  const { width } = useWindowSize();
  const { env, themeName } = useCommonDataContext();
  const columns = width && width < 650 ? 2 : 3;

  const saveGif = (gif: IGif) => {
    const quillElement = reactQuillRef?.getEditor();
    if (quillElement) {
      if (cursorPosition !== null) {
        quillElement.insertEmbed(
          cursorPosition,
          'image',
          gif.images.original.url,
          USER,
        );
        quillElement.insertText(cursorPosition + 1, ' ', USER);
        quillElement.setSelection(cursorPosition + 2, 0, USER);
      }
    }
    closeGifField();
  };

  return (
    <>
      <div className={styles.searchContainer}>
        <SearchBar className={styles.gifSearchBar} placeholder="Search" />
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={closeGifField}
        >
          <Icon name="Close" />
        </IconButton>
      </div>
      <Divider
        role="presentation"
        className={styles.divider}
        variant="fullWidth"
      />
      <div className={styles.gridWrapper}>
        <Grid
          key={searchKey}
          columns={columns}
          width={columns === 3 ? 500 : 270}
          fetchGifs={fetchGifs}
          noLink
          onGifClick={saveGif}
          className={styles.gifGrid}
        />
      </div>
      <div className={styles.poweredBy}>
        <img
          src={getImageUrl(
            env,
            themeName === 'dark' ? 'PoweredByDark.png' : 'PoweredByLight.png',
          )}
          alt="powered-by-giphy"
        />
      </div>
    </>
  );
};

const GifFieldDialog = ({
  showGif,
  closeGifField,
  reactQuillRef,
  cursorPosition,
}: GifFieldProps) => (
  <Dialog open={showGif} className={styles.dialog} onClose={closeGifField}>
    <DialogContent className={styles.gifFieldContent}>
      <SearchContextManager apiKey={API_KEY}>
        <Gifs
          reactQuillRef={reactQuillRef}
          cursorPosition={cursorPosition}
          closeGifField={closeGifField}
        />
      </SearchContextManager>
    </DialogContent>
  </Dialog>
);

export default GifFieldDialog;
