import React, {
  FC,
  ReactEventHandler,
  useEffect,
  useRef,
  useState,
  memo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useId } from 'react-id-generator';
import Divider from '@material-ui/core/Divider';
import { NewPostInternal } from '../NewPost';
import {
  favoritePost,
  getPost,
  postVote,
  unFavoritePost,
  reportPost,
  deletePost,
} from '../../services/SocialAPI';
import { IPost, PostUpdateData, PostWithReplies, VoteValue } from '../../types';
import Replies from '../Replies';
import PostCard from '../PostCard';
import NewReply from '../NewReply/NewReply';
import PostDeleteModal from '../PostDeleteModal';
import PostReportModal from '../PostReportModal';

import { PostProps } from './Post.props';
import GenericErrorModal from '../GenericErrorModal';
import { A11Y_ID_PREFIX } from '../../utils/a11y';
import {
  PostActionType,
  sendEventInitiateNewItem,
  sendEventSelectPostAction,
} from '../../utils/events';
import { useCommonDataContext } from '../../providers/commonDataContext';
import { usePostContext } from '../../providers/postProvider';
import { useModal } from '../../providers/modalContext';
import RepliesOrderSelect from '../RepliesOrderSelect/RepliesOrderSelect';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { OrderType } from '../RepliesOrderSelect/RepliesOrderSelect.props';

import styles from './styles';

const useStyles = makeStyles(styles);

const Post: FC<PostProps> = memo(
  ({
    id,
    postInput,
    onUpdateCallback,
    onDeleteCallback,
    linkClickCallback,
  }) => {
    const { areRepliesVisible, toggleRepliesVisibility, setRepliesVisibility } =
      usePostContext();
    const [post, setPost] = useState<PostWithReplies>(
      postInput as PostWithReplies,
    );
    const [wasOpenedReplies, setWasOpenedReplies] = useState(false);
    // const [mentionUser, setMentionUser] = useState<string>();
    // const [userPopupAnchorEl, setUserPopupAnchorEl] = useState<HTMLElement | null>(null);
    const repliesNodeRef = useRef<HTMLDivElement>(null);
    const { openModal } = useModal();
    const [repliesOrder, setRepliesOrder] = useState<OrderType>(
      'old' as OrderType,
    );

    const [inlineCommentsOpened, setInlineCommentsOpened] = useState(false);

    const {
      env,
      tenantId,
      tableOfLinks,
      token,
      userId,
      onLoginRequire,
      themeName,
    } = useCommonDataContext();

    const isOpenedReplies = areRepliesVisible(id);
    const { isCommentsMode } = useLayoutModeContext();

    const classes = useStyles({ isCommentsMode });
    const isLoginRequired = () => !userId && onLoginRequire;

    useEffect(() => {
      if (!postInput) {
        getPost(id, env, token).then(setPost);
      }
    }, [id, postInput, env]);

    useEffect(() => {
      if (!post?.replyCount && !post?.replies?.length) {
        setRepliesVisibility(id, false);
      }
    }, [post?.replyCount, post?.replies?.length]);

    useEffect(() => {
      if (isCommentsMode && (post.replyCount || post?.replies?.length)) {
        setRepliesVisibility(id, true);
      }
    }, []);

    const [repliesRegionId] = useId(1, A11Y_ID_PREFIX);

    const showError = (message: string) => {
      const closeModal = openModal(GenericErrorModal, {
        message,
        onClose: () => {
          closeModal();
        },
      });
    };

    const onThumbUpPost = (item: IPost, voteAction: VoteValue) => {
      if (isLoginRequired()) {
        onLoginRequire?.();
        return;
      }

      if (!(userId && post)) return;
      const voteValue =
        !item.votes ||
        item.votes.length === 0 ||
        item.votes[0].vote !== voteAction
          ? voteAction
          : 0;
      let voteActionEvent: PostActionType = 'Vote Up';
      if (voteValue === 0) {
        if (voteAction === -1) {
          voteActionEvent = 'Unvote Down';
        } else if (voteAction === 1) {
          voteActionEvent = 'Unvote Up';
        }
      } else if (voteValue === -1) {
        voteActionEvent = 'Vote Down';
      }
      sendEventSelectPostAction(voteActionEvent);
      postVote(token, item.id, env, voteValue)
        .then((vote) => {
          const modifiedPost = { ...post };
          Object.assign(modifiedPost, vote);
          setPost(modifiedPost);
          onUpdateCallback?.(modifiedPost);
        })
        .catch(() => showError('Error occured during posting your vote'));
    };

    const onFavorite = () => {
      if (isLoginRequired()) {
        onLoginRequire?.();
        return;
      }

      if (!(userId && post)) return;

      const eventType = post?.favoriteBy?.length ? 'Unsave' : 'Save';
      sendEventSelectPostAction(eventType);

      if (post.favoriteBy && post.favoriteBy[0]) {
        unFavoritePost(token, id, env)
          .then(() => {
            const modifiedPost = { ...post };
            modifiedPost.favoriteBy = [];
            setPost(modifiedPost);
            onUpdateCallback?.(modifiedPost);
          })
          .catch(() =>
            showError('Error occured during removing from favorites'),
          );
      } else {
        favoritePost(token, id, env)
          .then(() => {
            const modifiedPost = { ...post };
            modifiedPost.favoriteBy = [{ id: userId }];
            setPost(modifiedPost);
            onUpdateCallback?.(modifiedPost);
          })
          .catch(() => showError('Error occured during saving to favorites'));
      }
    };

    const openPostReportModal = () => {
      const closeModal = openModal(PostReportModal, {
        userName: post.user?.userName,
        onCancel: () => {
          closeModal();
        },
        onReport: () =>
          reportPost(token, post.id, env)
            .then(() => sendEventSelectPostAction('Report Post'))
            .catch(() => {
              showError('Error occured while reporting this post');
              closeModal();
            }),
      });
    };

    const openPostDeleteModal = () => {
      const closeModal = openModal(PostDeleteModal, {
        onCancel: () => {
          closeModal();
        },
        onDelete: async () => {
          try {
            const postsMarkup = await deletePost(
              token,
              post.id,
              env,
              tenantId!,
            );
            onDeleteCallback?.(post.id, postsMarkup);
            sendEventSelectPostAction('Delete Post');
          } catch (err) {
            showError('Error occured while deleting this post');
          } finally {
            closeModal();
          }
        },
      });
    };

    const onEditPost = () => {
      sendEventSelectPostAction('Edit Post');

      const closeModal = openModal(NewPostInternal, {
        post,
        linkContext: post.content.linkContext,
        link: post.link,
        dialog: true,
        external: false,
        themeName,
        onCancelCallback: () => {
          closeModal();
        },
        onSubmitCallback: (data: IPost) => {
          setPost({ ...post, ...data });
          closeModal();
          const outputData: PostUpdateData = { ...data };
          outputData.needToRefresh = true;
          onUpdateCallback?.(outputData);
        },
      });
    };

    const onReplyCreate = (cReply: IPost) => {
      const modifiedPost = { ...post };
      if (isOpenedReplies || wasOpenedReplies) {
        const replies = post.replies?.slice() || [];
        // TODO: fix this and do it based on replies order
        replies.unshift(cReply);
        modifiedPost.replies = replies;
      }
      modifiedPost.replyCount += 1;
      setPost(modifiedPost);
      onUpdateCallback?.(modifiedPost);
      if (!isOpenedReplies) {
        toggleRepliesVisibility(id);
        setWasOpenedReplies(true);
      }
      if (isCommentsMode && inlineCommentsOpened) {
        setInlineCommentsOpened(false);
      }
      setTimeout(
        () => repliesNodeRef.current?.scrollIntoView({ block: 'start' }),
        500,
      );
    };

    const mentionClickHandler: ReactEventHandler = (e) => {
      let el: HTMLElement | null = e.target as HTMLElement;
      while (el && el.tagName !== 'span' && el.className !== 'mention') {
        el = el.parentNode as HTMLElement | null;
      }
      // if (el && el.tagName?.toLowerCase() === 'span' && el.className?.toLowerCase() === 'mention') {
      //   setMentionUser(el.dataset.id);
      //   setUserPopupAnchorEl(el);
      // }
    };

    const onReply = () => {
      if (isLoginRequired()) {
        onLoginRequire?.();
        return;
      }

      sendEventInitiateNewItem('Reply');

      if (isCommentsMode) {
        setInlineCommentsOpened(!inlineCommentsOpened);
      } else {
        const closeModal = openModal(NewReply, {
          postId: post.id,
          context: post,
          dialog: true,
          onCancelCallback: () => {
            closeModal();
          },
          onSubmitCallback: (cReply: IPost) => {
            onReplyCreate(cReply);
            closeModal();
          },
        });
      }
    };

    const onShowReplies = () => {
      toggleRepliesVisibility(id);
      sendEventSelectPostAction('Show Replies');
      if (!wasOpenedReplies) {
        setWasOpenedReplies(true);
      }
    };

    // Render
    if (!post) {
      return <></>;
    }

    const onRepliesOrderChange = (
      e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ) => {
      setPost((prevState) => {
        const modifiedPost = { ...prevState };
        modifiedPost.replies = [];
        modifiedPost.repliesCursorPage = null;
        return modifiedPost;
      });
      setRepliesOrder(e.target.value as OrderType);
    };

    return (
      <>
        <PostCard
          HTMLIds={{ repliesRegionId }}
          post={post}
          userId={userId}
          tableOfLinks={tableOfLinks}
          linkClickCallback={(link, ranges) => {
            linkClickCallback?.(link, ranges);
            sendEventSelectPostAction('View Post in Content');
          }}
          onDeletePost={openPostDeleteModal}
          onFavorite={onFavorite}
          onReportPost={openPostReportModal}
          onEditPost={onEditPost}
          onThumbUp={onThumbUpPost}
          onReply={onReply}
          onLoginRequire={onLoginRequire}
          isUserAuthorized={!!userId}
          className={isOpenedReplies ? 'repliesOpened' : undefined}
          onShowReplies={onShowReplies}
          questionContentClickHandler={() => mentionClickHandler}
        />

        {isCommentsMode && inlineCommentsOpened && (
          <NewReply
            postId={post.id}
            context={post}
            onSubmitCallback={onReplyCreate}
            onCancelRTE={() => setInlineCommentsOpened(false)}
            dialog={false}
          />
        )}

        <>
          {isOpenedReplies && (
            <section aria-label="Replies" id={repliesRegionId}>
              {!isCommentsMode && (
                <RepliesOrderSelect
                  onChange={onRepliesOrderChange}
                  order={repliesOrder}
                />
              )}

              <Replies
                ref={repliesNodeRef}
                postId={post.id}
                postReplies={{
                  nextPageCursor: post.repliesCursorPage || null,
                  posts: post.replies || [],
                  totalCount: post.repliesTotalCount || 0,
                }}
                updatePost={setPost}
                onUpdateCallback={onUpdateCallback}
                repliesOrder={repliesOrder}
                initialLoadCount={isCommentsMode ? 1 : undefined}
              />
            </section>
          )}

          {isCommentsMode && (
            <Divider role="presentation" className={classes.divider} />
          )}
        </>
        {/* <UserPopup userId={mentionUser} el={userPopupAnchorEl} onClose={() => setUserPopupAnchorEl(null)} isUserMentionPopup /> */}
      </>
    );
  },
);

export default Post;
