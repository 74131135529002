const A11Y_ID_PREFIX = 'a11y-ident-';
const dumb = 'dumb';

export interface TopdownHTMLIds<T> {
  HTMLIds: T;
}

export { A11Y_ID_PREFIX, dumb };

export function a11yClick(
  event: React.MouseEvent | React.KeyboardEvent,
): boolean {
  if (event.type === 'click') {
    return true;
  }
  if (event.type === 'keypress') {
    const code =
      (event as React.KeyboardEvent).charCode ||
      (event as React.KeyboardEvent).keyCode;
    if (code === 32 || code === 13) {
      return true;
    }
  }
  return false;
}

export function someLater(timeout = 100): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}
