import React, { FC } from 'react';
import cn from 'classnames';
import { HighlightItem } from '../../types';
import styles from './QuotationBlock.module.scss';
import { QuotationBlockProps } from './QuotationBlock.props';
import MathMLFormula from '../MathMLFormula';

const mapHighlightItem = (
  highlightItem: HighlightItem | string,
  index: number,
) => {
  if (typeof highlightItem === 'string') {
    return highlightItem;
  }
  switch (highlightItem.type) {
    case 'mathml':
      return <MathMLFormula key={index} mathML={highlightItem.content?.text} />;
    case 'text':
    case 'string':
      return <span key={index}>{highlightItem.content?.text}</span>;
    default:
      return <span key={index} />;
  }
};

const QuotationBlock: FC<QuotationBlockProps> = ({
  highlight,
  linkName,
  onClick,
  clickable,
  className,
  htmlId,
}) => (
  <>
    {/* eslint-disable */}
    <div
      id={htmlId}
      role="group"
      aria-label="text fragment"
      aria-roledescription='quote'
      className={cn(styles.quotationBlock, className, { [styles.clickable]: clickable })}
      onClick={onClick}
    >
      {highlight && (
        <div className={cn(styles.quotation)}>
          <span className={styles.truncated}>
            {Array.isArray(highlight) ? highlight.map(mapHighlightItem) : highlight}
          </span>
        </div>
      )}
      {linkName && (
        <div className={cn(styles.linkName)}>
          <span className={styles.truncatedLinkName}>
            {linkName}
          </span>
        </div>
      )}
    </div>
    {
      /* eslint-enable */
    }
  </>
);

export default QuotationBlock;
