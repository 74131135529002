import React, { FC } from 'react';

import { Icon } from '@greenville/mui-theme';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';

import ActionsMenuPopup, { ActionMenuItem } from '../ActionsMenuPopup';
import { ReplyActionsProps } from './Reply.props';

const ReplyActionsPopup: FC<ReplyActionsProps> = ({
  reply,
  onReport,
  onEdit,
  onDelete,
  isOwner = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: any, ...params: unknown[]) => {
    if (action) {
      action(...params);
    }
    onCloseMenu();
  };

  return (
    <ActionsMenuPopup
      anchorEl={anchorEl}
      onOpen={onOpenMenu}
      onClose={onCloseMenu}
    >
      {isOwner ? (
        [
          <ActionMenuItem
            key="edit"
            onClick={() => handleAction(onEdit, reply)}
          >
            <Icon name="Edit" />
            Edit
          </ActionMenuItem>,
          <ActionMenuItem
            key="delete"
            onClick={() => handleAction(onDelete, reply.id)}
          >
            <Icon name="Delete" />
            Delete
          </ActionMenuItem>,
        ]
      ) : (
        <ActionMenuItem onClick={() => handleAction(onReport, reply)}>
          <ReportProblemOutlined />
          Report
        </ActionMenuItem>
      )}
    </ActionsMenuPopup>
  );
};

export default ReplyActionsPopup;
