import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import cn from 'classnames';
import React, { FC } from 'react';
import styles from './RepliesOrderSelect.module.scss';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import {
  sendEventSortReplies,
  SortRepliesActionType,
} from '../../utils/events';
import { RepliesOrderSelectProps } from './RepliesOrderSelect.props';

const SORTING_NAMES = {
  old: {
    displayValue: 'Old to new',
    eventValue: 'Old',
  },
  recent: {
    displayValue: 'New to old',
    eventValue: 'New',
  },
  rank: {
    displayValue: 'Most popular',
    eventValue: 'Rank',
  },
};

const RepliesOrderSelect: FC<RepliesOrderSelectProps> = ({
  order,
  onChange,
}) => (
  <FormControl
    variant="standard"
    classes={{ root: styles.filter }}
    data-testid="replies-order-select"
  >
    <Select
      classes={{
        root: styles.select,
      }}
      variant="outlined"
      value={order}
      onChange={onChange}
      IconComponent={({ className }) => (
        <FilterIcon className={cn(className, styles.filterIcon)} />
      )}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        className: styles.menu,
        getContentAnchorEl: null,
      }}
      renderValue={(value: unknown) =>
        `Sort by: ${
          SORTING_NAMES[value as keyof typeof SORTING_NAMES].displayValue
        }`
      }
    >
      {(Object.keys(SORTING_NAMES) as Array<keyof typeof SORTING_NAMES>).map(
        (replySortValue) => (
          <MenuItem
            key={replySortValue}
            classes={{ root: styles.menuItem }}
            value={replySortValue}
            onClick={() =>
              sendEventSortReplies(
                SORTING_NAMES[replySortValue]
                  .eventValue as SortRepliesActionType,
              )
            }
          >
            {SORTING_NAMES[replySortValue].displayValue}
          </MenuItem>
        ),
      )}
    </Select>
  </FormControl>
);

export default RepliesOrderSelect;
