import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles';
import pearsonTheme from '@greenville/mui-theme';
import { ThemeName } from '../types';

const defaultTheme = {
  buttonText: '#F7F9FD',
  buttonBackground:
    'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
  quotationBlockBackground: '#FEFEFE',
  quotationBlockText: '#333333',
  attachmentText: 'rgb(51, 51, 51)',
  cardShadow: '0px 12px 17px 2px rgba(0, 0, 0, 0.1)',
  primaryText: '#05112A',
  secondaryText: '#333333',
  primaryBackground: '#F6F9FD',
  secondaryBackground: '#FEFEFE',
  primarySeparatorColor: '#E8EEFA',
  primaryIconColor: '#003057',
  secondaryIconColor: '#6A707C',
  primaryHintColor: '#4F5561',
  primaryActiveColor: 'rgba(0,0,0,0.08)',
  primaryDisabledElementColor: 'rgb(204,204,204)',
};

const darkTheme = {
  buttonText: '#F7F9FD',
  buttonBackground:
    'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
  quotationBlockBackground: '#F6F9FD',
  quotationBlockText: '#333333',
  attachmentText: 'rgb(51, 51, 51)',
  cardShadow: '0px 12px 17px 2px rgba(255, 255, 255, 0.1)',
  primaryText: 'rgb(254, 254, 254)',
  secondaryText: 'rgb(254, 254, 254)',
  primaryBackground: 'rgb(16, 16, 16)',
  secondaryBackground: 'rgb(28, 28, 30)',
  primarySeparatorColor: 'rgb(51, 51, 51)',
  primaryIconColor: 'white',
  secondaryIconColor: 'white',
  primaryHintColor: 'rgb(254, 254, 254)',
  primaryActiveColor: 'rgba(100, 100, 100, 0.3)',
  primaryDisabledElementColor: 'rgb(204,204,204)',
};

const sepiaTheme = {
  buttonText: '#F7F9FD',
  buttonBackground:
    'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
  quotationBlockBackground: '#F6F9FD',
  quotationBlockText: '#2a1b05',
  attachmentText: 'rgb(51, 51, 51)',
  cardShadow: '0px 12px 17px 2px rgba(0, 0, 0, 0.1)',
  primaryText: '#2a1b05',
  secondaryText: '#2a1b05',
  primaryBackground: '#f7f4e7',
  secondaryBackground: 'rgb(252, 250, 237)',
  primarySeparatorColor: 'rgba(155, 161, 173, 0.5)',
  primaryIconColor: '#2a1b05',
  secondaryIconColor: '#2a1b05',
  primaryHintColor: '#4F5561',
  primaryActiveColor: 'rgba(0,0,0,0.08)',
  primaryDisabledElementColor: 'rgb(204,204,204)',
};

const darkThemeStudy = {
  buttonText: '#F7F9FD',
  buttonBackground:
    'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
  quotationBlockBackground: '#F6F9FD',
  quotationBlockText: '#333333',
  attachmentText: 'rgb(51, 51, 51)',
  cardShadow: '0px 0px 0px 0px rgba(255, 255, 255, 0.1)',
  primaryText: '#F7F9FD',
  secondaryText: '#333333',
  primaryBackground: '#05112A',
  secondaryBackground: '#071324',
  primarySeparatorColor: '#4F5561',
  primaryIconColor: '#F7F9FD',
  secondaryIconColor: '#F7F9FD',
  primaryHintColor: '#F7F9FD',
  primaryActiveColor: 'rgba(100, 100, 100, 0.3)',
  primaryDisabledElementColor: 'rgb(204,204,204)',
};

const getThemePalette = (mode: ThemeName, tenantId: number) => {
  const themeName = `${tenantId}-${mode}`;
  switch (themeName) {
    case '1-dark':
      return darkTheme;
    case '2-dark':
      return darkThemeStudy;
    case '1-sepia':
      return sepiaTheme;
    case '1-default':
    default:
      return defaultTheme;
  }
};

const getTheme = (mode: ThemeName = 'default', tenantId: number) =>
  createTheme(
    merge({}, pearsonTheme, {
      typography: {
        fontFamily: 'Hind',
      },
      overrides: {
        MuiPopover: null,
        MuiMenuItem: {
          root: {
            display: 'block',
            textOverflow: 'ellipsis',
          },
        },
        MuiButton: {
          label: {
            minWidth: 'auto',
          },
        },
      },
      palette: {
        mode,
        ...getThemePalette(mode, tenantId),
      },
    }),
  );

export default getTheme;
