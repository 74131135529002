import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { PostReportModalProps } from './PostReportModal.props';
import styles from './PostReportModal.module.scss';
import DialogModal from '../DialogModal';

const PostReportModal: FC<PostReportModalProps> = ({
  open = true,
  userName,
  onCancel,
  onReport,
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState('');

  const title = isConfirmed ? 'Under review' : 'Report a post';

  return (
    <DialogModal
      className={styles.reportPostModal}
      open={open}
      title={error ? 'Whoops...' : title}
      isFullScreen={false}
      onCancelCallback={onCancel}
      actions={
        !isConfirmed && (
          <Button
            onClick={() => {
              onReport()
                .then(() => setIsConfirmed(true))
                .catch((e: string) => setError(e));
            }}
          >
            Report
          </Button>
        )
      }
    >
      <div className={styles.content}>
        {error && <Typography className={styles.text}>{error}</Typography>}
        {!error && isConfirmed && (
          <>
            <Typography className={styles.text}>
              {`We’re reviewing your report of ${userName}’s post.`}
            </Typography>

            <Typography className={styles.text}>
              Thank you for helping us maintain Pearson’s Community Guidelines.
            </Typography>
          </>
        )}
        {!error && !isConfirmed && (
          <>
            <Typography className={styles.text}>
              Thanks for bringing problematic content to our attention.
            </Typography>

            <Typography className={styles.text}>
              We’ll review the post against our{' '}
              <a
                className={styles.communityGuidelinesLink}
                href="https://www.pearson.com/en-us/pearsonplus/community-guidelines.html"
                target="_black"
              >
                Community Guidelines
              </a>
              .
            </Typography>
          </>
        )}
      </div>
    </DialogModal>
  );
};

export default PostReportModal;
