import '../scss/posts.scss';
import { getPostsMarkups } from '../services/SocialAPI';
import { convertCommonFormatToMarkup } from '../utils/contentHelpers';

export { default as NewPost } from '../components/NewPost';
export { default as Posts } from '../components/Posts';
export const SocialAPI = {
  GetPostsMarkups: getPostsMarkups,
};

export const SocialUtils = {
  convertCommonFormatToMarkup,
};
