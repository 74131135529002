import React from 'react';
import { convertMathMLToFormula } from '../../utils/contentHelpers';

const MathMLFormula = ({ mathML }: { mathML: string }) => {
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: convertMathMLToFormula(mathML) }}
    />
  );
};

export default MathMLFormula;
