import React, { FC, useState, useEffect, useRef } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import useWindowSize from '../../hooks/useWindowSize';
import useTheme from '../../hooks/useTheme';
import getTheme from '../../utils/theme';
import { addPost, updatePost } from '../../services/SocialAPI';

import { setLinkOrder } from '../../utils/linkHelpers';
import '../../scss/postDialog.scss';
import NewPostModal from '../NewPostModal';
import NeedsReviewModal from '../NeedsReviewModal';

import { FormPostData, IPost, PostWithMarkup } from '../../types';
import { NewPostInternalProps, NewPostProps } from './NewPost.props';
import {
  sendEventCreateItem,
  setAdditionalTrackingEngines,
  setEventGlobalData,
} from '../../utils/events';
import eventSystem from '../../utils/eventSystem';
import GenericErrorModal from '../GenericErrorModal';
import { doesPostNeedReview } from '../../utils/postUtils';
import {
  CommonDataProvider,
  useCommonDataContext,
} from '../../providers/commonDataContext';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { A11yLiveProvider } from '../../providers/a11yLiveContext';

export const NewPostInternal: FC<NewPostInternalProps> = ({
  disableContentFilter,
  themeName,
  onSubmitCallback,
  onCancelCallback,
  onCancelRTE,
  link,
  linkContext,
  post,
  dialog = true,
  external = true,
  trackingGlobalData,
  additionalTrackingEngines,
}) => {
  const { tenantId, env, tableOfLinks, token, onLoginRequire, userId } =
    useCommonDataContext();
  const size = useWindowSize();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const createdPost = useRef<IPost | PostWithMarkup | undefined>(undefined);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const { isCommentsMode } = useLayoutModeContext();

  const postId = post?.id;

  useEffect(() => {
    setAdditionalTrackingEngines(additionalTrackingEngines);
  }, [additionalTrackingEngines]);

  useEffect(() => {
    if (!postId) {
      if (trackingGlobalData) {
        setEventGlobalData(trackingGlobalData);
      } else if (Array.isArray(window.dataLayer)) {
        const bookId = window.dataLayer.find(
          (event) =>
            typeof event.book_id !== 'undefined' && event.book_id != null,
        )?.book_id;
        const businessModelCode = window.dataLayer.find(
          (event) =>
            typeof event.business_model_code !== 'undefined' &&
            event.business_model_code != null,
        )?.business_model_code;
        const pearsonId = window.dataLayer.find(
          (event) =>
            typeof event.person_id !== 'undefined' && event.person_id != null,
        )?.person_id;
        const productId = window.dataLayer.find(
          (event) =>
            typeof event.product_id !== 'undefined' && event.product_id != null,
        )?.product_id;
        const globalData = {
          book_id: bookId || null,
          business_model_code: businessModelCode || null,
          person_id: pearsonId || null,
          product_id: productId || null,
        };
        setEventGlobalData(globalData);
      }
    }
  }, [postId, trackingGlobalData]);

  useEffect(() => {
    setIsFullScreen(size.isWidthLess1050);
  }, [size.isWidthLess1050]);

  useTheme(tenantId, themeName, !postId);

  const createOrUpdatePost = async (
    newPost: FormPostData,
  ): Promise<IPost | PostWithMarkup> => {
    const content = {
      ...newPost.content,
      linkContext,
    };

    if (content.linkContext?.highlight) {
      if (typeof content.linkContext?.highlight === 'string') {
        const QUOTATION_LIMIT = 480;
        content.linkContext.highlight = content.linkContext.highlight.slice(
          0,
          QUOTATION_LIMIT,
        );
      } else if (Array.isArray(content.linkContext?.highlight)) {
        content.linkContext.highlight = content.linkContext.highlight
          .slice(0, 3)
          .map((highlightItem) =>
            highlightItem.type === 'mathml'
              ? {
                  type: highlightItem.type,
                  content: { text: highlightItem.content.text },
                }
              : highlightItem,
          );
      }
    }

    if (postId) {
      return updatePost({
        token,
        id: postId,
        title: newPost.title,
        content,
        anonymous: newPost.anonymous,
        tenantId,
        env,
      });
    }
    const finalLink = setLinkOrder(newPost.link || link!, tableOfLinks || []);
    const addedPost = await addPost({
      token,
      ...(newPost.title ? { title: newPost.title } : {}),
      content,
      anonymous: newPost.anonymous,
      tenantId,
      link: finalLink,
      env,
    });
    sendEventCreateItem('New', external, content);
    return addedPost;
  };

  const handleConfirmReviewModal = () => {
    if (createdPost.current) {
      const cPost = { ...createdPost.current };
      createdPost.current = undefined;
      onSubmitCallback?.(cPost);
      if (!postId && external) {
        eventSystem.publish<PostWithMarkup>('newpost', cPost as PostWithMarkup);
      }
      setShowReviewModal(false);
    }

    setShowReviewModal(false);
  };

  const handleCreateOrUpdatePost = async (newPost: FormPostData) => {
    if (!userId && onLoginRequire) {
      onLoginRequire();

      return;
    }

    setIsRequestInProgress(true);

    createdPost.current = await createOrUpdatePost(newPost);

    setIsRequestInProgress(false);
    if (!createdPost.current) {
      return;
    }

    if (
      createdPost.current.reviewStatus === 'needs_review' &&
      doesPostNeedReview(newPost) &&
      !isCommentsMode
    ) {
      setShowReviewModal(true);
    } else {
      onSubmitCallback?.(createdPost.current);
      if (!postId && external) {
        eventSystem.publish<PostWithMarkup>(
          'newpost',
          createdPost.current as PostWithMarkup,
        );
      }
    }
  };

  return (
    <>
      <NewPostModal
        open={!!dialog && !showReviewModal}
        inlineMode={!dialog}
        post={post}
        link={link}
        disableContentFilter={disableContentFilter}
        highlight={linkContext?.highlight}
        isFullScreen={isFullScreen}
        onCancelCallback={() =>
          isEmpty ? setDiscard(true) : onCancelCallback?.()
        }
        onCancelRTE={onCancelRTE}
        onUpdatePost={handleCreateOrUpdatePost}
        onCreatePost={handleCreateOrUpdatePost}
        setIsEmpty={setIsEmpty}
        isRequestInProgress={isRequestInProgress}
      />

      {showReviewModal && !isCommentsMode && (
        <NeedsReviewModal
          onConfirm={handleConfirmReviewModal}
          onCancel={handleConfirmReviewModal}
        />
      )}

      <GenericErrorModal
        open={discard}
        title="Discard"
        message="Are you sure you want to discard this post?"
        onConfirm={onCancelCallback}
        onClose={() => setDiscard(false)}
      />
    </>
  );
};

const NewPost: FC<NewPostProps> = ({
  getUserCallback,
  env = 'stage',
  disableContentFilter,
  themeName,
  onSubmitCallback,
  onCancelCallback,
  tenantId,
  link,
  linkContext,
  tableOfLinks,
  post,
  dialog = true,
  external = true,
  trackingGlobalData,
  additionalTrackingEngines,
}) => (
  <A11yLiveProvider>
    <ThemeProvider theme={getTheme(themeName, tenantId)}>
      <CommonDataProvider
        env={env}
        tenantId={tenantId}
        link={link!}
        getUserCallback={getUserCallback}
        tableOfLinks={tableOfLinks}
        themeName={themeName}
      >
        <NewPostInternal
          themeName={themeName}
          disableContentFilter={disableContentFilter}
          onSubmitCallback={onSubmitCallback}
          onCancelCallback={onCancelCallback}
          link={link}
          linkContext={linkContext}
          post={post}
          dialog={dialog}
          external={external}
          trackingGlobalData={trackingGlobalData}
          additionalTrackingEngines={additionalTrackingEngines}
        />
      </CommonDataProvider>
    </ThemeProvider>
  </A11yLiveProvider>
);

export default NewPost;
