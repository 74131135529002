/* eslint-disable import/extensions */
/* eslint-disable import/no-webpack-loader-syntax */
import { Quill } from 'react-quill';

import boldIcon from '!!raw-loader!../../../assets/images/editor/bold.svg';
import italicIcon from '!!raw-loader!../../../assets/images/editor/italic.svg';
import underlineIcon from '!!raw-loader!../../../assets/images/editor/underline.svg';
// import blockquoteIcon from '!!raw-loader!../assets/images/editor/blockquote.svg';
import emojiIcon from '!!raw-loader!../../../assets/images/editor/emoji.svg';
import linkIcon from '!!raw-loader!../../../assets/images/editor/link.svg';
import imageIcon from '!!raw-loader!../../../assets/images/editor/image.svg';
import videoIcon from '!!raw-loader!../../../assets/images/editor/video.svg';

const Icons = Quill.import('ui/icons');

Icons.bold = boldIcon;
Icons.italic = italicIcon;
Icons.underline = underlineIcon;
// Icons.blockquote = blockquoteIcon;
Icons.emoji = emojiIcon;
Icons.link = linkIcon;
Icons.image = imageIcon;
Icons.video = videoIcon;
