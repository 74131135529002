import { StyleProps } from '../../types';

export default (theme: any) => ({
  root: {
    marginBottom: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 12 : 20,
    backgroundColor: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 'transparent' : theme.palette.secondaryBackground,
    color: theme.palette.primaryText,
    fontFamily: 'Hind',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primarySeparatorColor}`,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      boxShadow: theme.palette.cardShadow,
    },
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primaryText,
    gap: 8,
  },

  divider: {
    margin: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? '20px 8px 0' : '0 8px 8px',
    backgroundColor: theme.palette.primarySeparatorColor,
  },

  alternate: {
    backgroundColor: 'white',
  },

  postCardLayout2: {
    border: 0,
  },

  commentsMode: {
    border: 0,
    borderBottom: `0px solid ${theme.palette.primarySeparatorColor}`,
    borderRadius: 0,
  },

  repliesOpened: {
    marginBottom: 5,
  },

  postActions: {
    alignItems: 'baseline',
  },

  iconButton: {
    '&:hover': {
      backgroundColor: ({ isCommentsMode }: StyleProps) =>
        isCommentsMode && 'inherit',
    },
  },

  iconUp: {
    fill: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode
        ? theme.palette.primarySeparatorColor
        : theme.palette.secondaryIconColor,
    '&:hover': {
      fill: ({ isCommentsMode }: StyleProps) =>
        isCommentsMode ? '#8ef8ee' : theme.palette.secondaryIconColor,
    },
  },

  iconDown: {
    fill: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode
        ? theme.palette.primarySeparatorColor
        : theme.palette.secondaryIconColor,
    '&:hover': {
      fill: ({ isCommentsMode }: StyleProps) =>
        isCommentsMode ? '#3182ff' : theme.palette.secondaryIconColor,
    },
  },

  icon: {
    fill: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode
        ? theme.palette.primarySeparatorColor
        : theme.palette.secondaryIconColor,
  },
});
