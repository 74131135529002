import axios from 'axios';
import {
  Link,
  Vote,
  IPost,
  PostWithReplies,
  PostWithMarkup,
  PostContent,
  PostMarkup,
  VoteValue,
  User,
  Visibility,
  Enviroment,
} from '../types';
import { getPathFromLink } from '../utils/linkHelpers';

export interface AllPosts {
  nextPageCursor: number | null;
  posts: IPost[];
  totalCount: number;
}

interface VoteUpdateResponse {
  score: number;
  votes: Vote[];
}

export interface Upload {
  file: string;
}

const getBaseUrl = (env: Enviroment) => {
  switch (env) {
    case 'dev':
      return 'https://api.social-dev.pearsondev.tech/social';
    case 'qa':
      return 'https://api.social-qa.pearsondev.tech/social';
    // case 'ppe':
    // case 'perf':
    //   return 'https://api.social-ppe.pearsondev.tech/social';
    case 'prod':
      return 'https://social-api.pearson.com/social';
    case 'stage':
    // falls through
    case 'stg':
    // falls through
    default:
      return 'https://api.social-stg.pearsondev.tech/social';
  }
};

const postService = axios.create({
  timeout: 10000,
});

export function getPost(
  id: number,
  env: Enviroment = 'stage',
  token = '',
  replies = false,
) {
  const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
  return postService
    .get<
      IPost | PostWithReplies
    >(`${getBaseUrl(env)}/v2/posts/${id}?replies=${replies.toString()}`, { headers })
    .then((res) => res.data);
}

interface GetPostsOptions {
  ids: number[];
  env: Enviroment;
  token?: string;
  tenantId: number;
  adminToken?: string;
}

export function getPosts({
  ids,
  env = 'stage',
  token,
  tenantId,
  adminToken,
}: GetPostsOptions) {
  const headers: Record<string, string> = {
    tenant: String(tenantId),
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return postService
    .get<AllPosts>(`${getBaseUrl(env)}/v2/posts`, {
      headers,
      params: { ids, adminToken },
    })
    .then((res) => res.data);
}

interface GetPostsBatchOptions {
  tenantId: number;
  env: Enviroment;
  token?: string;
  adminToken?: string;
  filters: {
    link: Link;
    exactLink?: boolean;
    filter: string;
    sort: string;
    cursor?: number | null;
    pageSize?: number;
  }[];
}

export function getPostsBatch({
  env = 'stage',
  token,
  tenantId,
  filters,
}: GetPostsBatchOptions) {
  const headers: Record<string, string> = {
    tenant: String(tenantId),
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const payload = filters.map(({ link, exactLink, ...filter }) => ({
    linkPath: getPathFromLink(link),
    exactLinkPath: exactLink,
    parentId: 'null',
    ...filter,
  }));

  return postService
    .post<AllPosts[]>(`${getBaseUrl(env)}/v2/posts/batch`, payload, { headers })
    .then((res) => res.data);
}

interface GetPostsByLinkOptions {
  token?: string;
  tenantId: number;
  link: Link;
  exactLink?: boolean;
  filter: string;
  sort: string;
  cursor?: number | null;
  pageSize?: number;
  env: Enviroment;
}

export function getPostsByLink({
  tenantId,
  link,
  exactLink,
  filter,
  sort,
  cursor = null,
  pageSize,
  env = 'stage',
  token,
}: GetPostsByLinkOptions) {
  const headers: Record<string, string> = {
    tenant: String(tenantId),
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return postService
    .get<AllPosts>(`${getBaseUrl(env)}/v2/posts`, {
      headers,
      params: {
        linkPath: getPathFromLink(link),
        exactLinkPath: exactLink,
        parentId: 'null',
        filter: filter || undefined,
        sort: sort || undefined,
        cursor: cursor || undefined,
        pageSize,
      },
    })
    .then((res) => res.data);
}

interface GetPostRepliesOptions {
  tenantId: number;
  postId: number;
  env?: Enviroment;
  sort: string;
  pageSize?: number;
  cursor?: number | null;
  token?: string;
}

export function getPostReplies({
  tenantId,
  postId,
  env = 'stage',
  sort,
  pageSize,
  cursor = null,
  token = '',
}: GetPostRepliesOptions) {
  const headers: Record<string, string> = {
    tenant: String(tenantId),
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return postService
    .get<AllPosts>(`${getBaseUrl(env)}/v2/posts`, {
      headers,
      params: {
        rootId: postId,
        sort,
        pageSize,
        cursor,
      },
    })
    .then((res) => res.data);
}

interface AddPostOptions {
  token: string;
  title?: string;
  content: PostContent;
  anonymous?: boolean;
  visibility?: Visibility;
  parentId?: number;
  tenantId: number;
  link?: Link;
  env: Enviroment;
}

export function addPost({
  token,
  env = 'stage',
  tenantId,
  ...post
}: AddPostOptions) {
  return postService
    .post<PostWithMarkup>(
      `${getBaseUrl(env)}/v2/posts?outputPostsMarkup=true`,
      post,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          tenant: tenantId.toString(),
        },
      },
    )
    .then((res) => res.data);
}

interface UpdatePostOptions {
  token: string;
  env: Enviroment;
  tenantId: number;
  id: number;
  title?: string;
  content: PostContent;
  anonymous?: boolean;
}

export function updatePost({
  token,
  id,
  title,
  content,
  anonymous,
  env = 'stage',
  tenantId,
}: UpdatePostOptions) {
  return postService
    .patch<IPost>(
      `${getBaseUrl(env)}/v2/posts/${id}`,
      {
        title,
        content,
        anonymous,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          tenant: tenantId.toString(),
        },
      },
    )
    .then((res) => res.data);
}

export function deletePost(
  token: string,
  postId: number,
  env: Enviroment = 'stage',
  tenantId: number,
) {
  return postService
    .delete<PostMarkup>(
      `${getBaseUrl(env)}/v2/posts/${postId}?outputPostsMarkup=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          tenant: tenantId.toString(),
        },
      },
    )
    .then((res) => res.data);
}

export function postVote(
  token: string,
  postId: number,
  env: Enviroment = 'stage',
  value: VoteValue = 1,
) {
  return postService
    .put<VoteUpdateResponse>(
      `${getBaseUrl(env)}/v1/posts/${postId}/votes/${value}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => res.data);
}

export function favoritePost(
  token: string,
  postId: number,
  env: Enviroment = 'stage',
) {
  return postService
    .get<void>(`${getBaseUrl(env)}/v1/posts/${postId}/favorite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

export function unFavoritePost(
  token: string,
  postId: number,
  env: Enviroment = 'stage',
) {
  return postService
    .delete<void>(`${getBaseUrl(env)}/v1/posts/${postId}/favorite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}

export function getPostsMarkups(
  tenantId: number,
  link: Link,
  env: Enviroment = 'stage',
  token = '',
) {
  const headers: Record<string, string> = {
    tenant: tenantId.toString(),
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const url = `${getBaseUrl(env)}/v1/posts/links/markups/${getPathFromLink(
    link,
  )}`;
  return postService
    .get<PostMarkup[]>(url, { headers })
    .then((res) => res.data);
}

export function getUserData(userId: string, env: Enviroment = 'stage') {
  return postService
    .get<User>(`${getBaseUrl(env)}/v1/users/${userId}`)
    .then((res) => res.data);
}

export function reportPost(
  token: string,
  postId: number,
  env: Enviroment = 'stage',
) {
  return postService.post(
    `${getBaseUrl(env)}/v1/posts/${postId}/report`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function uploadImage(
  token: string,
  file: File,
  env: Enviroment = 'stage',
  name = '',
  onUploadProgress?: (percent: number) => void,
) {
  return postService
    .post<Upload>(`${getBaseUrl(env)}/v1/uploads/${name || file.name}`, file, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': file.type || 'application/octet-stream',
      },
      timeout: 0,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 100),
        );
        onUploadProgress?.(percentCompleted);
      },
    })
    .then((res) => res.data);
}
