import React, {
  useState,
  CSSProperties,
  memo,
  forwardRef,
  ReactElement,
} from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import useResizeObserver from '@react-hook/resize-observer';

interface VirtualBlockProps {
  className?: string;
  initialHeight: number;
  children: ReactElement;
}

const VirtualBlock = forwardRef<HTMLDivElement, VirtualBlockProps>(
  ({ className, initialHeight, children }, ref) => {
    if (typeof ref === 'function') {
      throw TypeError('ref must not be a function (aka legacy ref)');
    }

    const [height, setHeight] = useState(initialHeight);
    const { isIntersecting } = useIntersectionObserver(ref);

    useResizeObserver(ref, (entry) => {
      const newHeight = entry.contentRect.height;

      if (newHeight !== height) {
        setHeight(newHeight);
      }
    });

    const style: CSSProperties = isIntersecting ? {} : { height };

    return (
      <div ref={ref} className={className} style={style}>
        {isIntersecting ? children : null}
      </div>
    );
  },
);

export default memo(VirtualBlock);
