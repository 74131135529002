import React, { FC } from 'react';
import MuiCardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactTimeAgo from 'react-time-ago';

import Avatar from '../Avatar';
import TutorBadge from '../TutorBadge';

import { CardHeaderProps } from './CardHeader.props';
import { useLayoutModeContext } from '../../providers/layoutModeContext';

import styles from './styles';
import { useCommonDataContext } from '../../providers/commonDataContext';

const useStyles = makeStyles(styles);

const CardHeader: FC<CardHeaderProps> = ({
  user,
  actions,
  createdAt,
  edited,
  underReview,
  userNameHtmlId,
  isReply = false,
  classes: customClasses,
}) => {
  const { isCommentsMode } = useLayoutModeContext();
  const classes = useStyles({ isCommentsMode });
  const { tenantId } = useCommonDataContext();

  const headingAttrs = {
    ...(isCommentsMode
      ? {
          role: 'heading',
          'aria-level': isReply ? 4 : 3,
        }
      : {}),
  };

  return (
    <header aria-label="Post header.">
      <MuiCardHeader
        classes={{
          root: classes.root,
          action: classes.actionsBlock,
          avatar: classes.avatar,
          title: classes.title,
          ...customClasses,
        }}
        avatar={
          <Avatar aria-hidden="true" user={user} className={classes.avatar} />
        }
        action={actions}
        title={
          <span className={classes.titleWrapper}>
            <span className={isCommentsMode ? classes.channelsUserInfo : ''}>
              <div
                {...headingAttrs}
                id={userNameHtmlId}
                className={classes.userName}
              >
                {user?.userName || 'Anonymous'}
                {user?.type === 'tutor' && tenantId === 2 && <TutorBadge />}
              </div>
              {!isCommentsMode && ' | '}
              <Typography
                className={classes.createdAt}
                variant="caption"
                component="span"
              >
                <ReactTimeAgo date={Date.parse(createdAt)} locale="en-US" />
              </Typography>
            </span>
            {underReview && (
              <Typography
                className={classes.tag}
                variant="caption"
                component="span"
              >
                Under review
              </Typography>
            )}
            {edited && !underReview && tenantId !== 2 && (
              <Typography
                className={classes.tag}
                variant="caption"
                component="span"
              >
                Edited
              </Typography>
            )}
          </span>
        }
      />
    </header>
  );
};

export default CardHeader;
