import ImageUploader from 'quill-image-uploader';

const allowedTypes = ['image/jpeg', 'image/png'];

export default class CustomImageUploader extends ImageUploader {
  selectLocalImage() {
    this.range = this.quill.getSelection();
    this.fileHolder = document.createElement('input');
    this.fileHolder.setAttribute('type', 'file');
    this.fileHolder.setAttribute('accept', allowedTypes.join(',')); // this line is changed. all the rest are copied from original class
    this.fileHolder.setAttribute('style', 'visibility:hidden');

    this.fileHolder.onchange = this.fileChanged.bind(this);

    document.body.appendChild(this.fileHolder);

    this.fileHolder.click();

    window.requestAnimationFrame(() => {
      document.body.removeChild(this.fileHolder);
    });
  }
}
