import { StyleProps } from '../../types';

export default (theme: any) => ({
  cardActions: {
    padding: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? '0 12px 8px 12px' : '0 12px 8px 20px',
  },

  root: ({ isCommentsMode }: StyleProps) =>
    isCommentsMode
      ? {
          padding: '0 12px 8px 0',
          flexDirection: 'row-reverse' as const,
          justifyContent: 'flex-end',
          marginLeft: '-10px',
          height: 21,
        }
      : {
          padding: 0,
          height: 31,
        },

  buttonStyle: ({ isCommentsMode, postCardActions }: StyleProps) => {
    const result = {
      minWidth: 'auto',
      marginLeft: isCommentsMode ? 10 : 0,
    };

    return postCardActions && !isCommentsMode
      ? {
          ...result,
          backgroundColor: '#002E60',
          width: '55px',
          height: '27px',
          borderRadius: '40px',
        }
      : result;
  },

  active: {
    color: theme.palette.primaryText,
  },

  reply: ({ isCommentsMode, postCardActions }: StyleProps) => {
    let result: any = {
      cursor: 'pointer',
      color: theme.palette.secondaryIconColor,
      fontSize: '0.9em',
    };

    if (postCardActions && !isCommentsMode) {
      result = {
        ...result,
        color: theme.palette.buttonText,
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 700,
      };
    }

    return isCommentsMode
      ? {
          ...result,
          textTransform: 'uppercase' as const,
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '21px',
        }
      : result;
  },

  actions: {
    flexGrow: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 'inherit' : 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
  },
});

export const cardActionStyles = (theme: any) => ({
  actionIcon: {
    display: 'flex',
    paddingLeft: 4,
  },

  countBlock: {
    paddingTop: 3,
    textAlign: 'center' as const,
  },

  count: {
    marginRight: 3,
    color: theme.palette.secondaryIconColor,
    fontSize: 15,
  },
});
