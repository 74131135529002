import React, { FC } from 'react';
import cn from 'classnames';
import AttachmentButton from './AttachmentButton';

import { AttachmentsProps } from './Attachments.props';
import styles from './Attachments.module.scss';

const Attachments: FC<AttachmentsProps> = ({
  className,
  attachments,
  onDeleteClick,
}) => (
  <div className={cn(styles.attachments, className)}>
    {attachments.map((attachment) => (
      <AttachmentButton
        key={attachment.url}
        name={attachment.name}
        url={attachment.url}
        {...{
          onDeleteClick: onDeleteClick
            ? () => onDeleteClick(attachment)
            : undefined,
        }}
      />
    ))}
  </div>
);

export default Attachments;
