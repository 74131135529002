/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2021 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import Divider from '@material-ui/core/Divider';
import { ResizeObserver } from '@juggle/resize-observer';
import debounce from 'lodash/debounce';
import { NewPost, Posts, SocialAPI } from './bundle';
import Config from '../demo/config';
import {
  Link,
  IPost,
  PostMarkup,
  PostsOnunmount,
  PostWithMarkup,
  ThemeName,
  Enviroment,
  ChangeInputCallback,
} from './types';

import './scss/app.scss';

declare global {
  interface Window {
    SelectionMenu: any;
    dataLayer?: Array<any>;
  }
}

const tenantId = 1;
let userId = 'fakeuser';
let theme: ThemeName = 'default';
let mode = 'demo';

window.dataLayer = [];

const env = (process.env.REACT_APP_BUILD_ENV || 'stage') as Enviroment;

const queryParams = new URLSearchParams(window.location.search);
const bookIdQuery = queryParams.get('bookId');
if (bookIdQuery) {
  Config.bookId = bookIdQuery;
  Config.tableOfLinks[0].id = bookIdQuery;
}
const userIdQuery = queryParams.get('userId');
if (userIdQuery) {
  userId = userIdQuery;
}
const modeQuery = queryParams.get('mode');
if (modeQuery) {
  mode = modeQuery;
}

const getUserCallback = () => ({
  userId,
  token: `fake_token_${userId}`,
});

let contextWindow = (document.getElementById('iframe') as HTMLIFrameElement)
  .contentWindow;
let contextDocument = contextWindow!.document;

const socialRootElement = document.createElement('div');
socialRootElement.id = 'social';
let socialRoot: Root | undefined;
const newPostRootElement = document.createElement('div');
newPostRootElement.id = 'newPost';
let newPostRoot: Root | undefined;

const unmountSocial = () => {
  if (socialRoot) {
    socialRoot.unmount();
  }
  socialRoot = createRoot(socialRootElement);
};
const unmountNewPost = () => {
  if (newPostRoot) {
    newPostRoot.unmount();
  }
  newPostRoot = createRoot(newPostRootElement);
};
const CloseSideBar = () => {
  return (
    <span
      tabIndex={0}
      role="button"
      aria-label="closeSocial"
      className="closeSideBar"
      onClick={unmountSocial}
      onKeyDown={unmountSocial}
    />
  );
};

const findLastLinkId = (link: Link): string => {
  if (link.subLink) {
    return findLastLinkId(link.subLink);
  }
  return link.id;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const linkClickCallback = (linkObj: Link, ranges?: object[]) => {
  // eslint-disable-next-line no-console
  console.log('got link on click', linkObj);
  const paragraphId = findLastLinkId(linkObj);
  const markupEl = contextDocument.querySelector(
    `[data-chaucer-element-id="${paragraphId}"]`,
  );
  if (markupEl) {
    markupEl.scrollIntoView();
  } else {
    contextDocument.body.scrollTop = 0;
    contextDocument.documentElement.scrollTop = 0;
  }
};

const renderPostsList = (
  link: Link,
  usedLink: Link,
  disableContentFilter?: boolean,
  hideAddNewButton?: boolean,
) => {
  unmountSocial();
  const onUnmountCallback = (data: PostsOnunmount) => {
    if (data.refreshMarkups) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      loadMarkups(link);
    }
  };
  const changeInputCallback = (changeInput: ChangeInputCallback) => {
    if (changeInput.link) {
      renderPostsList(
        link,
        changeInput.link,
        changeInput.disableContentFilter,
        changeInput.hideAddNewButton,
      );
    }
  };
  socialRoot?.render(
    <div style={{ width: 400 }}>
      <div className="postsTitle">Posts</div>
      <Divider
        role="presentation"
        variant="middle"
        style={{ marginBottom: 0 }}
      />
      <Posts
        env={env}
        tenantId={tenantId}
        link={usedLink}
        getUserCallback={getUserCallback}
        onUnmountCallback={onUnmountCallback}
        resourceTitle="Book Title"
        assetLink="http://example.com/book-url"
        tableOfLinks={Config.tableOfLinks}
        linkClickCallback={linkClickCallback}
        changeInputCallback={changeInputCallback}
        themeName={theme}
        disableContentFilter={disableContentFilter}
        hideAddNewButton={hideAddNewButton}
      />
      <CloseSideBar />
    </div>,
  );
};

const addMarkup = (markup: PostMarkup, link: Link) => {
  const paragraphId = findLastLinkId(markup.link);
  const markupEl = contextDocument.querySelector(
    `[data-chaucer-element-id="${paragraphId}"]`,
  ) as HTMLElement | null;
  if (!markupEl) return;
  const top =
    contextWindow!.pageYOffset + markupEl.getBoundingClientRect().bottom - 30;
  const left =
    (contextWindow!.innerWidth - markupEl.offsetWidth) / 2 +
    markupEl.offsetWidth +
    20;
  const markupIcon = contextDocument.createElement('span');
  markupIcon.setAttribute('data-social-annotation', paragraphId);
  markupIcon.setAttribute('style', `top: ${top}px; left: ${left}px;`);
  markupIcon.className = 'annotatorNoteHandle annotatorHandle';
  markupIcon.className +=
    markup.icon === 'video' ? ' videoMarkup' : ' postMarkup';
  markupIcon.innerHTML = `<span class='button__badge'>${markup.count}</span>`;
  markupIcon.onclick = () => {
    renderPostsList(link, markup.link, true, true);
  };
  contextDocument.body.appendChild(markupIcon);
};

const loadMarkups = (link: Link) => {
  contextDocument
    .querySelectorAll('span[data-social-annotation]')
    .forEach((item) => item.remove());
  SocialAPI.GetPostsMarkups(tenantId, link, env, getUserCallback().token).then(
    (markups) => {
      markups.forEach((markup) => addMarkup(markup, link));
    },
  );
};

const debouncedLoadMarkups = debounce(loadMarkups, 300);

const loadTopBar = () => {
  const topBar = contextDocument.createElement('div');
  topBar.id = 'topBar';
  topBar.innerHTML =
    '<h6 class="titleTopBar">2: Water and Carbon: The Chemical Basis of Life</h6>';
  contextDocument.body.insertBefore(topBar, contextDocument.body.firstChild);
};

const loadTopMarkup = (contentDiv: HTMLElement, link: Link) => {
  const topMarkup = document.createElement('div');
  topMarkup.id = 'topMarkup';
  topMarkup.onclick = () => {
    renderPostsList(link, link);
  };
  contentDiv.insertBefore(topMarkup, contentDiv.firstChild);
};

const loadSelectionMenu = (link: Link) => {
  const isDialog = queryParams.get('dialog') !== 'false';
  const selectionMenuScript = contextDocument.createElement('script');
  selectionMenuScript.type = 'text/javascript';
  selectionMenuScript.src = 'selection-menu.js';
  selectionMenuScript.async = true;
  selectionMenuScript.onload = () => {
    const script = contextDocument.createElement('script');
    script.src =
      'https://cdnjs.cloudflare.com/ajax/libs/tether/1.4.7/js/tether.min.js';
    script.async = true;
    script.onload = () => {
      // eslint-disable-next-line no-new
      new contextWindow!.SelectionMenu({
        container: contextDocument.querySelector('body > section'),
        content: '<a id="addNewPost">Create new post</a>',
        handler() {
          unmountNewPost();
          const { selectionEndElement, selectedText } = this;
          const linkId = selectionEndElement.dataset.chaucerElementId;
          const linkNewPost = JSON.parse(JSON.stringify(link));
          linkNewPost.subLink.subLink.subLink = {
            id: linkId,
          };
          const onSubmit = (data: IPost | PostWithMarkup) => {
            const markupEl = contextDocument.querySelector(
              `span[data-social-annotation="${linkId}"]`,
            );
            if (markupEl) {
              markupEl.remove();
            }
            addMarkup((data as PostWithMarkup).postsMarkup, link);
            unmountNewPost();
            renderPostsList(link, data.link, true, true);
          };
          newPostRoot?.render(
            <NewPost
              env={env}
              tenantId={tenantId}
              link={linkNewPost}
              linkContext={{
                highlight: [{ type: 'text', content: { text: selectedText } }],
                ranges: [],
                resourceTitle: 'Book Title',
                assetLink: 'http://example.com/book-url',
              }}
              getUserCallback={getUserCallback}
              onSubmitCallback={onSubmit}
              onCancelCallback={unmountNewPost}
              tableOfLinks={Config.tableOfLinks}
              dialog={isDialog}
              themeName={theme}
            />,
          );
          this.hide();
        },
      });
    };
    contextDocument.body.appendChild(script);
  };
  contextDocument.body.appendChild(selectionMenuScript);
};

const loadUserIdInput = () => {
  const inputUser = document.createElement('input');
  inputUser.id = 'userid';
  inputUser.value = userId;
  inputUser.type = 'text';
  inputUser.onchange = (e) => {
    const element = e.target as HTMLInputElement;
    userId = element.value;
  };
  document.body.appendChild(inputUser);
};

const loadThemeSelector = () => {
  const themes = ['', 'default', 'dark', 'sepia'];
  const selectUser = document.createElement('select');
  selectUser.id = 'theme';
  themes.forEach((themeOption) => {
    const option = document.createElement('option');
    option.value = themeOption;
    option.text = themeOption;
    if (theme === themeOption) {
      option.selected = true;
    }
    selectUser.appendChild(option);
  });
  selectUser.onchange = (e) => {
    const element = e.target as HTMLInputElement;
    theme = element.value as ThemeName;
  };
  document.body.appendChild(selectUser);
};

function init() {
  const { bookId, chapterId, pageId } = Config;
  const link = {
    id: bookId,
    subLink: {
      id: chapterId,
      subLink: {
        id: pageId,
      },
    },
  };
  const contentDiv = document.getElementById('content');
  if (contentDiv) {
    loadTopBar();
    contentDiv.insertBefore(socialRootElement, contentDiv.firstChild);
    contentDiv.insertBefore(newPostRootElement, contentDiv.firstChild);
    loadTopMarkup(contentDiv, link);
  }
  const cssLink = contextDocument.createElement('link');
  cssLink.href = 'social.css';
  cssLink.rel = 'stylesheet';
  cssLink.type = 'text/css';
  contextDocument.head.appendChild(cssLink);
  if (mode !== 'etext') {
    loadUserIdInput();
    loadThemeSelector();
  }
  debouncedLoadMarkups(link);
  loadSelectionMenu(link);

  // reload markups on window resize
  new ResizeObserver(() => {
    debouncedLoadMarkups(link);
  }).observe(document.getElementById('iframe')!);

  if (mode === 'etext') {
    document.getElementById('topMarkup')?.click();
  }
}

window.addEventListener('load', () => {
  contextWindow = (document.getElementById('iframe') as HTMLIFrameElement)
    .contentWindow;
  contextDocument = contextWindow!.document;
  init();
});
