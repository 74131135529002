import { Quill } from 'react-quill';
import { USER } from './types';

const Tooltip = Quill.import('ui/tooltip');

function extractVideoUrl(url: string) {
  let match =
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/,
    ) ||
    url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
  if (match) {
    return `${match[1] || 'https'}://www.youtube.com/embed/${
      match[2]
    }?showinfo=0`;
  }
  // eslint-disable-next-line no-cond-assign
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    return `${match[1] || 'https'}://player.vimeo.com/video/${match[2]}/`;
  }
  return url;
}

export default class BaseTooltip extends Tooltip {
  constructor(quill: any, boundsContainer: any) {
    super(quill, boundsContainer);
    this.textbox = this.root.querySelector('input[type="text"]');
    this.listen();
  }

  listen() {
    this.textbox.addEventListener('keydown', (event: any) => {
      if (event.key === 'Enter') {
        this.save();
        event.preventDefault();
      } else if (event.key === 'Escape') {
        this.cancel();
        event.preventDefault();
      }
    });
  }

  cancel() {
    this.hide();
  }

  edit(mode = 'link', preview = null) {
    this.root.classList.remove('ql-hidden');
    this.root.classList.add('ql-editing');
    if (preview != null) {
      this.textbox.value = preview;
    } else if (mode !== this.root.getAttribute('data-mode')) {
      this.textbox.value = '';
    }
    this.position(this.quill.getBounds(this.quill.selection.savedRange));
    this.textbox.select();
    this.textbox.setAttribute(
      'placeholder',
      this.textbox.getAttribute(`data-${mode}`) || '',
    );
    this.root.setAttribute('data-mode', mode);
  }

  restoreFocus() {
    const { scrollTop } = this.quill.scrollingContainer;
    this.quill.focus();
    this.quill.scrollingContainer.scrollTop = scrollTop;
  }

  save() {
    let { value } = this.textbox;
    switch (this.root.getAttribute('data-mode')) {
      case 'link': {
        const { scrollTop } = this.quill.root;

        if (this.linkRange) {
          this.quill.formatText(this.linkRange, 'link', value, USER);
          delete this.linkRange;
        } else {
          const range = this.quill.selection.savedRange;
          if (range && !range.length) {
            this.quill.insertText(range.index, value, 'silent');
            this.quill.setSelection(range.index, value.length, 'silent');
          }

          this.restoreFocus();
          this.quill.format('link', value, USER);
        }
        this.quill.root.scrollTop = scrollTop;
        break;
      }
      case 'video': {
        const range = this.quill.selection.savedRange;
        value = extractVideoUrl(value);
        this.quill.insertEmbed(range.index, 'video', value, USER);
        this.restoreFocus();
        // set cursor at the end
        this.quill.setSelection(this.quill.getLength(), 0);
      } // eslint-disable-next-line no-fallthrough
      default:
    }
    this.textbox.value = '';
    this.cancel();
  }
}
