import React, { FC, useRef, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import cn from 'classnames';

import { useId } from 'react-id-generator';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { ReactComponent as ArrowBlueLine } from '../../assets/images/arrow-blue-line.svg';
import { ReactComponent as ArrowGreenLine } from '../../assets/images/arrow-green-line.svg';
import { ReactComponent as ArrowOutlined } from '../../assets/images/arrow-outlined.svg';
import { ReplyProps } from './Reply.props';

import ReplyActionsPopup from './ReplyActionsPopup';
import CardHeader from '../CardHeader';
import CardActions, { CardAction } from '../CardActions';
import CardContent from '../CardContent';
import NewReply from '../NewReply';
import GenericErrorModal from '../GenericErrorModal';
import PostReportModal from '../PostReportModal';

import { reportPost } from '../../services/SocialAPI';
import { isPostRecentlyEdited } from '../../utils/postUtils';
import { sendEventSelectPostAction } from '../../utils/events';
import { useCommonDataContext } from '../../providers/commonDataContext';
import { useLayoutModeContext } from '../../providers/layoutModeContext';

import styles from './Reply.module.scss';
import { IPost, VoteValue } from '../../types';
import { A11Y_ID_PREFIX } from '../../utils/a11y';
import SrOnlyText from '../A11yRelatedLib/SrOnlyText';

const Reply: FC<ReplyProps> = ({
  className,
  reply,
  onDeleteReply,
  editReplyClickHandler,
  onThumbUpReply,
  replyPostReplyHandler,
  inlineCreateUpdateReply,
}) => {
  const [reportModalOpened, setReportModalOpened] = useState<boolean>(false);
  const [inlineCommentsOpened, setInlineCommentsOpened] =
    useState<boolean>(false);
  const [inlineCommentsEditMode, setInlineCommentsEditMode] =
    useState<boolean>(false);

  const [error, setError] = useState<string>();
  const postRef = useRef(null);

  const { env, token, userId, onLoginRequire } = useCommonDataContext();
  const { isCommentsMode } = useLayoutModeContext();

  const [userNameHtmlId, upVoteHtmlId, downVoteHtmlId, contentId] = useId(
    4,
    A11Y_ID_PREFIX,
  );

  const mentionedUser = reply.user
    ? {
        userId: reply.userId,
        ...reply.user,
      }
    : undefined;

  const openPostReportModal = () => {
    setReportModalOpened(true);
  };

  const closePostReportModal = () => {
    setReportModalOpened(false);
  };

  const confirmReportPost = () =>
    reportPost(token, reply.id, env)
      .then(() => {
        const hasMentions =
          reply?.content?.mentions && reply.content.mentions.length > 0;
        sendEventSelectPostAction(
          'Report Post',
          hasMentions ? 'Reply to Reply' : 'Reply',
        );
      })
      .catch(() => setError('Error occured while reporting this reply'));

  const onEdit = (newReply: IPost) => {
    if (isCommentsMode) {
      setInlineCommentsEditMode(true);
      setInlineCommentsOpened(true);
    } else {
      editReplyClickHandler(newReply);
    }
  };

  const onReply = () => {
    if (!userId && onLoginRequire) {
      onLoginRequire();
      return;
    }

    if (isCommentsMode) {
      setInlineCommentsEditMode(false);
      setInlineCommentsOpened(!inlineCommentsOpened);
    } else {
      replyPostReplyHandler(reply);
    }
  };

  const onThumbsClick = (count: VoteValue) => {
    if (!userId && onLoginRequire) {
      onLoginRequire();
      return;
    }

    onThumbUpReply(reply, count);
  };

  const iconStyle = isCommentsMode ? styles.iconComments : styles.icon;
  const iconUp = isCommentsMode ? styles.iconUp : styles.icon;
  const iconDown = isCommentsMode ? styles.iconDown : styles.icon;
  const iconButtonStyle = isCommentsMode ? styles.iconButton : '';

  return (
    <article aria-labelledby={userNameHtmlId} aria-describedby={contentId}>
      <div className={cn(styles.reply, className)} ref={postRef}>
        <CardHeader
          isReply
          user={reply.user}
          userNameHtmlId={userNameHtmlId}
          createdAt={reply.createdAt}
          underReview={reply.reviewStatus === 'needs_review' && !isCommentsMode}
          edited={isPostRecentlyEdited(reply.createdAt, reply.updatedAt)}
          classes={{
            root: cn(styles.replyHeader, {
              [styles.replyHeaderComment]: isCommentsMode,
            }),
          }}
          actions={
            userId && (
              <div className={styles.replyActionsPopup}>
                <ReplyActionsPopup
                  reply={reply}
                  onDelete={onDeleteReply}
                  onReport={openPostReportModal}
                  onEdit={onEdit}
                  isOwner={reply.userId === userId}
                />
              </div>
            )
          }
        />
        <CardContent
          classes={{ root: styles.replyContent }}
          context={reply}
          postRef={postRef}
          postContentId={contentId}
        />
        <CardActions
          className={styles.actionBlock}
          classes={isCommentsMode ? {} : { root: styles.replyActions }}
          onReply={onReply}
          disableSpacing
        >
          <CardAction
            amount={reply.score > 0 ? reply.score : 0}
            preChildren={
              <>
                <SrOnlyText id={upVoteHtmlId}>Up Vote for</SrOnlyText>
                <IconButton
                  size="small"
                  aria-labelledby={`${upVoteHtmlId} ${userNameHtmlId}`}
                  aria-pressed={reply.votes && reply.votes[0]?.vote === -1}
                  onClick={() => onThumbsClick(1)}
                  className={iconButtonStyle}
                >
                  {reply.votes && reply.votes[0]?.vote === 1 ? (
                    <>
                      {isCommentsMode ? (
                        <ArrowGreenLine />
                      ) : (
                        <Arrow className={iconStyle} />
                      )}
                    </>
                  ) : (
                    <ArrowOutlined
                      aria-hidden="true"
                      focusable="false"
                      className={iconUp}
                    />
                  )}
                </IconButton>
              </>
            }
          >
            <SrOnlyText id={downVoteHtmlId}>Down Vote for</SrOnlyText>
            <IconButton
              size="small"
              aria-labelledby={`${downVoteHtmlId} ${userNameHtmlId}`}
              aria-pressed={reply.votes && reply.votes[0]?.vote === -1}
              onClick={() => onThumbsClick(-1)}
              style={{
                transform: 'rotateX(180deg)',
                marginRight: '4px',
                paddingTop: '1px',
              }}
              className={iconButtonStyle}
            >
              {reply.votes && reply.votes[0]?.vote === -1 ? (
                <>
                  {isCommentsMode ? (
                    <ArrowBlueLine />
                  ) : (
                    <Arrow className={iconStyle} />
                  )}
                </>
              ) : (
                <ArrowOutlined
                  aria-hidden="true"
                  focusable="false"
                  className={iconDown}
                />
              )}
            </IconButton>
          </CardAction>
        </CardActions>

        {isCommentsMode && inlineCommentsOpened && inlineCreateUpdateReply && (
          <NewReply
            postId={reply.id}
            {...(inlineCommentsEditMode
              ? {
                  reply,
                }
              : {
                  context: reply,
                  mentionedUser,
                })}
            onSubmitCallback={(newReply) => {
              inlineCreateUpdateReply(newReply);
              setInlineCommentsOpened(false);
            }}
            onCancelRTE={() => setInlineCommentsOpened(false)}
            dialog={false}
            isReplyToReply
          />
        )}
      </div>
      <PostReportModal
        open={reportModalOpened}
        userName={reply.user?.userName}
        onCancel={closePostReportModal}
        onReport={confirmReportPost}
      />
      <GenericErrorModal
        open={!!error}
        message={error}
        onClose={() => setError(undefined)}
      />
    </article>
  );
};

export default Reply;
