import React, { FC, createContext, useContext, useMemo, useState } from 'react';

import nextId from 'react-id-generator';

interface GenericModal {
  onCancel?: () => void;
}

interface ModalParams<T extends GenericModal> {
  id: string;
  Component: FC<T>;
  props: T;
}

interface ModalContextValue {
  openModal: <T>(Component: FC<T>, props: T) => () => void;
}

const ModalContext = createContext<ModalContextValue>({
  openModal: () => () => {},
});

export const ModalProvider: FC = ({ children }) => {
  const [modals, setModals] = useState<ModalParams<any>[]>([]);

  const openModal = (Component: FC<any>, props: any) => {
    const id = nextId();

    const closeModal = () => {
      setModals((prevModals) => prevModals.filter((modal) => modal.id !== id));
    };

    const newModal = {
      id,
      Component,
      props: {
        onCancel: closeModal,
        ...props,
      },
    };

    setModals((prevModals) => {
      const newModals = prevModals.slice();
      newModals.push(newModal);
      return newModals;
    });

    return closeModal;
  };

  const value = useMemo(
    () => ({
      openModal,
    }),
    [],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modals.map(({ id, Component, props }) => (
        <Component key={id} {...props} />
      ))}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
