import { StyleProps } from '../../types';

export default (theme: any) => ({
  root: {
    padding: '8px 8px 13px 8px !important',
    alignItems: 'center',
  },
  actionsBlock: {
    margin: 'auto',
  },
  avatar: ({ isCommentsMode }: StyleProps) => {
    const common = {
      fontFamily: 'Hind !important',
    };

    return isCommentsMode
      ? {
          width: 40,
          height: 40,
          fontSize: 16,
          marginTop: 0,
          fontWeight: 700,
          marginRight: 12,
          ...common,
        }
      : {
          width: 32,
          height: 32,
          fontSize: 14,
          marginTop: 2,
          marginRight: 8,
          ...common,
        };
  },
  title: ({ isCommentsMode }: StyleProps) => {
    const result = {
      color: theme.palette.primaryText,
    };

    return isCommentsMode
      ? {
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '24px',
          ...result,
        }
      : result;
  },

  titleWrapper: {
    margin: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 0 : '9px 0 2px',
    display: 'flex',
    alignItems: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 'flex-start' : 'center',
  },

  createdAt: {
    color: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? '#9BA1AD' : theme.palette.primaryHintColor,
    fontSize: ({ isCommentsMode }: StyleProps) => (isCommentsMode ? 12 : 10),
    lineHeight: '18px',
  },

  channelsUserInfo: {
    display: 'flex',
    flexDirection: 'column' as const, // issue with TS - do not remove
    gap: '2px',
  },

  userName: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '12px',
    fontWeight: 400,
    fontSize: 14,
    margin: 0,
  },

  tag: {
    fontWeight: 900,
    fontSize: 10,
    color: '#4F5561',
    padding: '2px 6px',
    background: theme.palette.buttonText,
    borderRadius: 4,
    border: '1px solid #E8EEFA',
    marginLeft: 8,
  },
});
