class EventSystem {
  queue: Record<string, ((data: any) => void)[]> = {};

  publish<T>(event: string, data: T) {
    const queue = this.queue[event];

    if (typeof queue === 'undefined') {
      return false;
    }

    queue.forEach((callback) => {
      callback(data);
    });

    return true;
  }

  subscribe<T>(event: string, callback: (data: T) => void) {
    if (typeof this.queue[event] === 'undefined') {
      this.queue[event] = [];
    }

    this.queue[event].push(callback);
  }

  //  the callback parameter is optional. Without it the whole event will be removed, instead of
  // just one subscibtion. Enough for simple implementations
  unsubscribe<T>(event: string, callback?: (data: T) => void) {
    const { queue } = this;

    if (typeof queue[event] !== 'undefined') {
      if (typeof callback === 'undefined') {
        delete queue[event];
      } else {
        this.queue[event] = queue[event].filter((sub: any) => sub !== callback);
      }
    }
  }
}

export default new EventSystem();
