import { Quill } from 'react-quill';
import '../../../scss/ql-video.scss';
import Link from './replaced-link';

import {
  getYoutubeUrls,
  hydrateYoutubeNode,
  YOUTUBETAG,
} from '../../../utils/contentHelpers';

const Video = Quill.import('formats/video');

export default class ReplacedVideo extends Video {
  static tagName = 'a';

  static create(value: string): HTMLElement {
    const a = super.create(value) as HTMLAnchorElement;
    a.removeAttribute('frameborder');
    a.removeAttribute('allowfullscreen');
    a.removeAttribute('src');

    const { thumbnailUrl, videoUrl } = getYoutubeUrls(value);

    if (thumbnailUrl && videoUrl) {
      return hydrateYoutubeNode(a, thumbnailUrl, videoUrl);
    }
    a.removeAttribute('class');
    a.setAttribute('href', Link.sanitize(value));
    a.setAttribute('target', '_blank');
    a.textContent = value;

    return a;
  }

  static value(domNode: HTMLElement) {
    return domNode.tagName === YOUTUBETAG.toUpperCase()
      ? domNode.textContent
      : domNode.getAttribute('href');
  }
}
