import React, { useEffect } from 'react';
import 'mathlive';
import { virtualKeyboardLayers } from './math-keyboards';

interface MathFieldProps {
  setMathFieldValue: (value: any) => void;
}

const MathField = ({ setMathFieldValue }: MathFieldProps) => {
  useEffect(() => {
    document.body.style.setProperty('--keyboard-zindex', '2000');
    const mathField = document.querySelector('#math-field');

    // @ts-ignore
    mathVirtualKeyboard.layouts = virtualKeyboardLayers;

    if (mathField) {
      // @ts-ignore
      mathField.menuItems = mathField.menuItems.filter(
        (item: { id: string }) => item.id !== 'mode',
      );
    }

    // @ts-ignore
    mathField.executeCommand(['showVirtualKeyboard']);
    // @ts-ignore
    mathField.addEventListener(
      'input',
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setMathFieldValue(event.target.value);
      },
    );
    return () => {
      // @ts-ignore
      mathField.executeCommand(['hideVirtualKeyboard']);
      // @ts-ignore
      mathField.removeEventListener(
        'input',
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setMathFieldValue(event.target.value);
        },
      );
    };
  }, []);

  return (
    <math-field
      id="math-field"
      data-formula="e=mc^2"
      virtual-keyboard-mode="off"
      role="textbox"
    />
  );
};

export default MathField;
