import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DialogModal from '../DialogModal';

import { GenericErrorModalProps } from './GenericErrorModal.props';
import styles from './GenericErrorModal.module.scss';

const GenericErrorModal: FC<GenericErrorModalProps> = ({
  open = true,
  message,
  onClose,
  title = 'Error',
  onConfirm,
}) => (
  <DialogModal
    className={styles.genericErrorModal}
    open={open}
    title={title}
    isFullScreen={false}
    onCancelCallback={onClose}
    actions={
      <div className={styles.buttons}>
        {onConfirm && <Button onClick={onConfirm}>OK</Button>}
      </div>
    }
  >
    <div className={styles.content}>
      <Typography className={styles.text}>{message}</Typography>
    </div>
  </DialogModal>
);

export default GenericErrorModal;
