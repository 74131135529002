import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { ReactElement, useState } from 'react';
import ReactQuill from 'react-quill';
import styles from './MathFieldDialog.module.scss';
import MathField from '../MathField';
import { USER } from '../quill-modules/custom-theme/types';
import SrOnlyText from '../../A11yRelatedLib/SrOnlyText';

interface MathFieldProps {
  showMathDialog: boolean;
  closeMathField: () => void;
  cursorPosition: number;
  reactQuillRef: ReactQuill | null | undefined;
}

const MathFieldDialog = ({
  showMathDialog,
  closeMathField,
  cursorPosition,
  reactQuillRef,
}: MathFieldProps) => {
  const [mathFieldValue, setMathFieldValue] = useState('');
  const MATH_KB_HINT_DESCRIPTION_IDREF = 'math-kb-hint-description';

  const saveMath = () => {
    const quillElement = reactQuillRef?.getEditor();

    if (quillElement) {
      if (cursorPosition !== null) {
        quillElement.insertEmbed(
          cursorPosition,
          'formula',
          mathFieldValue,
          USER,
        );
        quillElement.insertText(cursorPosition + 1, ' ', USER);
        quillElement.setSelection(cursorPosition + 2, 0, USER);
      }
    }

    closeMathField();
  };

  const onDialogClose = (e: ReactElement, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      closeMathField();
    }
  };

  return (
    <Dialog
      maxWidth={false}
      open={showMathDialog}
      className={styles.dialog}
      onClose={onDialogClose}
    >
      <DialogContent className={styles.mathFieldContent}>
        <div className={styles.fieldBox}>
          <div className={styles.enterFormula}>
            <span>Enter formula:</span>
            <MathField setMathFieldValue={setMathFieldValue} />
          </div>
          <div className={styles.actions}>
            <button type="button" onClick={saveMath}>
              Save
            </button>
            <button type="button" onClick={closeMathField}>
              Close
            </button>
          </div>
        </div>
        <div className={styles.hint}>
          <a
            aria-describedby={MATH_KB_HINT_DESCRIPTION_IDREF}
            target="_blank"
            href="https://cortexjs.io/mathlive/reference/keybindings/"
            rel="noreferrer"
          >
            Note: you can use shortcuts for the input.
          </a>
          <SrOnlyText id={MATH_KB_HINT_DESCRIPTION_IDREF}>
            opens in new tab
          </SrOnlyText>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MathFieldDialog;
