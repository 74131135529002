import React, { FC, useEffect, useRef, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Icon } from '@greenville/mui-theme';

import cn from 'classnames';

import PaperDraggable, { DRAGGABLE_ELEMENT } from './PaperDraggable';
import ResizableBox from './ResizableBox';

import styles from './DialogModal.module.scss';
import { DialogModalProps } from './DialogModal.props';
import { someLater } from '../../utils/a11y';

export const ROOT_MODAL_ID = 'rootModal';

const DialogModal: FC<DialogModalProps> = ({
  className,
  open,
  isFullScreen = true,
  isResizable = false,
  title,
  onCancelCallback,
  children,
  actions,
}) => {
  const onClose = (event: any, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    onCancelCallback?.();
  };

  if (open) {
    someLater(500).then(() => {
      const lives = document.querySelectorAll('body > [aria-live]');
      for (let i = 0; i < lives.length; i += 1) {
        lives[i].removeAttribute('aria-hidden');
      }
    });
  }

  const closeBtnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (open) {
      someLater(0).then(() => {
        closeBtnRef.current?.focus();
      });
    }
  }, [open]);

  const getContent = () => (
    <>
      <DialogTitle className={styles.header} disableTypography>
        <div
          className={cn({
            [DRAGGABLE_ELEMENT]: !isFullScreen,
          })}
        >
          {title && (
            <Typography variant="h2" className={styles.title}>
              {title}
            </Typography>
          )}
        </div>

        <IconButton
          ref={closeBtnRef}
          aria-label="close"
          className={styles.closeButton}
          onClick={onCancelCallback}
        >
          <Icon name="Close" />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.content}>
        {title && <Divider role="presentation" className={styles.divider} />}
        {children}
      </DialogContent>

      {actions && (
        <DialogActions className={styles.actions}>{actions}</DialogActions>
      )}
    </>
  );

  const onModalShow = useCallback((node: HTMLElement) => {
    node.id = ROOT_MODAL_ID; // eslint-disable-line no-param-reassign
  }, []);

  return (
    <Dialog
      className={cn(styles.dialogModal, className)}
      fullScreen={isFullScreen}
      open={open}
      onClose={onClose}
      PaperComponent={PaperDraggable}
      PaperProps={{
        className: isFullScreen ? styles.fullScreen : '',
      }}
      TransitionProps={{
        onEntered: onModalShow,
      }}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      hideBackdrop
    >
      <ResizableBox enable={isResizable}>{getContent()}</ResizableBox>
    </Dialog>
  );
};

export default DialogModal;
