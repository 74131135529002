import { Rule } from 'rc-field-form/es/interface';
import differenceBy from 'lodash/differenceBy';
import { PostContent, Attachment } from '../../types';
import { FormValues } from './FormPost.props';

const createTitleValidationRule = (): Rule => ({
  required: false,
  min: 5,
  max: 100,
  validator: async (rule, value: string = '') => {
    const trimmedValue = value ? value.trim() : '';

    if (trimmedValue) {
      if (rule.min && trimmedValue.length < rule.min) {
        throw new Error(
          `Your title must be at least ${rule.min} characters long.`,
        );
      }

      if (rule.max && trimmedValue.length > rule.max) {
        throw new Error(`Your title cannot exceed ${rule.max} characters.`);
      }

      return;
    }

    if (rule.required) {
      throw new Error('Your post must contain a title.');
    }
  },
});

const createContentValidationRule = ({ required = true, min = 0 }): Rule => ({
  required,
  min,
  max: 5000,
  validator: async (rule, value?: PostContent) => {
    const html = value?.html || '';
    let textContentLenght = html.replace(/<[^>]*>?/gm, '').length;
    if (textContentLenght === 0) {
      textContentLenght = html.match(/<img([\w\W]+?)\/?>/gm)?.length || 0;
    }
    if (rule.min && textContentLenght < rule.min) {
      throw new Error(
        `Your reply must be at least ${rule.min} character long.`,
      );
    }

    if (rule.max && textContentLenght > rule.max) {
      throw new Error(`Your post cannot exceed ${rule.max} characters.`);
    }

    if (rule.required && textContentLenght === 0) {
      throw new Error('Your post must contain a description.');
    }
  },
});

const checkAttachments = (
  initState: Attachment[] = [],
  updatedState: Attachment[] = [],
) =>
  [
    ...differenceBy(initState, updatedState, 'url'),
    ...differenceBy(updatedState, initState, 'url'),
  ].length > 0;

const checkContent = (initValue = '', currentValue = '') => {
  // Text editor has an empty state equals to '<p><br></p>' - that mean that there is no text typed.
  if (initValue === '' && currentValue === '<p><br></p>') {
    return false;
  }
  return currentValue !== initValue;
};

const useIsFormDirty = (
  initialValues: FormValues,
  currentValues: FormValues,
) => {
  const isTitleChanged = initialValues?.title !== currentValues?.title;
  const isLinkChanged = initialValues?.link !== currentValues?.link;
  const isContentChanged = checkContent(
    initialValues?.content?.html,
    currentValues?.content?.html,
  );
  const isAttachmentsChanged = checkAttachments(
    initialValues?.content?.attachments,
    currentValues.content?.attachments,
  );

  return (
    isTitleChanged || isLinkChanged || isContentChanged || isAttachmentsChanged
  );
};

export {
  createTitleValidationRule,
  createContentValidationRule,
  useIsFormDirty,
};
