import React, { useRef, useEffect, FC, memo } from 'react';

import useIntersectionObserver from '@react-hook/intersection-observer';
import useLatest from '@react-hook/latest';
import { InfiniteLoaderProps } from './InfiniteLoader.props';
import styles from './InfiniteLoader.module.scss';
import Loader from '../Loader';

const InfiniteLoader: FC<InfiniteLoaderProps> = memo(
  ({ hasMore, isLoading, onNeedMore, children }) => {
    const needMoreHandler = useLatest(onNeedMore);
    const detectorRef = useRef<HTMLDivElement>(null);
    const { isIntersecting } = useIntersectionObserver(detectorRef);

    useEffect(() => {
      if (hasMore && !isLoading && isIntersecting) {
        needMoreHandler.current();
      }
    }, [hasMore, isLoading, isIntersecting]);

    return (
      <Loader isLoading={isLoading}>
        {children}
        <div ref={detectorRef} className={styles.detectorBlock} />
      </Loader>
    );
  },
);

export default InfiniteLoader;
