import React, { FC, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@greenville/mui-theme';

import { FilterDropdownProps } from './Filters.props';
import { ReactComponent as SortIcon } from '../../assets/images/filter-icon.svg';
import styles from './Filters.module.scss';
import { FilterActionType } from '../../utils/events';

const FilterDropdown: FC<FilterDropdownProps> = ({
  value,
  onFilter,
  hasUserId,
}) => {
  const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLElement>(
    null,
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorFilterEl(null);
  };

  const onFilterClick = (sortType: string, eventType: FilterActionType) => {
    onFilter(sortType, eventType);
    handleClose();
  };

  return (
    <>
      <Tooltip title="Filter" data-testid="filter-dropdown">
        <IconButton
          aria-label="filter-label"
          id="filter"
          aria-controls="filter-menu"
          aria-expanded={anchorFilterEl ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleFilterClick}
          className={styles.filterSortIcon}
        >
          <SortIcon className={styles.icon} />
        </IconButton>
      </Tooltip>

      <Menu
        classes={{
          paper: styles.popover,
        }}
        anchorEl={anchorFilterEl}
        open={!!anchorFilterEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          className={styles.menuItem}
          value="all"
          selected={value === 'all'}
          onClick={() => onFilterClick('all', 'All')}
        >
          All posts
        </MenuItem>
        {hasUserId && (
          <MenuItem
            className={styles.menuItem}
            value="mine"
            selected={value === 'mine'}
            onClick={() => onFilterClick('mine', 'Mine')}
          >
            My posts
          </MenuItem>
        )}
        {hasUserId && (
          <MenuItem
            className={styles.menuItem}
            value="favorites"
            selected={value === 'favorites'}
            onClick={() => onFilterClick('favorites', 'Saved Posts')}
          >
            Saved posts
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default FilterDropdown;
