import React, { FC, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@greenville/mui-theme';

import { SortDropdownProps } from './Filters.props';
import { ReactComponent as SortIcon } from '../../assets/images/sort-icon.svg';
import styles from './Filters.module.scss';
import { SortPostsActionType } from '../../utils/events';

const SortDropdown: FC<SortDropdownProps> = ({
  value,
  onSort,
  hasTableLinks,
}) => {
  const [anchorSortEl, setAnchorSortEl] = useState<null | HTMLElement>(null);

  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSortEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorSortEl(null);
  };

  const onSortClick = (sortType: string, eventType: SortPostsActionType) => {
    onSort(sortType, eventType);
    handleClose();
  };

  return (
    <>
      <Tooltip title="Sort">
        <IconButton
          aria-label="sort-label"
          id="sort"
          aria-controls="sort-menu"
          aria-expanded={anchorSortEl ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleSortClick}
          className={styles.filterSortIcon}
          data-testid="sort-dropdown"
        >
          <SortIcon className={styles.icon} />
        </IconButton>
      </Tooltip>

      <Menu
        classes={{
          paper: styles.popover,
        }}
        anchorEl={anchorSortEl}
        open={!!anchorSortEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        {hasTableLinks && (
          <MenuItem
            className={styles.menuItem}
            value="linkOrder"
            selected={value === 'linkOrder'}
            onClick={() => onSortClick('linkOrder', 'Book order')}
          >
            Book order
          </MenuItem>
        )}
        <MenuItem
          className={styles.menuItem}
          value="recent"
          selected={value === 'recent'}
          onClick={() => onSortClick('recent', 'Recent')}
        >
          Most recent
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          value="rank"
          selected={value === 'rank'}
          onClick={() => onSortClick('rank', 'Rank')}
        >
          Most popular
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortDropdown;
