import ms from 'ms';
import { FormPostData } from '../types';

export function isPostRecentlyEdited(
  createdAt: string,
  updatedAt: string,
): boolean {
  const createdAtDate = new Date(createdAt).getTime();
  const updatedAtDate = new Date(updatedAt).getTime();

  if (createdAtDate >= updatedAtDate) {
    return false;
  }

  const timeDiff = Date.now() - updatedAtDate;
  return timeDiff < ms('48h');
}

export function doesPostNeedReview(post: FormPostData): boolean {
  const { content } = post;
  const html = content?.html || '';
  const hasHtml = !!html;
  const links = html.match(
    /src="([^">]+)" | href="([^">]+)" | https="([^">]+)"/g,
  );
  const notOnlyGif =
    links !== null && links.some((item) => !item.includes('giphy'));
  const hasLinks = hasHtml && notOnlyGif;
  const hasAttachments = !!content?.attachments?.length;
  return hasLinks || hasAttachments;
}
