import React, { FC, useState } from 'react';
import Card from '@material-ui/core/Card';

import { FormPostData, IPost } from '../../types';
import FormPost from '../FormPost/FormPost';
import DialogModal, { DRAGGABLE_ELEMENT } from '../DialogModal';
import CardHeader from '../CardHeader';
import CardContent from '../CardContent';

import { NewReplyModalProps } from './NewReplyModal.props';

import styles from './NewReplyModal.module.scss';

const NewReplyModal: FC<NewReplyModalProps> = ({
  open,
  contextReply,
  tableOfLinks,
  reply,
  mentionedUser,
  isFullScreen,
  inlineMode = false,
  onCancelCallback,
  onCancelRTE,
  onUpdateReply,
  onCreateReply,
  setIsEmpty,
  isRequestInProgress,
}) => {
  const { user, createdAt = '' } = (contextReply as IPost) || {};

  const [error, setError] = useState<string>();

  const handleSubmit = async (newReply: FormPostData) => {
    try {
      setError(undefined);

      if (reply) {
        await onUpdateReply(newReply);
      } else {
        await onCreateReply(newReply);
      }
    } catch (err) {
      setError('Something went wrong - please try again.');
    }
  };

  return (
    <>
      {!inlineMode ? (
        <DialogModal
          className={styles.newReply}
          open={open}
          isFullScreen={isFullScreen}
          isResizable={!isFullScreen}
          title={reply ? 'Edit your reply' : 'Reply'}
          onCancelCallback={onCancelCallback}
        >
          <FormPost
            onSubmitCallback={handleSubmit}
            submitActionText={reply ? 'Update' : 'Reply'}
            isRequestInProgress={isRequestInProgress}
            error={error}
            hideTitle
            hideType
            isReply
            content={reply?.content}
            anonymous={!!reply?.anonymous}
            questionPlaceholder={
              reply
                ? 'Edit your reply'
                : `Reply to ${user?.userName}. Your post will be visibile to everyone reading this eText.`
            }
            mentionedUser={mentionedUser}
            footerClassName={!isFullScreen ? DRAGGABLE_ELEMENT : ''}
            setIsEmpty={setIsEmpty}
          >
            {contextReply && (
              <Card className={styles.postCard}>
                <CardHeader user={user} createdAt={createdAt} />
                <CardContent
                  context={contextReply}
                  tableOfLinks={tableOfLinks}
                  isViewMode
                />
              </Card>
            )}
          </FormPost>
        </DialogModal>
      ) : (
        <FormPost
          onSubmitCallback={handleSubmit}
          onCancelCallback={onCancelRTE}
          submitActionText={reply ? 'Update' : 'Post comment'}
          isRequestInProgress={isRequestInProgress}
          error={error}
          hideTitle
          hideType
          isReply
          content={reply?.content}
          anonymous={!!reply?.anonymous}
          questionPlaceholder={
            reply ? 'Edit your reply' : `Reply to ${user?.userName}`
          }
          mentionedUser={mentionedUser}
          setIsEmpty={setIsEmpty}
          showRTEPanel
          inlineMode
        />
      )}
    </>
  );
};

export default NewReplyModal;
