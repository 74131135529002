import React, { FC } from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { AvatarProps } from './Avatar.props';

const StyledAvatar = withStyles(() => ({
  root: {
    width: 38,
    height: 38,
  },
}))(MuiAvatar);

const useStyles = makeStyles(() => ({
  initialsSpan: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: 1,
  },
}));

const Avatar: FC<AvatarProps> = ({ className, user }) => {
  const startColor = user ? user.avatar.startColor : '##9F9FB0';
  const endColor = user ? user.avatar.endColor : '##9F9FB0';
  const background = `linear-gradient(180deg, ${startColor} 0%, ${endColor} 100%)`;
  const image = user?.avatar.image;
  const shouldShowInitils = user?.type !== 'tutor';

  const avatarProps: any = {
    className,
    'aria-hidden': 'true',
  };

  if (!image) {
    avatarProps.style = { background };
  }

  const classes = useStyles();

  return (
    <>
      <StyledAvatar {...avatarProps}>
        {image && <img src={image} alt="" />}
        {user && shouldShowInitils ? (
          <span className={classes.initialsSpan}>{user.avatar.initials}</span>
        ) : (
          <svg
            width="18"
            height="18"
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.42533 4.17313C6.03029 3.75314 6.42425 3.07343 6.42425 2.30769C6.42425 1.03523 5.33673 0 4 0C2.66327 0 1.57575 1.03523 1.57575 2.30769C1.57575 3.07343 1.96969 3.75314 2.57467 4.17313C1.0708 4.72096 0 6.10894 0 7.73077C0 8.43063 0.59813 9 1.33333 9H6.66667C7.40187 9 8 8.43063 8 7.73077C8 6.10894 6.9292 4.72096 5.42533 4.17313ZM2.30304 2.30769C2.30304 1.41697 3.06429 0.692314 4 0.692314C4.93571 0.692314 5.69697 1.41697 5.69697 2.30769C5.69697 3.19841 4.93571 3.92309 4 3.92309C3.06429 3.92309 2.30304 3.19841 2.30304 2.30769ZM6.66667 8.30769H1.33333C0.999155 8.30769 0.727281 8.04888 0.727281 7.73075C0.727281 6.01291 2.1954 4.61537 4.00002 4.61537C5.80464 4.61537 7.27276 6.0129 7.27276 7.73075C7.27274 8.04888 7.00086 8.30769 6.66667 8.30769Z"
              fill="white"
            />
          </svg>
        )}
      </StyledAvatar>
    </>
  );
};

export default Avatar;
