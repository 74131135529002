import { useEffect } from 'react';
import { ThemeName } from '../types';

const availableThemes: ThemeName[] = ['default', 'dark', 'sepia'];

export const normalizeThemeName = (themeName: ThemeName | undefined) => {
  if (!themeName) return undefined;
  return availableThemes.includes(themeName) ? themeName : 'default';
};

const clearThemeClasses = () => {
  Array.from(document.body.classList).forEach((className) => {
    if (className.startsWith('social-theme-')) {
      document.body.classList.remove(className);
    }
  });
};

const useTheme = (
  tenantId: number | undefined,
  themeName: ThemeName | undefined,
  enabled = true,
) => {
  useEffect(() => {
    const normalizedThemeName = normalizeThemeName(themeName);

    if (enabled && normalizedThemeName) {
      clearThemeClasses();
      document.body.classList.add(
        `social-theme-${tenantId}-${normalizedThemeName}`,
      );
    }
  }, [tenantId, themeName]);
};

export default useTheme;
