import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { IPost, TableOfLinks } from '../../types';
import { flattenTableOfLinks } from '../../utils/linkHelpers';
import Accordion from '../Accordion';
import PostList, { ScrollablePostList } from './PostList';

import { PostListByLinkOrderProps } from './Posts.props';
import { useCommonDataContext } from '../../providers/commonDataContext';
import styles from './Posts.module.scss';

const groupPostsByChapter = (posts: IPost[], tableOfLinks: TableOfLinks[]) => {
  const flatTableOfLinks = flattenTableOfLinks(tableOfLinks!, 2);

  const groups = flatTableOfLinks.map<[TableOfLinks, IPost[]]>((link) => [
    link,
    [],
  ]);
  groups[0][0].name = 'No Chapter';

  posts.forEach((post) => {
    const linkId = post.link.subLink?.id || post.link.id;
    const group = groups.find(([link]) => link.id === linkId) || groups[0];
    group[1].push(post);
  });

  return groups;
};

const PostListByLinkOrder = forwardRef<
  ScrollablePostList,
  PostListByLinkOrderProps
>(
  (
    {
      posts,
      onlyShowLink,
      hasMorePosts,
      hideLoadMore,
      onPostUpdate,
      onPostDelete,
      onLinkClick,
      onLoadMoreClick,
      scrollableContainer,
    },
    ref,
  ) => {
    const groupRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const postListRefs = useRef<Record<string, ScrollablePostList | null>>({});

    const { tableOfLinks } = useCommonDataContext();

    let groupedPosts = groupPostsByChapter(posts, tableOfLinks!);

    if (onlyShowLink) {
      groupedPosts = groupedPosts.filter(
        (groupedPost) => onlyShowLink.id === groupedPost[0].id,
      );
    }

    useImperativeHandle(ref, () => ({
      scrollToPost: (id: number) => {
        const group = groupedPosts.find(([, items]) =>
          items.some((item) => item.id === id),
        );

        if (group) {
          postListRefs.current[group[0].id]?.scrollToPost(id);
        }
      },
      scrollToGroup: (linkId: string) => {
        if (groupedPosts[0][0].id !== linkId) {
          // if book (0th) link, do not scroll
          const groupRef = groupRefs.current[linkId];
          groupRef?.scrollIntoView({ block: 'start' });
        }
      },
      scrollToTop: () => {
        const { id } = groupedPosts[0][0];

        if (id) {
          const groupRef = groupRefs.current[id];
          groupRef?.scrollIntoView({ block: 'start' });
        }
      },
    }));

    return (
      <div role="list" aria-label="Chapters comments">
        {groupedPosts.map(([group, items]) => (
          <Accordion
            key={group.id}
            ref={(groupRef) => {
              groupRefs.current[group.id] = groupRef;
            }}
            label={group.name}
            disabled={items.length === 0}
          >
            <PostList
              ref={(postListRef) => {
                postListRefs.current[group.id] = postListRef;
              }}
              posts={items}
              onPostUpdate={onPostUpdate}
              onPostDelete={onPostDelete}
              onLinkClick={onLinkClick}
              scrollableContainer={scrollableContainer}
            />

            {!!hasMorePosts[group.id] && !hideLoadMore && (
              <button
                className={styles.loadMoreButton}
                type="button"
                onClick={() => onLoadMoreClick(group.id)}
              >
                Load more
              </button>
            )}
          </Accordion>
        ))}
      </div>
    );
  },
);

export default PostListByLinkOrder;
