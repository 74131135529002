import { Quill } from 'react-quill';
import SnowTooltip from './snow-tooltip';

const SnowTheme = Quill.import('themes/snow');
const icons = Quill.import('ui/icons');

class CustomSnowTheme extends SnowTheme {
  extendToolbar(toolbar: any) {
    toolbar.container.classList.add('ql-snow');
    this.buildButtons([...toolbar.container.querySelectorAll('button')], icons);
    this.buildPickers([...toolbar.container.querySelectorAll('select')], icons);
    this.tooltip = new SnowTooltip(this.quill, this.options.bounds);
    if (toolbar.container.querySelector('.ql-link')) {
      this.quill.keyboard.addBinding(
        { key: 'k', shortKey: true },
        (range: any, context: any) => {
          toolbar.handlers.link.call(toolbar, !context.format.link);
        },
      );
    }
  }
}

CustomSnowTheme.DEFAULTS.modules.toolbar.handlers.link = function link(
  value: boolean,
) {
  if (value) {
    const range = this.quill.getSelection();
    let preview = this.quill.getText(range);
    if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
      preview = `mailto:${preview}`;
    }
    const { tooltip } = this.quill.theme;
    tooltip.edit('link', preview);
  } else {
    this.quill.format('link', false);
  }
};

export default CustomSnowTheme;
