import { PostContent } from '../types';

let globalData: Record<string, unknown> = {};
let additionalTrackingEngines:
  | undefined
  | ((event: Record<string, unknown>) => void);

const socialEvent = {
  event: 'socialevent',
  event_category: 'Social',
  event_action: null,
  event_label: null,
  post_type: null,
};

const socialCreatePostEvent = {
  event: 'socialcreatepost',
  event_category: 'Social',
  event_action: null,
  event_label: null,
  location_in_app: null,
  attachments: null,
  images: null,
  hyperlinks: null,
  videos: null,
};

function pushEvent(event: Record<string, unknown>) {
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push(event);
  } else {
    // eslint-disable-next-line no-console
    console.log('No data layer', event);
  }
  additionalTrackingEngines?.(event);
}

function sendEvent(event: Record<string, unknown>) {
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = new Date(Date.now() - tzoffset)
    .toISOString()
    .slice(0, -1);
  const tempEvent: Record<string, unknown> = {
    ...event,
    transaction_local_dt: localISOTime,
  };
  Object.keys(globalData).forEach((key) => {
    if (
      !(globalData[key] == null && tempEvent[key] && tempEvent[key] != null)
    ) {
      tempEvent[key] = globalData[key];
    }
  });
  pushEvent(tempEvent);
}

function countSubstring(string: string, substring: string) {
  const result = string.match(new RegExp(substring, 'g'));
  return result ? result.length : 0;
}

const setAdditionalTrackingEngines = (
  updatedData?: (event: Record<string, unknown>) => void,
) => {
  additionalTrackingEngines = updatedData;
};

const setEventGlobalData = (updatedData: Record<string, unknown>) => {
  globalData = { ...updatedData };
};

type ItemType = 'New' | 'Reply' | 'Reply to Reply';
const sendEventInitiateNewItem = (type: ItemType) => {
  sendEvent({
    ...socialEvent,
    event_action: 'Initiate New Post',
    event_label: type,
  });
};

const sendEventCreateItem = (
  type: ItemType,
  isExternal: boolean,
  content: PostContent,
) => {
  sendEvent({
    ...socialCreatePostEvent,
    event_action: 'Create Post',
    event_label: type,
    location_in_app: isExternal ? 'Content Selector' : 'Social',
    attachments: content.attachments?.length || 0,
    images: countSubstring(content.html || '', 'data-analytics-event="image"'),
    videos: countSubstring(content.html || '', 'data-analytics-event="video"'),
    hyperlinks: countSubstring(content.html || '', '<a href="'),
  });
};

type EditorActionType =
  | 'Bold'
  | 'Italic'
  | 'Underline'
  | 'Gif'
  | 'Emoji'
  | 'Hyperlink'
  | 'Image'
  | 'Video'
  | 'Attachment'
  | 'Formula';
const sendEventSelectRichTextOption = (type: EditorActionType) => {
  sendEvent({
    ...socialEvent,
    event_action: 'Select Rich Text Option',
    event_label: type,
  });
};

export type PostActionType =
  | 'Save'
  | 'Unsave'
  | 'Edit Post'
  | 'Delete Post'
  | 'View Post in Content'
  | 'Report Post'
  | 'Vote Up'
  | 'Unvote Up'
  | 'Vote Down'
  | 'Unvote Down'
  | 'Show Replies';
const sendEventSelectPostAction = (
  type: PostActionType,
  itemType: ItemType = 'New',
) => {
  sendEvent({
    ...socialEvent,
    event_action: 'Select Post Action',
    event_label: type,
    post_type: itemType,
  });
};

export type SortPostsActionType = 'Book order' | 'Recent' | 'Rank';
const sendEventSortPosts = (type: SortPostsActionType) => {
  sendEvent({
    ...socialEvent,
    event_action: 'Sort Posts',
    event_label: type,
  });
};

export type FilterActionType = 'All' | 'Mine' | 'Saved Posts' | 'TOC';
const sendEventFilterPosts = (type: FilterActionType) => {
  sendEvent({
    ...socialEvent,
    event_action: 'Filter Posts',
    event_label: type,
  });
};

export type SortRepliesActionType = 'New' | 'Old' | 'Rank';
const sendEventSortReplies = (type: SortRepliesActionType) => {
  sendEvent({
    ...socialEvent,
    event_action: 'Sort Replies',
    event_label: type,
  });
};

export {
  setAdditionalTrackingEngines,
  setEventGlobalData,
  sendEventInitiateNewItem,
  sendEventCreateItem,
  sendEventSelectRichTextOption,
  sendEventSelectPostAction,
  sendEventSortPosts,
  sendEventFilterPosts,
  sendEventSortReplies,
};
