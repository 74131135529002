import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import cn from 'classnames';

import { TableLinkItemProps } from './TableLinksList.props';
import styles from './TableLinksList.module.scss';

const TableLinkItem: FC<TableLinkItemProps> = ({
  open = false,
  isSelected,
  name,
  level,
  onClick,
  onSelectItem,
}) => (
  <ListItem
    classes={{ root: styles.menuItem }}
    className={cn({
      [styles.isSelected]: isSelected,
    })}
    style={{ paddingLeft: `${level * 15}px` }}
    component="div"
  >
    {!!onClick && (
      <ListItemIcon className={styles.menuItemIconWrapper} onClick={onClick}>
        <IconButton
          className={styles.menuItemIcon}
          size="small"
          aria-label={`${name} subsections`}
          aria-expanded={open}
          data-testid="test-table-link-expand-btn"
        >
          {open ? <KeyboardArrowDown /> : <ChevronRight />}
        </IconButton>
      </ListItemIcon>
    )}

    <Button
      classes={{ label: styles.valueText }}
      className={cn({
        [styles.withoutIcon]: !onClick,
      })}
      onClick={onSelectItem}
    >
      {name}
    </Button>
  </ListItem>
);

export default TableLinkItem;
