import React, { createContext, FC, memo, useContext } from 'react';
import LiveRegion from 'live-region';

const polite = new LiveRegion({
  ariaLive: 'polite',
  role: 'log',
  ariaRelevant: 'all',
  ariaAtomic: 'true',
});

const assertive = new LiveRegion({
  ariaLive: 'assertive',
  role: 'log',
  ariaRelevant: 'all',
  ariaAtomic: 'true',
});

interface ILiveRegion {
  announce: (text: string, expire: number) => void;
  read: (text: string, expire?: number) => void;
}

export interface A11yLiveContextProps {
  polite: ILiveRegion;
  assertive: ILiveRegion;
}

function wrapTimeout(
  func: (msg: string, timeout?: number) => void,
): (msg: string, timeout?: number) => void {
  const REPEAT_TIMEOUT = 1000;
  let lastMsg: string = '';
  let lastTime = Date.now();

  return (msg: string, timeout = 5e3): void => {
    const now = Date.now();

    if (lastMsg !== msg || now - lastTime > REPEAT_TIMEOUT) {
      lastTime = now;
      lastMsg = msg;
      func(msg, timeout);
    }
  };
}

polite.read = wrapTimeout(polite.announce.bind(polite));
assertive.read = wrapTimeout(assertive.announce.bind(assertive));

const contextValue = {
  polite,
  assertive,
};

const A11yLiveContext = createContext<A11yLiveContextProps>(contextValue);

export const A11yLiveProvider: FC = memo(({ children }) => (
  <A11yLiveContext.Provider value={contextValue}>
    {children}
  </A11yLiveContext.Provider>
));

export const useA11yLiveContext = (): A11yLiveContextProps =>
  useContext<A11yLiveContextProps>(A11yLiveContext);
