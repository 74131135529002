import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as PearsonIcon } from '../../assets/images/pearson.svg';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { StyleProps } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  root: {
    background:
      'linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%)',
    padding: '4px',
    paddingRight: '6px',
    borderRadius: 25,
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  badge: {
    color: '#FEFEFE',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '100%',
  },
  tooltip: {
    backgroundColor: '#E8EEFA',
    color: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? theme.palette.secondaryText : theme.palette.primaryText,
    fontSize: 14,
    fontFamily: 'Open Sans, Hind',
  },
  arrow: {
    color: '#E8EEFA',
  },
}));

const TutorBadge: FC = () => {
  const { isCommentsMode } = useLayoutModeContext();
  const classes = useStyles({ isCommentsMode });
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      title="Pearson staff"
      placement="top"
      arrow
    >
      <div className={classes.root}>
        <PearsonIcon />
        <span className={classes.badge}>Tutor</span>
      </div>
    </Tooltip>
  );
};

export default TutorBadge;
