import React, { FC, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import TableLinkItem from './TableLinkItem';

import { TableLinkItemChildrenProps } from './TableLinksList.props';

const TableLinkItemChildren: FC<TableLinkItemChildrenProps> = ({
  open,
  isSelected,
  name,
  level,
  children,
  onSelectItem,
}) => {
  const [isOpened, setIsOpen] = useState(open);
  return (
    <>
      <TableLinkItem
        open={isOpened}
        isSelected={isSelected}
        level={level}
        name={name}
        onClick={() => setIsOpen(!isOpened)}
        onSelectItem={onSelectItem}
      />
      <Collapse in={isOpened} timeout="auto" unmountOnExit>
        <List component="div" disablePadding role="list">
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default TableLinkItemChildren;
