import { StyleProps } from '../../types';

export default (theme: any) => ({
  root: {
    margin: 0,
  },
  filterDivider: {
    margin: '0 8px 8px',
  },
  divider: {
    backgroundColor: theme.palette.primarySeparatorColor,
    margin: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? '24px 16px 18px' : '0 16px',
  },
  repliesInner: {
    backgroundColor: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 'transparent' : theme.palette.secondaryBackground,
    color: theme.palette.primaryText,
    border: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode
        ? 'none'
        : `1px solid ${theme.palette.primarySeparatorColor}`,
    margin: 0,
    borderRadius: 8,
    marginBottom: 20,
    marginLeft: ({ isCommentsMode }: StyleProps) => (isCommentsMode ? 30 : 40),
  },
  reply: {
    '&:last-child hr': {
      display: 'none',
    },
  },
  loadMore: {
    textAlign: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 'left' : 'center',
    color: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? '#12B2A6' : '#5384CD',
    cursor: 'pointer',
    backgroundColor: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? 'transparent' : theme.palette.secondaryBackground,
    borderRadius: 51,
    margin: ({ isCommentsMode }: StyleProps) =>
      isCommentsMode ? '0 5px 5px 40px' : 5,
    lineHeight: '24px',
    fontWeight: 400,
    verticalAlign: 'middle',
    padding: ({ isCommentsMode }: StyleProps) => (isCommentsMode ? 0 : '8px 0'),
  },
});
