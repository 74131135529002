import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { Icon } from '@greenville/mui-theme';
import { useId } from 'react-id-generator';
import { A11Y_ID_PREFIX } from '../../utils/a11y';

import { ActionsMenuPopupProps } from './ActionsMenuPopup.props';
import styles from './ActionsMenuPopup.module.scss';

const ITEM_HEIGHT = 48;
const ActionsMenuPopup: FC<ActionsMenuPopupProps> = ({
  anchorEl,
  onOpen,
  onClose,
  children,
}) => {
  const [htmlId] = useId(1, A11Y_ID_PREFIX);

  return (
    <div>
      <IconButton
        className={styles.threeDotsMenu}
        aria-label="Post actions"
        size="small"
        aria-controls={htmlId}
        aria-haspopup="true"
        onClick={onOpen}
      >
        <Icon name="MoreVert" className={styles.threeDotsMenu} />
      </IconButton>
      <Menu
        classes={{
          paper: styles.popover,
        }}
        id={htmlId}
        className={styles.popupActionsMenu}
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={onClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default ActionsMenuPopup;
