import cloneDeep from 'lodash/cloneDeep';
import { Link, TableOfLinks } from '../types';

export const MAX_LINK_ORDER_DEPTH = 2;

function arrayFromLink(link: Link): string[] {
  let arrayId = [link.id];
  if (link.subLink) {
    arrayId = [link.id, ...arrayFromLink(link.subLink)];
  }
  return arrayId;
}

export function getPathFromLink(link: Link) {
  const arrayLink = arrayFromLink(link);
  return arrayLink.map(encodeURIComponent).join('/');
}

function buildLinkFromArray(ids: string[]): Link | undefined {
  let link: Link | undefined;
  for (let i = ids.length - 1; i >= 0; i -= 1) {
    if (ids[i]) {
      const id = ids[i];
      if (link) {
        link = {
          id,
          subLink: link,
        };
      } else {
        link = { id };
      }
    }
  }
  return link;
}

export function getLinkFromPath(path: string): Link | undefined {
  const strings = path.split('/');
  return buildLinkFromArray(strings.map(decodeURIComponent));
}

export function extractLinksFromRoot(tableOfLinks: TableOfLinks[]) {
  const [rootItem] = tableOfLinks;

  return [
    {
      id: rootItem.id,
      name: 'No Chapter',
    },
    ...(rootItem?.subLinks ? rootItem.subLinks : []),
  ];
}

export function initValue(tableOfLinks: TableOfLinks[], link: Link) {
  const value = getPathFromLink(link);
  if (tableOfLinks?.length > 1) {
    return value;
  }

  const [rootItem] = tableOfLinks;
  if (value !== rootItem.id) {
    const [, ...ids] = value.split('/');
    return ids.join('/');
  }
  return value;
}

export function handleExtractedRootLink(
  tableOfLinks: TableOfLinks[],
  value: string,
) {
  if (tableOfLinks?.length > 1) {
    return getLinkFromPath(value);
  }

  const [rootItem] = tableOfLinks;
  const link = value === rootItem.id ? rootItem.id : `${rootItem.id}/${value}`;

  return getLinkFromPath(link);
}

export function getTopLevelLink(link: Link): Link {
  return { id: link.id };
}

export function getParentLevelLink(link: Link): Link | undefined {
  const copy = cloneDeep(link);
  let parent;
  if (copy.subLink) {
    parent = {
      id: copy.id,
      data: copy.data,
      subLink: getParentLevelLink(copy.subLink),
    };
  }
  return parent;
}

export function getFirstLevelLink(link: Link) {
  return {
    id: link.id,
    data: link.data,
  } as Link;
}

function firstPageLinkOfTableOfLink(tableOfLinks: TableOfLinks[]): string[] {
  const links = [];
  let path: TableOfLinks | undefined = tableOfLinks[0];
  while (path) {
    links.push(path.id);
    if (path.subLinks && path.subLinks.length > 0) {
      [path] = path.subLinks;
    } else {
      path = undefined;
    }
  }
  return links;
}

export function getPageLink(
  link: Link,
  tableOfLinks: TableOfLinks[],
  originalLink?: Link,
): Link | undefined {
  const lastLink = tableOfLinks.find(
    (tableOfLinksObj) => tableOfLinksObj.id === link.id,
  );
  if (lastLink) {
    if (link.subLink && lastLink.subLinks && lastLink.subLinks.length > 0) {
      return getPageLink(link.subLink, lastLink.subLinks, originalLink || link);
    }
    if (lastLink.subLinks && lastLink.subLinks.length > 0) {
      return buildLinkFromArray([
        ...arrayFromLink(originalLink || link),
        ...firstPageLinkOfTableOfLink(lastLink.subLinks),
      ]);
    }
    return originalLink;
  }
  return originalLink || link;
}

export function isFirstLevelLink(link: Link) {
  return !link.subLink;
}

export function normalizeLinkName(linkName: string, parentLinkName: string) {
  const re = /^(\d+(?:[\d.]+)?)/;

  if (!re.test(linkName) && parentLinkName) {
    const m = parentLinkName.match(re);

    if (!m) {
      return linkName;
    }

    return `${m[1]}: ${linkName}`;
  }

  return linkName;
}

export function findLinkNameByLink(
  linkObj: Link,
  linkFilters: TableOfLinks[],
  lastName = '',
): string {
  const lastLink = linkFilters.find(
    (linkFilterObj) => linkFilterObj.id === linkObj.id,
  );
  if (lastLink) {
    if (linkObj.subLink && lastLink.subLinks) {
      return findLinkNameByLink(
        linkObj.subLink,
        lastLink.subLinks,
        lastLink.name,
      );
    }
    return normalizeLinkName(lastLink.name, lastName);
  }
  return lastName;
}

export function linkIncludesLink(firstLink: Link, secondLink: Link): boolean {
  if (firstLink.id === secondLink.id) {
    if (firstLink.subLink) {
      if (secondLink.subLink) {
        return linkIncludesLink(firstLink.subLink, secondLink.subLink);
      }
      return false;
    }
    return true;
  }
  return false;
}

export function isSameLink(firstLink: Link, secondLink: Link): boolean {
  if (firstLink.id === secondLink.id) {
    if (
      (firstLink.subLink && !secondLink.subLink) ||
      (!firstLink.subLink && secondLink.subLink)
    ) {
      return false;
    }
    if (firstLink.subLink && secondLink.subLink) {
      return isSameLink(firstLink.subLink, secondLink.subLink);
    }
    return true;
  }
  return false;
}

export function flattenTableOfLinks(
  tableOfLinks: TableOfLinks[],
  maxDepth = Infinity,
): TableOfLinks[] {
  return tableOfLinks.flatMap(({ subLinks, ...link }) =>
    subLinks && maxDepth > 1
      ? [link, ...flattenTableOfLinks(subLinks, maxDepth - 1)]
      : [link],
  );
}

function setLinkOrderRecursive(link: Link, tableOfLinks: TableOfLinks[]): Link {
  const order = tableOfLinks.findIndex(({ id }) => link.id === id);

  return {
    ...link,
    order: order >= 0 ? order : undefined,
    subLink: link.subLink
      ? setLinkOrderRecursive(link.subLink, tableOfLinks)
      : undefined,
  };
}

export function setLinkOrder(link: Link, tableOfLinks: TableOfLinks[]) {
  const flatTableOfLinks = flattenTableOfLinks(tableOfLinks);
  return setLinkOrderRecursive(link, flatTableOfLinks);
}

function getLinkOrderRecursive(
  link: Link,
  maxDepth: number,
): number | undefined {
  let order =
    link.subLink && maxDepth > 1
      ? getLinkOrderRecursive(link.subLink, maxDepth - 1)
      : link.order;

  if (!(order != null && order >= 0)) {
    order = link.order;
  }

  return order;
}

export function getLinkOrder(link: Link, maxDepth = Infinity): number {
  return getLinkOrderRecursive(link, maxDepth) || 0;
}

export function getRandomId() {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function clampStringLength(text: string, maxLength: number) {
  const trimmed = text.trim();

  if (trimmed.length > maxLength) {
    return trimmed.substr(0, maxLength);
  }

  return text;
}
