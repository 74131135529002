import React, { FC } from 'react';
import ReportProblemRounded from '@material-ui/icons/ReportProblemRounded';
import cn from 'classnames';

import styles from './FormFieldHint.module.scss';
import { FormFieldHintProps } from './FormFieldHint.props';
import { useA11yLiveContext } from '../../providers/a11yLiveContext';

const FormFieldHint: FC<FormFieldHintProps> = ({
  className,
  hasError,
  hint,
  secondaryHint,
}) => {
  const { polite: livePolite } = useA11yLiveContext();

  if (hasError) {
    livePolite.read(hint || 'Error in field.');
  }

  return (
    <div
      className={cn(
        styles.inputHint,
        { [styles.isError]: hasError },
        className,
      )}
    >
      <div className={styles.primaryHint}>
        {hasError && <ReportProblemRounded />}

        <span className={styles.label}>{hint}</span>
      </div>
      <div className={styles.secondaryHint}>{secondaryHint}</div>
    </div>
  );
};

export default FormFieldHint;
