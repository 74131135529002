import React, { FC, useRef } from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import { useId } from 'react-id-generator';
import CardHeader from '../CardHeader';
import CardContent from '../CardContent';
import PostCardActions from './PostCardActions';
import PostActionsMenuPopup from './PostActionsMenuPopup';

import { isPostRecentlyEdited } from '../../utils/postUtils';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { A11Y_ID_PREFIX } from '../../utils/a11y';

import { IPost } from '../../types';
import { PostCardProps } from './PostCard.props';

import styles from './styles';

const useStyles = makeStyles(styles);

const PostCard: FC<PostCardProps> = ({
  HTMLIds,
  className,
  post,
  userId,
  tableOfLinks,
  linkClickCallback,
  onDeletePost,
  onFavorite,
  onReportPost,
  onEditPost,
  onThumbUp,
  onReply,
  onShowReplies,
  onLoginRequire,
  isUserAuthorized = false,
  questionContentClickHandler,
}) => {
  const postRef = useRef(null);
  const [postHeaderId, userNameId, postContentId, quoteId] = useId(
    4,
    A11Y_ID_PREFIX,
  );

  const { isCommentsMode } = useLayoutModeContext();
  const classes = useStyles({ isCommentsMode });

  return (
    <Card
      className={cn(classes.root, className, {
        [classes.commentsMode]: isCommentsMode,
      })}
      ref={postRef}
      component="article"
      aria-owns={HTMLIds.repliesRegionId}
      aria-labelledby={isCommentsMode ? userNameId : postHeaderId}
      aria-describedby={[quoteId, postContentId].join(' ')}
    >
      <CardHeader
        userNameHtmlId={userNameId}
        user={post.user}
        createdAt={post.createdAt}
        underReview={post.reviewStatus === 'needs_review' && !isCommentsMode}
        edited={isPostRecentlyEdited(post.createdAt, post.updatedAt)}
        actions={
          isUserAuthorized && (
            <PostActionsMenuPopup
              post={post}
              onDelete={onDeletePost}
              onFavorite={onFavorite}
              onViewInContent={linkClickCallback}
              onReport={onReportPost}
              onEdit={onEditPost}
              isOwner={post && post.userId === userId}
              tableOfLinks={tableOfLinks}
            />
          )
        }
      />
      <CardContent
        context={post}
        tableOfLinks={tableOfLinks}
        linkClickCallback={linkClickCallback}
        questionContentClickHandler={
          questionContentClickHandler
            ? (context) => questionContentClickHandler(context as IPost)
            : undefined
        }
        postRef={postRef}
        postContentId={postContentId}
        headerHtmlId={postHeaderId}
        quoteId={quoteId}
      />
      {!isCommentsMode && (
        <Divider role="presentation" className={classes.divider} />
      )}
      <PostCardActions
        HTMLIds={HTMLIds}
        disableSpacing
        post={post}
        onThumbUp={onThumbUp}
        onReply={onReply}
        onShowReplies={onShowReplies}
        onLoginRequire={onLoginRequire}
        isUserAuthorized={isUserAuthorized}
        postHeaderHtmlId={postHeaderId}
        userNameHtmlId={userNameId}
      />
    </Card>
  );
};

export default PostCard;
