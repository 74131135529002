export default function convertYoutubeLink(quill: any) {
  quill.clipboard.addMatcher(Node.TEXT_NODE, (node: any, delta: any): any => {
    if (typeof node.data !== 'string') return null;

    const regex =
      /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/g;
    const matches = node.data.match(regex);

    if (matches && matches.length > 0) {
      const ops = [];
      let str = node.data;

      matches.forEach((match: RegExp) => {
        const split = str.split(match);
        const beforeLink = split.shift();

        ops.push({ insert: beforeLink });
        ops.push({ insert: { video: match }, attributes: { link: match } });
        str = split.join(match);
      });

      ops.push({ insert: str });
      // eslint-disable-next-line no-param-reassign
      delta.ops = ops;
    }

    return delta;
  });
}
