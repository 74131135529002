import { Quill } from 'react-quill';

const Image = Quill.import('formats/image');
export default class CustomImage extends Image {
  static tagName = 'img';

  static create(url: string): HTMLElement {
    const node = super.create(url);
    node.setAttribute('data-analytics-event', 'image');
    node.setAttribute('class', 'ql-inline-image');
    return node;
  }
}
