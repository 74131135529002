import React, { FC, memo } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { LoaderProps } from './Loader.props';
import styles from './Loader.module.scss';

const Loader: FC<LoaderProps> = memo(({ isLoading, children }) => (
  <div className={styles.infiniteLoader}>
    {children}
    {isLoading && (
      <div className={styles.loading}>
        <CircularProgress className={styles.loader} color="secondary" />
      </div>
    )}
  </div>
));

export default Loader;
