import React, { FC } from 'react';
import { NoPostsProps } from './NoPosts.props';
import styles from './NoPosts.module.scss';
import PrimaryButton from '../PrimaryButton';
import { getImageUrl } from '../../utils/cloudfront';
import { useCommonDataContext } from '../../providers/commonDataContext';

const NoPosts: FC<NoPostsProps> = ({ isFiltered, action }) => {
  const { env } = useCommonDataContext();
  const imageToUse = isFiltered ? 'no_post_filter.png' : 'be_the_first.png';

  return (
    <div className={styles.noPosts}>
      <div className={styles.labelBlock}>
        <h3 className={styles.label}>
          {isFiltered ? 'No posts' : 'Be the first'}
        </h3>
        <p className={styles.text}>
          {isFiltered
            ? 'Adjust your filters to find more posts'
            : 'Create a post to get feedback from others who are studying the same material'}
        </p>
      </div>

      {action && (
        <PrimaryButton className={styles.action} onClick={action}>
          {isFiltered ? 'Clear filters' : 'New Post'}
        </PrimaryButton>
      )}
      <div className={styles.background}>
        <img
          className={styles.image}
          src={getImageUrl(env, imageToUse)}
          alt={
            isFiltered
              ? 'Adjust your filters to find more posts'
              : 'Create a post to get feedback from others who are studying the same material'
          }
        />
      </div>
      <div className={styles.gradient} />
    </div>
  );
};

export default NoPosts;
