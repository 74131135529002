import React, { forwardRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

import { ActionMenuItemProps } from './ActionsMenuPopup.props';
import styles from './ActionsMenuPopup.module.scss';

const ActionMenuItem = forwardRef<HTMLLIElement, ActionMenuItemProps>(
  (props, ref) => (
    // @ts-ignore
    <MenuItem innerRef={ref} className={styles.popupActionItem} {...props} />
  ),
);

export default ActionMenuItem;
