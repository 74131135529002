import React, {
  ReactEventHandler,
  useCallback,
  useEffect,
  forwardRef,
} from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';
import { postVote, getPostReplies, deletePost } from '../../services/SocialAPI';

import NewReply from '../NewReply/NewReply';
import Reply from '../Reply';
import ReplyDeleteModal from '../ReplyDeleteModal';

import { IPost, VoteValue, StyleProps } from '../../types';
import { RepliesProps } from './Replies.props';
import GenericErrorModal from '../GenericErrorModal';

import {
  sendEventInitiateNewItem,
  sendEventSelectPostAction,
  PostActionType,
} from '../../utils/events';

import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { useCommonDataContext } from '../../providers/commonDataContext';
import { useModal } from '../../providers/modalContext';

import styles from './styles';

const useStyles = makeStyles<any, StyleProps>(styles);

const Replies = forwardRef<HTMLDivElement, RepliesProps>(
  (
    { postId, postReplies, updatePost, repliesOrder, initialLoadCount },
    ref,
  ) => {
    const { env, tenantId, token, userId } = useCommonDataContext();

    const { isCommentsMode } = useLayoutModeContext();
    const classes = useStyles({ isCommentsMode });
    const { openModal } = useModal();

    const showError = (message: string) => {
      const closeModal = openModal(GenericErrorModal, {
        message,
        onClose: () => {
          closeModal();
        },
      });
    };

    const fetchPostReplies = useCallback(
      (count?: number, nextPageCursor = postReplies?.nextPageCursor) => {
        getPostReplies({
          tenantId,
          postId,
          env,
          sort: repliesOrder,
          pageSize: count,
          cursor: nextPageCursor,
          token,
        })
          .then((replies) => {
            updatePost((prevState) => {
              const modifiedPost = { ...prevState };
              modifiedPost.repliesCursorPage = replies.nextPageCursor
                ? replies.nextPageCursor
                : null;
              modifiedPost.replies = uniqBy(
                (modifiedPost?.replies || []).concat(replies.posts),
                'id',
              );
              modifiedPost.repliesTotalCount = replies.totalCount;

              return modifiedPost;
            });
          })
          .catch(() => {
            showError('Error occured while loading replies');
          });
      },
      [repliesOrder, postId, postReplies?.nextPageCursor],
    );

    useEffect(() => {
      const length = postReplies?.posts?.length || 0;

      if (length === 0) {
        fetchPostReplies(initialLoadCount);
      }
    }, [repliesOrder]);

    useEffect(() => {
      if (
        postReplies?.totalCount &&
        isCommentsMode &&
        !postReplies?.posts.length
      ) {
        fetchPostReplies(1, null);
      }
    }, [postReplies?.posts?.length]);

    const onLoadMoreReplies: ReactEventHandler = (e) => {
      e.preventDefault();
      fetchPostReplies();
    };

    const onReplyCreate = (cReply: IPost) => {
      updatePost((prevState) => {
        const modifiedPost = { ...prevState };

        const replies = prevState.replies?.slice() || [];
        if (repliesOrder === 'recent') {
          replies.unshift(cReply);
        } else {
          replies.push(cReply);
        }
        modifiedPost.replies = replies;

        modifiedPost.replyCount += 1;
        return modifiedPost;
      });

      if (typeof ref === 'object') {
        ref?.current?.scrollIntoView({
          block: repliesOrder === 'recent' ? 'start' : 'end',
        });
      }
    };

    const onReplyUpdate = (cReply: IPost) => {
      updatePost((prevState) => ({
        ...prevState,
        replies: (prevState.replies || []).map((reply) =>
          reply.id === cReply.id ? cReply : reply,
        ),
      }));

      const hasMentions =
        cReply?.content?.mentions && cReply.content.mentions.length > 0;
      sendEventSelectPostAction(
        'Edit Post',
        hasMentions ? 'Reply to Reply' : 'Reply',
      );
    };

    const editReplyClickHandler = (reply: IPost) => {
      const closeModal = openModal(NewReply, {
        postId,
        reply,
        dialog: true,
        isReplyToReply: true,
        onCancelCallback: () => {
          closeModal();
        },
        onSubmitCallback: (undatedReply: IPost) => {
          if (reply) {
            onReplyUpdate(undatedReply);
          } else {
            onReplyCreate(undatedReply);
          }

          closeModal();
        },
      });
    };

    const onThumbUpReply = (item: IPost, voteAction: VoteValue) => {
      if (!userId) return;
      const voteValue =
        !item.votes ||
        item.votes.length === 0 ||
        item.votes[0].vote !== voteAction
          ? voteAction
          : 0;
      let voteActionEvent: PostActionType = 'Vote Up';
      if (voteValue === 0) {
        if (voteAction === -1) {
          voteActionEvent = 'Unvote Down';
        } else if (voteAction === 1) {
          voteActionEvent = 'Unvote Up';
        }
      } else if (voteValue === -1) {
        voteActionEvent = 'Vote Down';
      }

      postVote(token, item.id, env, voteValue)
        .then((vote) => {
          const hasMentions =
            item?.content?.mentions && item.content.mentions.length > 0;
          sendEventSelectPostAction(
            voteActionEvent,
            hasMentions ? 'Reply to Reply' : 'Reply',
          );

          updatePost((prevState) => {
            const modifiedPost = { ...prevState };
            const replyIndex = modifiedPost.replies!.findIndex(
              (reply) => reply.id === item.id,
            );
            Object.assign(modifiedPost.replies![replyIndex], vote);
            return modifiedPost;
          });
        })
        .catch(() => showError('Error occured while posting your vote'));
    };

    const confirmDelete = async (replyId: number) => {
      if (!userId) return;

      try {
        await deletePost(token, replyId, env, tenantId);

        updatePost((prevState) => {
          const modifiedPost = { ...prevState };

          const deletedReply = modifiedPost.replies!.find(
            (reply) => reply.id === replyId,
          );
          const hasMentions =
            deletedReply &&
            deletedReply?.content?.mentions &&
            deletedReply.content.mentions.length > 0;
          sendEventSelectPostAction(
            'Delete Post',
            hasMentions ? 'Reply to Reply' : 'Reply',
          );

          modifiedPost.replies = modifiedPost.replies!.filter(
            (reply) => reply.id !== replyId,
          );
          modifiedPost.replyCount -= 1;
          return modifiedPost;
        });
      } catch (err: any) {
        showError('Error occured while deleting your reply');
      }
    };

    const onDeleteReply = (replyId: number) => {
      if (!userId) return;

      const closeModal = openModal(ReplyDeleteModal, {
        replyId,
        onCancel: () => {
          closeModal();
        },
        onDelete: () => {
          confirmDelete(replyId).finally(closeModal);
        },
      });
    };

    const replyPostReplyHandler = (reply: IPost) => {
      sendEventInitiateNewItem('Reply to Reply');

      const closeModal = openModal(NewReply, {
        postId,
        context: reply,
        mentionedUser: reply.user
          ? {
              userId: reply.userId,
              ...reply.user,
            }
          : undefined,
        dialog: true,
        isReplyToReply: true,
        onCancelCallback: () => {
          closeModal();
        },
        onSubmitCallback: (undatedReply: IPost) => {
          onReplyCreate(undatedReply);
          closeModal();
        },
      });
    };

    const mentionClickHandler: ReactEventHandler = (e) => {
      let el: HTMLElement | null = e.target as HTMLElement;
      while (el && el.tagName !== 'span' && el.className !== 'mention') {
        el = el.parentNode as HTMLElement | null;
      }
      // if (el && el.tagName?.toLowerCase() === 'span' && el.className?.toLowerCase() === 'mention') {
      //   setMentionUser(el.dataset.id);
      //   setUserPopupAnchorEl(el);
      // }
    };

    const isShowingReplies = postReplies && postReplies.posts.length > 0;

    return (
      <div ref={ref} className={classes.root}>
        {isShowingReplies && (
          <>
            <div className={classes.repliesInner}>
              {postReplies.posts.map((reply) => (
                <div key={reply.id} className={classes.reply}>
                  <Reply
                    key={reply.id}
                    reply={reply}
                    onDeleteReply={onDeleteReply}
                    editReplyClickHandler={editReplyClickHandler}
                    mentionClickHandler={mentionClickHandler}
                    onThumbUpReply={onThumbUpReply}
                    replyPostReplyHandler={replyPostReplyHandler}
                    inlineCreateUpdateReply={(newReply) => {
                      if (newReply.id === reply.id) {
                        onReplyUpdate(newReply);
                      } else {
                        onReplyCreate(newReply);
                      }
                    }}
                  />
                  <Divider className={classes.divider} role="presentation" />
                </div>
              ))}
            </div>
            {postReplies.nextPageCursor !== null && (
              <div
                role="button"
                tabIndex={0}
                className={classes.loadMore}
                onClick={onLoadMoreReplies}
                onKeyDown={onLoadMoreReplies}
              >
                {isCommentsMode
                  ? `Show other replies (${
                      postReplies.totalCount - postReplies.posts.length
                    })`
                  : 'Show more replies'}
              </div>
            )}
          </>
        )}
      </div>
    );
  },
);

export default Replies;
