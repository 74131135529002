import React, { FC, createContext, useContext, memo } from 'react';
import { LayoutMode } from '../types';

const LayoutModeContext = createContext<LayoutMode>('default');

interface LayoutModeProviderProps {
  children: React.ReactNode;
  layoutMode: LayoutMode;
}

export const LayoutModeProvider: FC<LayoutModeProviderProps> = memo(
  ({ children, layoutMode }) => (
    <LayoutModeContext.Provider value={layoutMode}>
      {children}
    </LayoutModeContext.Provider>
  ),
);

export const useLayoutModeContext = () => {
  const layoutMode = useContext(LayoutModeContext);

  return {
    layoutMode,
    isCommentsMode: layoutMode === 'comments',
  };
};
