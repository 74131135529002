import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DialogModal from '../DialogModal';

import { PostDeleteModalProps } from './PostDeleteModal.props';
import styles from './PostDeleteModal.module.scss';

const PostDeleteModal: FC<PostDeleteModalProps> = ({
  open = true,
  onCancel,
  onDelete,
}) => (
  <DialogModal
    className={styles.deletePostModal}
    isFullScreen={false}
    open={open}
    onCancelCallback={onCancel}
    actions={<Button onClick={onDelete}>Delete</Button>}
  >
    <Typography className={styles.question}>
      Delete post permanently?
    </Typography>

    <Typography className={styles.clarification}>
      If you&apos;re sure, we&apos;re sure.
    </Typography>
  </DialogModal>
);

export default PostDeleteModal;
