import React, { FC, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useId } from 'react-id-generator';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { ReactComponent as ArrowGreenLine } from '../../assets/images/arrow-green-line.svg';
import { ReactComponent as ArrowBlueLine } from '../../assets/images/arrow-blue-line.svg';
import { ReactComponent as ArrowOutlined } from '../../assets/images/arrow-outlined.svg';
import { ReactComponent as CommentOutlined } from '../../assets/images/comment-outlined.svg';

import CardActions, { CardAction } from '../CardActions';
import { PostCardActionsProps } from './PostCard.props';

import { A11Y_ID_PREFIX } from '../../utils/a11y';
import { useLayoutModeContext } from '../../providers/layoutModeContext';
import { VoteValue } from '../../types';

import styles from './styles';
import SrOnlyText from '../A11yRelatedLib/SrOnlyText';

const useStyles = makeStyles(styles);

const PostCardActions: FC<PostCardActionsProps> = ({
  HTMLIds,
  post,
  onThumbUp,
  onReply,
  onShowReplies,
  onLoginRequire,
  isUserAuthorized,
  disableSpacing,
  postHeaderHtmlId,
  userNameHtmlId,
}) => {
  const [repliesIsShow, setRepliesIsShow] = useState(false);
  const [upVoteHtmlId, downVoteHtmlId] = useId(2, A11Y_ID_PREFIX);

  const { isCommentsMode } = useLayoutModeContext();

  const classes = useStyles({ isCommentsMode });

  const onRepliesBtnClick = () => {
    onShowReplies?.(post);
    setRepliesIsShow(!repliesIsShow);
  };

  const handleClick = (vote: VoteValue) =>
    isUserAuthorized
      ? onThumbUp(post, vote)
      : onLoginRequire && onLoginRequire();
  return (
    <CardActions
      onReply={onReply}
      disableSpacing={disableSpacing}
      className={classes.postActions}
      postCardActions
    >
      {post.replyCount > 0 && !isCommentsMode && (
        <CardAction amount={post.replyCount}>
          <IconButton
            size="small"
            onClick={onRepliesBtnClick}
            title="Show replies"
            aria-expanded={repliesIsShow}
            aria-controls={HTMLIds.repliesRegionId}
            style={{ paddingTop: '5px' }}
          >
            <CommentOutlined
              className={classes.icon}
              aria-hidden="true"
              focusable="false"
            />
          </IconButton>
        </CardAction>
      )}
      <CardAction
        amount={post.score > 0 ? post.score : 0}
        preChildren={
          <>
            <SrOnlyText id={upVoteHtmlId}>Up Vote for</SrOnlyText>
            <IconButton
              size="small"
              aria-labelledby={`${upVoteHtmlId} ${
                isCommentsMode ? userNameHtmlId : postHeaderHtmlId
              }`}
              aria-pressed={post.votes && post.votes[0]?.vote === 1}
              onClick={() => handleClick(1)}
              className={classes.iconButton}
            >
              {post.votes && post.votes[0]?.vote === 1 ? (
                <>
                  {isCommentsMode ? (
                    <ArrowGreenLine />
                  ) : (
                    <Arrow className={classes.icon} />
                  )}
                </>
              ) : (
                <ArrowOutlined
                  className={classes.iconUp}
                  aria-hidden="true"
                  focusable="false"
                />
              )}
            </IconButton>
          </>
        }
      >
        <SrOnlyText id={downVoteHtmlId}>Down Vote for</SrOnlyText>
        <IconButton
          size="small"
          aria-labelledby={`${downVoteHtmlId} ${
            isCommentsMode ? userNameHtmlId : postHeaderHtmlId
          }`}
          aria-pressed={post.votes && post.votes[0]?.vote === -1}
          onClick={() => handleClick(-1)}
          style={{
            transform: 'rotateX(180deg)',
            marginRight: '4px',
            paddingTop: '1px',
          }}
          className={classes.iconButton}
        >
          {post.votes && post.votes[0]?.vote === -1 ? (
            <>
              {isCommentsMode ? (
                <ArrowBlueLine />
              ) : (
                <Arrow className={classes.icon} />
              )}
            </>
          ) : (
            <ArrowOutlined
              className={classes.iconDown}
              aria-hidden="true"
              focusable="false"
            />
          )}
        </IconButton>
      </CardAction>
    </CardActions>
  );
};

export default PostCardActions;
