import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';

import { FormFieldLabelProps } from './FormFieldLable.props';
import styles from './FormFieldLabel.module.scss';

const FormFieldLabel: FC<FormFieldLabelProps> = ({
  htmlFor,
  label,
  required,
  hint,
  error,
}) => (
  <div className={cn(styles.formFieldLabel, { [styles.error]: error })}>
    <Typography
      variant="h5"
      className={styles.label}
      component="label"
      htmlFor={htmlFor}
    >
      {label}
      {required && '*'}
    </Typography>

    {hint ? (
      <Typography variant="h6" className={styles.hint}>
        {hint}
      </Typography>
    ) : null}
  </div>
);

export default FormFieldLabel;
