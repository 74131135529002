export default {
  bookId: '30b18904-dcc1-4f0f-bf0c-5cc91fa98e8b',
  chapterId: 'a9718d38f4ac9092562911f831bc57b0acced4cc8',
  pageId: 'a1d342a3f8f72dcf3fef30ee3e242d25af77be4eb',
  tableOfLinks: [{
    id: '30b18904-dcc1-4f0f-bf0c-5cc91fa98e8b',
    name: 'All Posts',
    subLinks: [{
      id: 'abdec1c1b516811a2d2a1bcf35a3da249ba8dbb6b',
      name: '1: Biology: The Study of Life',
      subLinks: [{
        id: 'abdec1c1b516811a2d2a1bcf35a3da249ba8dbb6b-id_toc16',
        name: 'Introduction: Biology: The Study of Life'
      },
      {
        id: 'a8f2193a7e1f770645b2abc46b72782dd14fdda29',
        name: '1.1 What Does It Mean to Say That Something Is Alive?'
      },
      {
        id: 'ae5d3d68f9e60697a7d155bd9848d48e56feae50c',
        name: '1.2 Life Is Cellular and Replicates through Cell Division'
      },
      {
        id: 'a5387e88cad25e974fc2adda99daf8e85a45dc014',
        name: '1.3 Life Processes Information and Requires Energy'
      }]
    },
    {
      id: 'a9718d38f4ac9092562911f831bc57b0acced4cc8',
      name: '2: Water and Carbon: The Chemical Basis of Life',
      subLinks: [{
        id: 'a9718d38f4ac9092562911f831bc57b0acced4cc8-id_toc53',
        name: 'Introduction: Water and Carbon: The Chemical Basis of Life'
      },
      {
        id: 'a1d342a3f8f72dcf3fef30ee3e242d25af77be4eb',
        name: '2.1: Atoms, Ions, and Molecules: The Building Blocks of Chemical Evolution'
      },
      {
        id: 'ace8772e371ceecab9079080e847e45c27a53df13',
        name: '2.2: Properties of Water and the Early Oceans'
      }]
    },
    {
      id: 'abe1c1aa083f2e0a813e03adaa5fe1e8a53fe5f0b',
      name: '3: Protein Structure and Function',
      subLinks: [{
        id: 'abe1c1aa083f2e0a813e03adaa5fe1e8a53fe5f0b-id_toc63',
        name: 'Introduction: Protein Structure and Function'
      }]
    }]
  }]
};
