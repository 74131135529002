export const SELECTION_CHANGE = 'selection-change';
export const USER = 'user';

export class Range {
  index: number;

  length: number;

  constructor(index: number, length = 0) {
    this.index = index;
    this.length = length;
  }
}
