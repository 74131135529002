import React, { FC } from 'react';
import Paper, { type PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import cn from 'classnames';
import styles from './DialogModal.module.scss';
import useWindowSize from '../../hooks/useWindowSize';

export const DRAGGABLE_ELEMENT = styles.draggableDialogElement;

const PaperDraggableComponent: FC<PaperProps> = (props: PaperProps) => {
  const { isWidthLess1050 } = useWindowSize();
  const dragProps = isWidthLess1050 ? { position: { x: 0, y: 0 } } : {};

  return (
    <Draggable handle={`.${DRAGGABLE_ELEMENT}`} {...dragProps}>
      <Paper {...props} className={cn(styles.paper, props.className)} />
    </Draggable>
  );
};

export default PaperDraggableComponent;
