import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import cn from 'classnames';

import { PrimaryButtonProps } from './PrimaryButton.props';
import styles from './PrimaryButton.module.scss';

const PrimaryButton: FC<PrimaryButtonProps> = ({
  className,
  children,
  onClick,
  type,
  disabled,
}) => (
  <Button
    classes={{ label: styles.label }}
    className={cn(styles.button, className)}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {children || 'Submit'}
  </Button>
);

export default PrimaryButton;
