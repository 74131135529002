import React, { FC } from 'react';
import MuiCardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useId } from 'react-id-generator';
import cn from 'classnames';

import SrOnlyText from '../A11yRelatedLib/SrOnlyText';
import { A11Y_ID_PREFIX } from '../../utils/a11y';

import { CardActionsProps } from './CardActions.props';
import { useLayoutModeContext } from '../../providers/layoutModeContext';

import styles from './styles';

const useStyles = makeStyles(styles);

const CardActions: FC<CardActionsProps> = ({
  className,
  classes: customClasses,
  children,
  onReply,
  disableSpacing,
  postCardActions,
}) => {
  const [id1] = useId(1, A11Y_ID_PREFIX);
  const { isCommentsMode } = useLayoutModeContext();
  const classes = useStyles({ isCommentsMode, postCardActions });

  return (
    <footer
      aria-label="Post actions."
      className={cn(classes.cardActions, className)}
    >
      <MuiCardActions
        classes={{
          root: classes.root,
          ...customClasses,
        }}
        disableSpacing={disableSpacing}
      >
        <SrOnlyText id={id1}>Post you reply to this comment.</SrOnlyText>
        <Button
          variant="text"
          onClick={onReply}
          classes={{
            root: classes.buttonStyle,
            label: classes.buttonStyle,
          }}
          aria-describedby={id1}
        >
          <Typography className={classes.reply} variant="inherit">
            Reply
          </Typography>
        </Button>

        <div className={classes.actions}>{children}</div>
      </MuiCardActions>
    </footer>
  );
};

export default CardActions;
