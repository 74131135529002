import React, { FC, useState, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@greenville/mui-theme';
import { getPathFromLink, getLinkFromPath } from '../../utils/linkHelpers';

import {
  sendEventSortPosts,
  sendEventFilterPosts,
  SortPostsActionType,
  FilterActionType,
} from '../../utils/events';

import SortDropdown from './SortDropdown';
import FilterDropdown from './FilterDropdown';
import TableLinksList from '../TableLinksList';

import { FiltersProps, FiltersValue } from './Filters.props';
import styles from './Filters.module.scss';
import { ReactComponent as AddIcon } from '../../assets/images/add-icon.svg';

const Filters: FC<FiltersProps> = ({
  userId,
  filtersValue,
  tableOfLinks,
  onFiltersUpdateCallback,
  onAddPost,
  hideAddBtn,
  contentFilterDisable,
}) => {
  const [filters, setFilters] = useState<FiltersValue>(filtersValue);

  useEffect(() => {
    setFilters(filtersValue);
  }, [filtersValue]);

  const updateFilters = (newFilterState: FiltersValue) => {
    onFiltersUpdateCallback(newFilterState);
    setFilters(newFilterState);
  };

  const setLinkByFilter = (value: string) => {
    updateFilters({ ...filters, link: getLinkFromPath(value)! });
    sendEventFilterPosts('TOC');
  };

  const onSort = (sortType: string, eventType: SortPostsActionType) => {
    updateFilters({ ...filters, sort: sortType });
    sendEventSortPosts(eventType);
  };

  const onFilter = (sortType: string, eventType: FilterActionType) => {
    updateFilters({ ...filters, filter: sortType });
    sendEventFilterPosts(eventType);
  };

  return (
    <div className={styles.postsFilter}>
      {tableOfLinks && !contentFilterDisable && (
        <TableLinksList
          className={styles.dropdown}
          tableOfLinks={tableOfLinks}
          value={getPathFromLink(filters.link)}
          onChange={setLinkByFilter}
        />
      )}

      <SortDropdown
        onSort={onSort}
        value={filters.sort}
        hasTableLinks={!!tableOfLinks?.length}
      />
      <FilterDropdown
        onFilter={onFilter}
        value={filters.filter}
        hasUserId={!!userId}
      />

      {!hideAddBtn && (
        <Tooltip title="Create new post">
          <IconButton
            aria-label="add-post"
            id="add-post"
            onClick={onAddPost}
            className={styles.filterSortIcon}
          >
            <AddIcon className={styles.icon} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Filters;
