import Mathml2latex from 'mathml2latex';
import katex from 'katex';
import { decode as decodeHtml, encode as encodeHtml } from 'html-entities';
import uniqBy from 'lodash/uniqBy';
import { PostContent } from '../types';

export const QLFORMULA = 'ql-formula';
export const YOUTUBETAG = 'youtube';
const YOUTUBESELECTOR = 'ql-video';

export function convertMathMLToFormula(mathml: string): string {
  const latex = Mathml2latex.convert(mathml);
  const formulaNode = document.createElement('span');

  katex.render(latex, formulaNode, {
    throwOnError: false,
    errorColor: '#f00',
  });

  return formulaNode.innerHTML;
}

const transformMarkupBySelector = (
  getNodes: (container: HTMLElement) => HTMLCollectionOf<Element>,
  nodeTransformer: (formula: Element) => void,
  content: PostContent,
) => {
  const container = document.createElement('div');
  container.innerHTML = content.html || '';

  [...getNodes(container)].forEach(nodeTransformer);

  return {
    ...content,
    html: container.innerHTML,
  };
};

const convertFormulasToCommonFormat = (content: PostContent): PostContent =>
  transformMarkupBySelector(
    (container) => container.getElementsByClassName(QLFORMULA),
    (formula) => {
      const formulaNode = document.createElement(QLFORMULA);
      formulaNode.classList.add(QLFORMULA);
      formulaNode.innerText = (formula as HTMLElement).dataset.value || '';
      formula.replaceWith(formulaNode);
    },
    content,
  );

const convertCommonToKatexFormat = (content: PostContent): PostContent =>
  transformMarkupBySelector(
    (container) => container.getElementsByTagName(QLFORMULA),
    (formula) => {
      const formulaNode = document.createElement('span');
      formulaNode.classList.add(QLFORMULA);
      katex.render(formula.textContent as string, formulaNode, {
        throwOnError: false,
        errorColor: '#f00',
      });

      formula.replaceWith(formulaNode);
    },
    content,
  );

export const getYoutubeUrls = (url: string) => {
  const videoId = url.match(/(?:watch\?v=|embed\/)([a-z0-9_]+)/i)?.[1];

  return videoId
    ? {
        videoUrl: `https://www.youtube.com/watch?v=${videoId}`,
        thumbnailUrl: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`,
      }
    : {};
};

export const hydrateYoutubeNode = (
  link: HTMLAnchorElement,
  thumbnailUrl: string,
  videoUrl: string,
) => {
  const img = document.createElement('img');
  img.setAttribute('src', thumbnailUrl);
  img.setAttribute('class', `${YOUTUBESELECTOR}-thumbnail`);
  img.setAttribute('alt', 'YouTube video thumbnail.');

  link.setAttribute('data-analytics-event', 'video');
  link.setAttribute('href', videoUrl);
  link.setAttribute('target', '_blank');
  link.setAttribute('class', YOUTUBESELECTOR);
  link.appendChild(img);

  return link;
};

const convertYoutubeToCommonFormat = (content: PostContent): PostContent =>
  transformMarkupBySelector(
    (container) => container.getElementsByClassName(YOUTUBESELECTOR),
    (youtubeLink) => {
      const youtubeNode = document.createElement(YOUTUBETAG);
      youtubeNode.classList.add(YOUTUBESELECTOR);
      youtubeNode.innerText = (youtubeLink as HTMLAnchorElement).href || '';
      youtubeLink.replaceWith(youtubeNode);
    },
    content,
  );

const convertCommonToYoutubeFormat = (content: PostContent): PostContent =>
  transformMarkupBySelector(
    (container) => container.getElementsByTagName(YOUTUBETAG),
    (youtubeTag) => {
      const youtubeNode = document.createElement('a');
      const { thumbnailUrl, videoUrl } = getYoutubeUrls(
        youtubeTag.textContent as string,
      );

      if (thumbnailUrl && videoUrl) {
        const hydratedNode = hydrateYoutubeNode(
          youtubeNode,
          thumbnailUrl,
          videoUrl,
        );
        youtubeTag.replaceWith(hydratedNode);
      }
    },
    content,
  );

const convertMentionsToCommonFormat = (content: PostContent): PostContent => {
  if (!content.html) return content;
  const tempNode = document.createElement('div');
  tempNode.innerHTML = content.html;

  const mentions = [...tempNode.querySelectorAll<HTMLElement>('.mention')].map(
    (mentionNode) => {
      const mention = mentionNode && {
        userId: decodeHtml(mentionNode?.dataset?.id),
        userName: decodeHtml(mentionNode?.dataset?.value),
      };

      if (mention) {
        const userNode = document.createElement('user');
        userNode.innerHTML = mention?.userId;
        if (mentionNode) {
          mentionNode.replaceWith(userNode);
        }
      }

      return mention;
    },
  );

  return {
    ...content,
    mentions: uniqBy(mentions, 'userId'),
    html: tempNode?.innerHTML,
  };
};

export const convertCommonToMentionsFormat = (
  content: PostContent,
): PostContent => {
  const contentHtml = content.html || '';
  const html = contentHtml.replace(
    /<user>(.+)<\/user>/gim,
    (match, userId: string) => {
      const safeUserId = encodeHtml(userId);
      const userName =
        content.mentions?.find((mention) => mention.userId === userId)
          ?.userName || userId;
      const safeUserName = encodeHtml(userName);
      return `<span class="mention" data-id="${safeUserId}" data-value="${safeUserName}" data-denotation-char="@">${safeUserName}</span>`;
    },
  );

  return {
    ...content,
    html,
  };
};

type Converter = (content: PostContent) => PostContent;
const compose =
  (...converters: Converter[]) =>
  (content: PostContent) =>
    converters.reduceRight((acc, converter) => converter(acc), content);

export const convertDataToCommonFormat = compose(
  convertMentionsToCommonFormat,
  convertYoutubeToCommonFormat,
  convertFormulasToCommonFormat,
);

export const convertCommonFormatToMarkup = compose(
  convertCommonToMentionsFormat,
  convertCommonToYoutubeFormat,
  convertCommonToKatexFormat,
);
